import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../service/user.service';
import { CommonService } from '../../service/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { bothCheckboxMustBeTrue, mustMatch } from '../../shared/helper/form.validator';
import { ToastServices } from '../../shared/toast.service';
import { TranslateService } from '../../translate';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(private router: Router,
              private elementRef: ElementRef,
              private userService: UserService,
              private commonService: CommonService,
              private renderer: Renderer2,
              private toastService: ToastServices,
              private translateService: TranslateService,
              private formBuilder: FormBuilder) { }
  errorMessage = '';
  languages = [];
  hidePassword = true;
  hideConfirmPassword = true;
  registerForm: FormGroup;
  submitted = false;
  signUpStatus = false;
  checkboxShowing = false;

  ngOnInit() {
    this.detectLanguage();
    this.commonService.getLanguages().then(languages => this.languages = languages);
    this.initFormGroup();
  }

  initFormGroup() {
    this.registerForm = this.formBuilder.group({
      nickName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      language: ['', Validators.required],
      agreeToServices: [false, Validators.required],
      agreeToPolicy: [false, Validators.required],
      // recaptchaReactive: ['', Validators.required],
    }, {
      validators: [bothCheckboxMustBeTrue('agreeToServices', 'agreeToPolicy'), mustMatch('password', 'confirmPassword')]
    });
  }

  ngAfterViewInit() {
    // this.elementRef.nativeElement.ownerDocument.body.classList.add('login-body');
    this.renderer.addClass(document.body, 'login-body');
  }

  ngOnDestroy() {
    // remove the class form body tag
    this.renderer.removeClass(document.body, 'login-body');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.registerForm.controls[controlName].hasError(errorName);
  }

  get f() { return this.registerForm.controls; }

  checkboxChange() {
    this.checkboxShowing = true;
  }
  onSubmit() {
    this.checkboxShowing = true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    const formValue = this.registerForm.value;
    const userObj = {
      nickName: formValue.nickName,
      email: formValue.email,
      password: formValue.password,
      language: formValue.language
    };
    this.userService.createUser(userObj).then(() => {
      this.signUpStatus = true;
      this.toastService.showSuccess(this.translateService.instant('registerPage.message.success'));
      this.errorMessage = this.translateService.instant('registerPage.message.success');
      this.router.navigate(['/login']);
    }).catch(() => {
      this.signUpStatus = false;
      this.toastService.showError(this.translateService.instant('registerPage.message.fail'));
      this.errorMessage = this.translateService.instant('registerPage.message.fail');
    });
  }

  private detectLanguage() {
    const browserLang = navigator.language;
    if (browserLang.indexOf('-') !== -1) {
      localStorage.setItem('language', browserLang.substr(0, browserLang.indexOf('-')));
    } else {
      localStorage.setItem('language', browserLang);
    }
  }

}
