import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UserService } from '../../service/user.service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../service/loader.service';
import { BasePage } from '../../shared/BasePage';
import { TranslateService } from '../../translate';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BasePage implements OnInit, AfterViewInit, OnDestroy {
  constructor(private router: Router,
              private elementRef: ElementRef,
              private userService: UserService,
              private renderer: Renderer2,
              private loaderService: LoaderService,
              private translateService: TranslateService,
              private authService: AuthenticationService) {
    super();
  }
  hide = true;
  username = '';
  password = '';
  errorMessage = '';
  ngOnInit() {
    this.detectLanguage();
  }

  ngAfterViewInit() {
    // this.elementRef.nativeElement.ownerDocument.body.classList.add('login-body');
    this.renderer.addClass(document.body, 'login-body');
  }

  ngOnDestroy() {
    // remove the class form body tag
    this.renderer.removeClass(document.body, 'login-body');
  }

  private handleLoginError(error: any) {
    console.log(error);
    if (error.status === 401) {
      this.errorMessage = this.translateService.instant('loginPage.message.invalidEmailPassword');
    } else {
      this.errorMessage = this.translateService.instant('loginPage.message.loginFail');
    }
    this.loaderService.hide();
  }

  forgotPass() {
    this.router.navigate(['/forgot-password']);
  }

  login() {
    this.errorMessage = '';
    this.loaderService.show();
    if (this.username === '' || this.password === '') {
      this.errorMessage = this.translateService.instant('loginPage.message.emptyField');
      this.loaderService.hide();
      return;
    } else {
      this.authService.login(this.username, this.password)
        .then(async userObj =>  {
            this.loaderService.hide();
            if (userObj.firstLogin) {
              localStorage.removeItem('firstTimeLoginVisitedSteps');
              this.router.navigate(['/accounts']);
            } else {
              localStorage.setItem('firstTimeLoginVisitedSteps', '[]');
              this.router.navigate(['/first-time-login']);
            }
          },
          error => this.handleLoginError(error)
        );
    }
  }

  createAccount() {
    this.router.navigate(['/register']);
  }

  signInWithFB() {}

  signInWithGG() {}

  private detectLanguage() {
    const browserLang = navigator.language;
    if (browserLang.indexOf('-') !== -1) {
      localStorage.setItem('language', browserLang.substr(0, browserLang.indexOf('-')));
    } else {
      localStorage.setItem('language', browserLang);
    }
  }
}
