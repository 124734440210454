import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoaderService } from '../service/loader.service';
import { ToastServices } from './toast.service';
import { Router } from '@angular/router';
import { TokenService } from '../authentication/token.service';
import { TranslateService } from '../translate';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient,
              private toastServices: ToastServices,
              private tokenService: TokenService,
              private translateService: TranslateService,
              private router: Router,
              private loaderService: LoaderService) {
  }

  /**
   * build request url
   *
   * @param resource
   * @returns {string}
   */
  buildUrl(resource: string): string {
    return AppSettings.API_ENDPOINT + resource;
  }

  /**
   * send a http request
   *
   * @param method
   * @param url
   * @param body
   * @param options
   * @returns {Observable<Response>}
   */
  request(method: string, url: string, body?: any, option?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const httpOptions = option ? option : {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization:  'Bearer ' + this.getTokenFromLocalStorage(),
        }),
        body,
      };
      this.loaderService.show();
      return this.http.request(method, url, httpOptions)
        .toPromise().then(result => {
          this.tokenService.refreshToken();
          resolve(result);
          this.loaderService.hide();
        }).catch(err => {
          if (err.status === 401) {
            this.toastServices.showError(this.translateService.instant('session.isExpired'));
            this.router.navigate(['/login']);
          } else {
            reject(err);
          }
          this.loaderService.hide();
        });
    });
  }

  public upload(url: string, formData: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      return this.http.post<any>(url, formData, {
        headers: {
          Authorization:  'Bearer ' + this.getTokenFromLocalStorage(),
        },
        reportProgress: true,
        observe: 'events'
      }).toPromise().then((result: any) => {
          resolve(result.body);
          this.loaderService.hide();
      }).catch(err => {
          reject(err);
          this.loaderService.hide();
      });
    });
  }

  /**
   * send a http get request
   *
   * @param url
   * @param options
   * @returns {Observable<Response>}
   */
  get(url: string): Promise<any> {
    return this.request('GET', encodeURI(url));
  }

  getWithoutToken(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const option = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      this.loaderService.show();
      this.http.request('GET', url, option).toPromise().then(result => {
          resolve(result);
          this.loaderService.hide();
        }).catch(err => {
          reject(err);
          this.loaderService.hide();
        });
    });
  }

  postWithoutToken(url: string, body?: any): Promise<any> {
    const option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body
    };
    return this.request('POST', url, body, option);
  }

  putWithoutTokenAndBody(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const option = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      this.loaderService.show();
      return this.http.request('PUT', url, option)
        .toPromise().then(result => {
          resolve(result);
          this.loaderService.hide();
        }).catch(err => {
          reject(err);
          this.loaderService.hide();
        });
    });
  }

  getWithParam(url: string, param: URLSearchParams): Promise<any> {
    return this.request('GET', url + '?' + param.toString());
  }

  /**getCommonData
   * send a http post request
   *
   * @param url
   * @param body
   * @param options
   * @returns {Observable<Response>}
   */
  post(url: string, body?: any): Promise<any> {
    return this.request('POST', url, body);
  }

  /**
   * send a http post request
   *
   * @param url
   * @param body
   * @param options
   * @returns {Observable<Response>}
   */
  put(url: string, body: any): Promise<any> {
    return this.request('PUT', url, body);
  }

  /**
   * send a http delete request
   *
   * @param url
   * @param body
   * @param options
   * @returns {Observable<Response>}
   */
  delete(url: string): Promise<any> {
    return this.request('DELETE', url, null);
  }

  makeParams(arr: any): string {
    const param: URLSearchParams = new URLSearchParams();
    for (const key in arr) {
      if (arr.hasOwnProperty(key)) {
        param.set(key, arr[key]);
      }
    }
    return '?' + param.toString();
  }

  getTokenFromLocalStorage() {
    const tokenJson = JSON.parse(localStorage.getItem('token'));
    return tokenJson ? tokenJson.accessToken : '';
  }
}
