import { Component, Input, OnInit } from '@angular/core';
import { TransactionModel } from '../transaction.model';
import { BasePage } from '../../shared/BasePage';
import { TransactionCategoryModel } from './transaction-category.model';
import { TransactionLabelModalComponent } from '../transaction-label-modal/transaction-label-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TransactionService } from '../../service/transaction.service';
import { Router } from '@angular/router';
import { ToastServices } from '../../shared/toast.service';
import { TranslateService } from '../../translate';

@Component({
  selector: 'app-transaction-item',
  templateUrl: './transaction-item.component.html',
  styleUrls: ['./transaction-item.component.css']
})
export class TransactionItemComponent extends BasePage implements OnInit {

  constructor(private dialog: MatDialog,
              private toastServices: ToastServices,
              private router: Router,
              private translateService: TranslateService,
              private transactionService: TransactionService) {
    super();
  }

  @Input() public transactionInfo: TransactionModel;
  @Input() public showCardDetail: boolean;
  @Input() public listLabelCategories: Array<TransactionCategoryModel>;
  @Input() public accountId: number;
  @Input() public editable: boolean;
  isIncomeOrTransafer = false;
  listLabels = [];

  tooglePanel() {
    this.showCardDetail = !this.showCardDetail;
  }

  ngOnInit() {
    this.isIncomeOrTransafer = this.transactionInfo.directionFlag === 'INCOME' || this.transactionInfo.directionFlag === 'TRANSFER';
    this.populateCategoryInfo();
  }

  populateCategoryInfo() {
    this.listLabelCategories = this.listLabelCategories.filter( a => {
      return this.filterType(a.directionFlag);
    });

    for (const c of this.listLabelCategories) {
      for (const transactionLabel of this.transactionInfo.labels) {
        if (transactionLabel.label.labelCategory.enumId.trim() === c.enumId.trim() &&
          transactionLabel.label.labelCategory.profileId.toString() === this.defaultProfileId) {
          this.listLabels.push({
            enumIcon: transactionLabel.label.iconEnumId,
            labelName: this.translateService.instant('enum.name.' + transactionLabel.label.enumId.trim()),
            labelCategoryName: this.translateService.instant('enum.category.' + c.enumId.trim()),
            labelCategoryId: transactionLabel.label.labelCategoryId,
            icon: transactionLabel.label.icon,
            id: transactionLabel.label.id
          });
        }
      }
    }

  }

  filterType(type: string) {
    return type === this.transactionInfo.directionFlag;
  }

  public orderListInAlphabetical(listLabelsOfCategory) {
    return this.orderListInAlphabeticalWithSelectedLanguage(this.translateService, listLabelsOfCategory);
  }

  openSelectLabelDialog(label): void {
    this.transactionService.getLabelOfCategoryId(label.labelCategoryId).then(listLabelsOfCategory => {
      let temp = {};
      let originalLabelId = 0;
      for (const item of listLabelsOfCategory) {
        if (item.id === label.id) {
          temp = item;
          originalLabelId = label.id;
          break;
        }
      }

      listLabelsOfCategory = this.orderListInAlphabetical(listLabelsOfCategory);

      const dialogRef = this.dialog.open(TransactionLabelModalComponent, {
        width: '300px',
        data: {
          selectedLabel: temp,
          labelList: listLabelsOfCategory,
          transactionId: this.transactionInfo.id,
          originalLabelId: originalLabelId,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.updateLabelResult(result);
      });
    }).catch(() => {
      this.toastServices.showError('Get label of category fail');
    });
  }

  updateLabelResult(result: any) {
    if (!result) {
      return;
    }
    const temp = [];
    for (let label of this.listLabels) {
      if (label.id === result.originalLabelId) {
        label = {
          icon : result.selectedLabel.icon,
          enumIcon: result.selectedLabel.iconEnumId,
          labelName: this.translateService.instant('enum.name.' + result.selectedLabel.enumId.trim()),
          labelCategoryName: this.translateService.instant('enum.category.' + result.selectedLabel.labelCategory.enumId.trim()),
          labelCategoryId: result.selectedLabel.labelCategory.id,
          id: result.selectedLabel.id
        };
      }
      temp.push(label);
    }
    this.listLabels = temp;
  }

  moveToTransaction(mode: string) {
    this.router.navigate([`accounts/${this.accountId}/transactions-detail/${this.transactionInfo.id}/${mode}`]);
  }

  isPlanedTransaction(transactionInfo: any) {
    return transactionInfo.baseTransactionId;
  }

}
