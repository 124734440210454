import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './authentication/login/login.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { RegisterComponent } from './authentication/register/register.component';
import { ActiveAccountComponent } from './authentication/active-account/active-account.component';
import { AddAccountComponent } from './accounts/add-account/add-account.component';
import { AccountDetailComponent } from './accounts/account-detail/account-detail.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileDetailComponent } from './users/profile-detail/profile-detail.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { TransactionDetailComponent } from './transactions/transaction-detail/transaction-detail.component';
import { AddTransactionComponent } from './transactions/add-transaction/add-transaction.component';
import { AccountUploadSettingComponent } from './accounts/account-upload-setting/account-upload-setting.component';
import { FirstTimeLoginComponent } from './users/first-time-login/first-time-login.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { UserAccountListComponent } from './users/user-account-list/user-account-list.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { CashFlowComponent } from './cash-flow/cash-flow.component';
import { InviteUsersComponent } from './invite-users/invite-users.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/accounts',
        canActivate: [AuthenticationGuard],
        pathMatch: 'full'
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'cash-flow',
        component: CashFlowComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts',
        component: AccountsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'create-account',
        component: AddAccountComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts/:id/:mode',
        component: AccountDetailComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'profile-detail',
        component: ProfileDetailComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'first-time-login',
        component: FirstTimeLoginComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts/:accountId/transactions/list/:month',
        component: TransactionsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts/:accountId/transactions/list',
        component: TransactionsComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts/:accountId/transactions-detail/:transactionId/:mode',
        component: TransactionDetailComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts/:accountId/transactions/create-transaction',
        component: AddTransactionComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts/:accountId/transactions/create-transaction/:month',
        component: AddTransactionComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'accounts/:accountId/transactions/upload-transaction',
        component: AccountUploadSettingComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'user-account-list',
        component: UserAccountListComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'invite-new-user',
        component: InviteUsersComponent,
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent,
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full'
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full'
  },
  {
    path: 'active-account/:id',
    component: ActiveAccountComponent
  },
  {
    path: 'reset-pass/:userId/:secret',
    component: ResetPasswordComponent
  },
];
