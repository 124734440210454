import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasePage } from '../shared/BasePage';
import { TransactionService } from '../service/transaction.service';
import { TranslateService } from '../translate';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastServices } from '../shared/toast.service';
import { PeriodEnums } from './period-enums';
import { DateUtil } from '../shared/date.util';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { clone } from '../shared/String.config';
import { MatDialog } from '@angular/material/dialog';
import { TransactionFilterModalComponent } from './transaction-filter-modal/transaction-filter-modal.component';
import { HELP_POPUP_STEP } from '../shared/data.utils';
import { DataSharingService } from '../shared/data-service-change';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent extends BasePage implements OnInit {

  constructor(private transactionService: TransactionService,
              private dialog: MatDialog,
              private dataSharingService: DataSharingService,
              private formBuilder: FormBuilder,
              private toastService: ToastServices,
              private translateService: TranslateService) {
    super();
  }
  chartIncomeDatas = [];
  chartExpenseDatas = [];
  incomeTotalAmount = 0;
  expenseTotalAmount = 0;
  data: any;
  labels = [];
  incomeNameList = [];
  expenseNameList = [];
  chartForm: FormGroup;
  incomeChartOption: any;
  incomeMobileChartOption: any;
  expenseChartOption: any;
  expenseMobileChartOption: any;
  incomeList = [];
  expenseList = [];
  timeList = PeriodEnums.LIST;
  selectedIncomePeriod = PeriodEnums.THIS_YEAR.value;
  selectedExpensePeriod = PeriodEnums.THIS_YEAR.value;
  selectedIncomeCategory = 'PAYING';
  selectedExpenseCategory = 'PURPOSE';
  fromIncome = '';
  toIncome = '';
  fromExpense = '';
  toExpense = '';
  isMobileView = this.getMobileView();
  customFromDate = new FormControl(new Date());
  customToDate = new FormControl(new Date());
  // @ts-ignore
  @Output()
  dateChange(): EventEmitter<MatDatepickerInputEvent<any>>;
  // @ts-ignore
  ngOnInit() {
    this.initFormGroup();
    this.incomeNameList = [];
    this.expenseNameList = [];
    this.setDate(this.selectedIncomePeriod, INCOME);
    this.setDate(this.selectedExpensePeriod, EXPENSE);
    this.transactionService.getListLabelCategoriesByProfileId(this.defaultProfileId).then((result) => {
      this.incomeList = result[INCOME];
      this.expenseList = result[EXPENSE];
      this.drawChartByCategory(this.incomeList[0].enumId.trim(), INCOME);
      this.drawChartByCategory(this.expenseList[0].enumId.trim(), EXPENSE);
    }).catch(e => {
      this.toastService.showError(this.translateService.instant('analyticsPage.getLabelFail'));
    });
    this.checkFirstTimeLogin(HELP_POPUP_STEP.ANALYTICS, this.dataSharingService);
  }

  private setChartData(type, chartData) {
    this.data = this.genData(chartData);
    const chartOption = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => this.formatToolTip(params),
      },
      legend: {
        type: 'scroll',
        right: 10,
        data: this.data.legendData,
        selected: this.data.selected
      },
      series: [
        {
          name: 'chartSeries',
          type: 'pie',
          radius: '55%',
          center: ['50%', '50%'],
          data: this.data.seriesData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            formatter: (params) => this.formatPopupToolTip(params),
            backgroundColor: '#eee',
            borderColor: '#aaa',
            borderWidth: 1,
            borderRadius: 4,
            // shadowBlur:3,
            // shadowOffsetX: 2,
            // shadowOffsetY: 2,
            // shadowColor: '#999',
            // padding: [0, 7],
            rich: {
              a: {
                color: '#000438',
                lineHeight: 22,
                align: 'center'
              },
              // abg: {
              //     backgroundColor: '#333',
              //     width: '100%',
              //     align: 'right',
              //     height: 22,
              //     borderRadius: [4, 4, 0, 0]
              // },
              hr: {
                borderColor: '#aaa',
                width: '100%',
                borderWidth: 0.5,
                height: 0
              },
              b: {
                fontSize: 13,
                lineHeight: 25,
              },
              per: {
                color: '#eee',
                backgroundColor: '#334455',
                padding: [2, 4],
                borderRadius: 2
              }
            }
          },
        }
      ]
    };

    if (type === INCOME) {
      this.incomeChartOption = clone(chartOption); // JSON.parse(JSON.stringify(chartOption));
      this.incomeChartOption.title = {
        text: this.translateService.instant('analyticsPage.incomeChart'),
        subtext: this.translateService.instant('enum.category.' + this.selectedIncomeCategory)
          + ' | ' + this.translateService.instant('analyticsPage.totalAmount') + ': '
          + this.formatMoney(this.incomeTotalAmount) + ' ' + this.defaultCurrency,
        left: 'left'
      };
      this.setLegendForChart(true, this.incomeChartOption);
      this.incomeChartOption.series[0].name = this.translateService.instant('enum.category.' + this.selectedIncomeCategory);

      this.incomeMobileChartOption = clone(this.incomeChartOption);
      this.incomeMobileChartOption.series[0].label = null;
      this.setLegendForChart(false, this.incomeMobileChartOption);
    }

    if (type === EXPENSE) {
      this.expenseChartOption = clone(chartOption);
      this.expenseChartOption.title = {
        text: this.translateService.instant('analyticsPage.expenseChart'),
        subtext: this.translateService.instant('enum.category.' + this.selectedExpenseCategory)
          + ' | ' + this.translateService.instant('analyticsPage.totalAmount') + ': '
          + this.formatMoney(this.expenseTotalAmount) + ' ' + this.defaultCurrency,
        left: 'left'
      };
      this.expenseChartOption.series[0].name = this.translateService.instant('enum.category.' + this.selectedExpenseCategory);
      this.setLegendForChart(true, this.expenseChartOption);

      this.expenseMobileChartOption = clone(this.expenseChartOption);
      this.expenseMobileChartOption.series[0].label = null;
      this.setLegendForChart(false, this.expenseMobileChartOption);
    }
  }

  private setLegendForChart(isDesktopView, chart) {
    if (isDesktopView) {
      chart.legend.orient = 'vertical';
      chart.legend.left = 'left';
      chart.legend.top = 60;
      chart.legend.left = 10;
    } else {
      chart.legend.orient = 'horizontal';
      chart.legend.bottom = 10;
      chart.legend.top = null;
    }
  }

  genData(chartData) {
    const legendData = [];
    const seriesData = [];
    const selected = {};
    for (let i = 0; i < chartData.length; i++) {
      const translatedLabel = this.translateService.instant('enum.name.' + chartData[i].label);
      legendData.push(translatedLabel);
      seriesData.push({
        name: translatedLabel,
        value: chartData[i].totalAmount,
        labelId: chartData[i].labelId
      });
      selected[chartData[i].label] = true;
    }

    return {
      legendData, seriesData, selected
    };
  }

  formatToolTip(params) {
    const amount = this.formatMoney(params.value);
    return params.name + ' : ' + amount + ' ' + this.defaultCurrency + ' (' + params.percent + ' %) ';
  }

  openSelectLabelDialog(labelId, labelName, isDesktopView, chartType) {
    const from = chartType === INCOME ? this.fromIncome : this.fromExpense;
    const to = chartType === INCOME ? this.toIncome : this.toExpense;
    this.transactionService.getTransactionsOfProfileGroupByLabelId(this.defaultProfileId, labelId, from, to)
      .then((transactions) => {
        this.dialog.open(TransactionFilterModalComponent, {
          width: '1200px',
          maxWidth: '95%',
          data: {
            transactions: transactions,
            labelName: labelName,
            displayedColumns: isDesktopView ? ['accountName', 'dateOfTransaction', 'amount', 'partnerName', 'descriptionAndNote'] : ['accountName', 'dateOfTransaction', 'amount']
          }
        });
      });
  }

  onChartClick(event, isDesktopView, chartType) {
    this.openSelectLabelDialog(event.data.labelId, event.data.name, isDesktopView, chartType);
  }

  formatPopupToolTip(data) {
    // '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c} ' + this.defaultCurrency + '  {per|{d}%}  '
    const amount = this.formatMoney(data.value);
    // const format = `{a|${data.seriesName}}{abg|}\n{hr|}\n {b|${data.name}：}${amount} ${this.defaultCurrency} {per|${data.percent}%} `;
    const format = `{a|${data.name}}{abg|}\n{hr|}\n {b|${amount} ${this.defaultCurrency}} {per|${data.percent}%} `;
    return format;
  }

  changePeriod(periodSelected, chartType) {
    this.setDate(periodSelected, chartType);
    this.drawChartByCategory(this.selectedIncomeCategory, INCOME);
    this.drawChartByCategory(this.selectedExpenseCategory, EXPENSE);
  }

  drawChartByCategory(categoryName, type) {
    const from = type === INCOME ? this.fromIncome : this.fromExpense;
    const to = type === INCOME ? this.toIncome : this.toExpense;

    let categoryId = 0;
    if (type === INCOME) {
      for (const label of this.incomeList) {
        if (label.enumId.trim() === categoryName) {
          categoryId = label.id;
          break;
        }
      }
    }

    if (type === EXPENSE) {
      for (const label of this.expenseList) {
        if (label.enumId.trim() === categoryName) {
          categoryId = label.id;
          break;
        }
      }
    }

    this.transactionService.getChartDataByCategory(this.defaultProfileId, categoryId, from, to).then(chartDatas => {
      if (type === INCOME) {
        this.chartIncomeDatas = chartDatas;
        this.incomeTotalAmount = this.getTotalAmountFromData(chartDatas);
      } else {
        this.chartExpenseDatas = chartDatas;
        this.expenseTotalAmount = this.getTotalAmountFromData(chartDatas);
      }
      this.setChartData(type, chartDatas);
    }).catch(err => {
      this.toastService.showError(this.translateService.instant('analyticsPage.getChartDataFail'));
    });
  }

  private initFormGroup() {
    this.chartForm = this.formBuilder.group({
      labelCategory: [''],
    });
  }

  private setDate(selectedPeriod: string, type: string) {
    if (selectedPeriod === PeriodEnums.CUSTOM.value) {
      if (type === INCOME) {
        this.fromIncome = this.getDateInFormat(this.customFromDate.value, 'YYYY-MM-DD');
        this.toIncome = this.getDateInFormat(this.customToDate.value, 'YYYY-MM-DD');
      }
      if (type === EXPENSE) {
        this.fromExpense = this.getDateInFormat(this.customFromDate.value, 'YYYY-MM-DD');
        this.toExpense = this.getDateInFormat(this.customToDate.value, 'YYYY-MM-DD');
      }
    } else {
      const dateOBj = DateUtil.getDateFromTimeRange(selectedPeriod);
      if (type === INCOME) {
        this.fromIncome = dateOBj.from;
        this.toIncome = dateOBj.to;
      }
      if (type === EXPENSE) {
        this.fromExpense = dateOBj.from;
        this.toExpense = dateOBj.to;
      }
    }
  }

  private getTotalAmountFromData(chartDatas: any) {
    let amount = 0;
    for (const data of chartDatas) {
      amount += data.totalAmount;
    }
    return amount;
  }

  getMobileView() {
    if (window.innerWidth <= 800) {
      return true;
    } else {
      return false;
    }
  }
}

const INCOME = 'INCOME';
const EXPENSE = 'EXPENSE';
