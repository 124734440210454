import { Observable } from 'rxjs';

import { detect, convert } from 'encoding-japanese';

export interface TextFileInfo {
  content: string;
  encoding: string;
}

/**
 * Returns the file content and the predicted encoding.
 * @param file File object eg. from a file picker.
 */
export function loadTextFile(file: File): Observable<TextFileInfo> {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return Observable.create((observer: any) => {
    reader.onloadend = () => {
      const codes = new Uint8Array(reader.result as ArrayBuffer);
      const encoding = detect(codes);

      const unicodeString = convert(codes, {
        to: 'UNICODE',
        from: encoding,
        type: 'string'
      });

      observer.next({ content: unicodeString, encoding: encoding });
      observer.complete();
    };
  });
}
