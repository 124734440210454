import { Injectable } from '@angular/core';
import { User } from './user.model';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { HttpService } from '../shared/http.service';
import { UserService } from '../service/user.service';
import { Token } from './token.model';

@Injectable()
export class AuthenticationService {
  userDetails: User;

  constructor(private tokenService: TokenService, private http: HttpService, private router: Router, private userService: UserService) {

  }

  /**
   * get current token
   *
   * @returns {String}
   */
  getAccessToken(): string {
    return this.tokenService.getAccessToken();
  }

  /**
   * perform login
   *
   * @param userName
   * @param password
   * @returns {Observable}
   */
  login(userName: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.tokenService.login(userName, password).then(() => {
        this.userService.getUserInfo().then(res => {
            this.tokenService.setUser(res);
            resolve(res);
          }).catch(err => {
          reject(false);
        });
      }).catch( err => {
        reject(false);
      });
    });
  }

  /**
   * get current user details
   *
   * @returns {User}
   */
  getUserDetails(): User {
    let token;

    if (this.userDetails !== undefined && this.userDetails !== null) {
      return this.userDetails;
    } else {
      token = this.tokenService.getToken();

      if (token !== undefined && token !== null) {
        this.userDetails = token.user;
        return token.user;
      }
    }

    return null;
  }

  getCurrentEmail() {
    const currUser = this.getUserDetails();
    if (currUser != null) {
      return currUser.email;
    }
    return '';
  }

  hasRoles(...roles: string[]): boolean {
    /*let user = this.getUserDetails();
    if(user == null || user.roles == null ) return false;
    for (let role of user.roles) {
      if (StringUtils.isEqualInArray(roles, role.roleName)) {
        return true;
      }
    }*/
    return false;
  }

  /**
   * check if user is authenticated
   *
   * @returns {boolean}
   */
  isAuthenticated(): boolean {
    return this.tokenService.isAuthenticated();
  }

  logOut(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.userService.signOut().then(() => {
        this.tokenService.removeToken();
        this.userService.removeProfile();
        resolve();
      }).catch(() => {
        this.tokenService.removeToken();
        this.userService.removeProfile();
        resolve();
      });
    });
  }
}
