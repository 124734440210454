import { Injectable } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { ConstantSetting } from '../shared/constant.config';

@Injectable()
export class TransactionService {
    constructor(private http: HttpService,
    ) {
    }

  getTransactionsOfAccountList(accountId: number, from: string, to: string): Promise<any> {
      const url = ConstantSetting.GET_TRANSACTION_OF_ACCOUNT_LIST.replace(/\{\w+\}/ig, accountId.toString());
      return this.http.get(url + `/${from}/${to}`);
  }

  getLabelOfCategoryId(labelCategoryId: number): Promise<any> {
    const url = ConstantSetting.GET_LABEL_OF_CATEGORY.replace(/\{\w+\}/ig, labelCategoryId.toString());
    return this.http.get(url);
  }

  getLabelByDirectionFlag(directionFlag: string): Promise<any> {
    const url = ConstantSetting.GET_LABEL_BY_DIRECTION_FLAG.replace(/\{\w+\}/ig, directionFlag);
    return this.http.get(url);
  }

  getListLabelCategoriesByProfileId(profileId: string): Promise<any> {
    const url = ConstantSetting.GET_LIST_LABEL_CATEGORIES_BY_PROFILE_ID.replace(/\{\w+\}/ig, profileId);
    return this.http.get(url);
  }

  updateTransactionLabel(transactionId: number, selectedLabelId: any, originalLabelId: number): Promise<any> {
    const url = ConstantSetting.UPDATE_TRANSACTION_LABEL.replace(/\{\w+\}/ig, transactionId.toString());
    return this.http.put(url + `/${selectedLabelId}/${originalLabelId}`, null);
  }

  getTransactionById(accountId: number, transactionId: number): Promise<any> {
    const url = ConstantSetting.GET_TRANSACTION_BY_ID.replace(/\{\w+\}/ig, accountId.toString()) + `/${transactionId}`;
    return this.http.get(url);
  }

  createFiTransaction(accountId: number, transObj: any): Promise<any>  {
    const url = ConstantSetting.SAVE_TRANSACTION.replace(/\{\w+\}/ig, accountId.toString());
    return this.http.post(url, transObj);
  }

  updateFiTransaction(accountId: number, transObj: any): Promise<any> {
    const url = ConstantSetting.SAVE_TRANSACTION.replace(/\{\w+\}/ig, accountId.toString());
    return this.http.put(url, transObj);
  }

  removeTransaction(accountId: number, transactionId: number) {
    const url = ConstantSetting.DELETE_TRANSACTION_BY_ID.replace(/\{\w+\}/ig, accountId.toString()) + `/${transactionId}`;
    return this.http.delete(url);
  }

  getChartDataByCategory(defaultProfileId: string, labelCategoryId: number, from: string, to: string) {
    const url = ConstantSetting.TRANSACTIONS + `/${defaultProfileId}/${labelCategoryId}/${from}/${to}`;
    return this.http.get(url);
  }

  getTransactionsOfProfileGroupByLabelId(defaultProfileId: string, labelId: number, from: string, to: string): Promise<any> {
    const url = ConstantSetting.TRANSACTIONS + '/' + ConstantSetting.GET_TRANSACTIONS_OF_PROFILE_GROUP_BY_LABEL_ID + `/${defaultProfileId}/${labelId}/${from}/${to}`;
    return this.http.get(url);
  }
}
