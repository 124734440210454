import { InjectionToken } from '@angular/core';

// import translations
import { LANG_EN_TRANS } from '../i18n/lang-en';
import { LANG_DE_TRANS } from '../i18n/lang-de';
import { LANG_HU_TRANS } from '../i18n/lang-hu';
import { LANG_IT_TRANS } from '../i18n/lang-it';
import { LANG_ES_TRANS } from '../i18n/lang-es';
import { LANG_CZ_TRANS } from '../i18n/lang-cz';

// translation token
export const TRANSLATIONS = new InjectionToken('translations');
const dictionary = {
  en: LANG_EN_TRANS,
  de: LANG_DE_TRANS,
  hu: LANG_HU_TRANS,
  it: LANG_IT_TRANS,
  es: LANG_ES_TRANS,
  cz: LANG_CZ_TRANS,
};

// providers
export const TRANSLATION_PROVIDERS = [
  { provide: TRANSLATIONS, useValue: dictionary },
];
