export class PeriodEnums {
  public static THIS_MONTH = {
    name : 'THIS_MONTH',
    value: 'THIS_MONTH'
  };
  public static LAST_MONTH = {
    name : 'LAST_MONTH',
    value: 'LAST_MONTH'
  };
  public static THIS_WEEK = {
    name : 'THIS_WEEK',
    value: 'THIS_WEEK'
  };
  public static LAST_WEEK = {
    name : 'LAST_WEEK',
    value: 'LAST_WEEK'
  };
  public static THIS_YEAR = {
    name : 'THIS_YEAR',
    value: 'THIS_YEAR'
  };
  public static LAST_YEAR = {
    name : 'LAST_YEAR',
    value: 'LAST_YEAR'
  };
  public static TODAY = {
    name : 'TODAY',
    value: 'TODAY'
  };

  public static CUSTOM = {
    name : 'CUSTOM',
    value: 'CUSTOM'
  };

  public static get LIST(): Array<any> {
    return [
      this.THIS_MONTH, this.LAST_MONTH, this.THIS_WEEK, this.LAST_WEEK, this.THIS_YEAR, this.LAST_YEAR, this.TODAY, this.CUSTOM
    ];
  }
}
