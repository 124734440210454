import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mustMatch } from '../../shared/helper/form.validator';
import { UserService } from '../../service/user.service';
import { ToastServices } from '../../shared/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
              private renderer: Renderer2,
              private toastService: ToastServices,
              private userService: UserService) { }
  forgotForm: FormGroup;

  ngOnInit() {
    this.initFormGroup();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.forgotForm.controls[controlName].hasError(errorName);
  }

  forgotPassword() {
    if (this.forgotForm.invalid) {
      return;
    }

    const formValue = this.forgotForm.value;
    this.userService.forgotPassword(formValue.email).then((result) => {
      this.toastService.showSuccess('You have request reset password successfully, please check your mailbox');
      this.initFormGroup();
    }).catch((err) => {
      if (err && err.status === 404) {
        this.toastService.showError('Email hasn\'t existed in the system');
      } else {
        this.toastService.showError('You have request reset password unsuccessfully');
      }
    });
  }

  private initFormGroup() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit() {
    // this.elementRef.nativeElement.ownerDocument.body.classList.add('login-body');
    this.renderer.addClass(document.body, 'login-body');
  }

  ngOnDestroy() {
    // remove the class form body tag
    this.renderer.removeClass(document.body, 'login-body');
  }
}
