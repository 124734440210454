import { replaceAll } from 'chartist';
import { isUndefined } from 'util';
const REGEX1 = '[+-]?\\\\d*(\\\\.(\\\\d*)?)?';
const REGEX2 = '/,/g';
const REGEX3 = '/,/gtype3';
const REGEX4 = '/\\s/g';
export class StringUtils {
    public static isNullOrEmpty(str: string): boolean {
        return (!str || isUndefined(str) || str.length == 0);
    }

    public static isAnyNullOrEmpty(...arr: string[]): boolean {
        for (let i = 0; i < arr.length; i++) {
            if (this.isNullOrEmpty(arr[i]))
                return false;
        }
        return true;
    }

    public static isAnyNotNullOrNotEmpty(...arr: any[]): boolean {
        for (let i = 0; i < arr.length; i++) {
            if (!this.isNullOrEmpty(arr[i]))
                return true;
        }
        return false;
    }

    public static isEqualInArray(arr: string[], val: string): boolean {
        for (let i = 0; i < arr.length; i++) {
            if (StringUtils.isEqualIgnoreCase(arr[i], val)) {
                return true;
            }
        }
        return false;
    }

    public static isEqual(str1: string, str2: string): boolean {
        return str1.toString() === str2.toString();
    }

    public static isEqualIgnoreCase(str1: string, str2: string): boolean {
        if (StringUtils.isNullOrEmpty(str1.toString()) || StringUtils.isNullOrEmpty(str2.toString())) {
            return false;
        }
        return str1.toString().toLowerCase() === str2.toString().toLowerCase();
    }

    public static clearArray(arr: any) {
        while (arr.length > 0) arr.pop();
    }

    public static formatMoney(input: number) {
      // should using en-IN
      return input.toLocaleString('hu-HU', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }

  static replaceWithRegex(value: string, regex: string) {
      const m = value.trim().replace(regex, '');
      return m;
  }

  static convertToNumberWithRegex(value: string, regex: string) {
      try {
        if (value && value.trim()) {
          if (regex === REGEX1) {
            return parseFloat(value.trim().replace('[+-]?\\d*(\\.(\\d*)?)?', ''));
          } else if (regex === REGEX2) {
            return parseFloat(value.trim().replace(/,/g, ''));
          } else if (regex === REGEX3) {
            const usMoney = value.trim().replace(/\./g, '').replace(/,/g, '.');
            return parseFloat(usMoney.trim().replace('[+-]?\\d*(\\.(\\d*)?)?', ''));
          } else if (regex === REGEX4) {
            const usMoney = value.trim().replace(/,/g, '.').replace(/\s/g, '');
            return parseFloat(usMoney.trim().replace('[+-]?\\d*(\\.(\\d*)?)?', ''));
          }
        } else {
          return 0;
        }
      } catch (e) {
        return 'invalid';
      }
  }
}

export function setURLParameters(uri, params) {
  const placeholderRegExp = /(%[A-Z_]+%)/g;
  const placeholders = uri.match(placeholderRegExp);
  if (!uri) {
    return '';
  }
  if (placeholders !== null && placeholders.length === params.length) {
    for (let i = 0; i < placeholders.length; i += 1) {
      uri = uri.replace(placeholders[i], params[i]);
    }
    return uri;
  } else if (placeholders === null) {
    return uri;
  }
}

export function clone(obj) {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error('Unable to copy obj! Its type isn\'t supported.');
}
