export const LANG_CZ_TRANS = {
  // global
  'button.save': 'Uložit',
  'button.cancel': 'Storno',
  'button.ok': 'OK',
  'button.close': 'Zavřít',
  'button.back': 'Zpět',
  'button.next': 'Další',
  'button.saveAndFinish': 'Uložit a dokončit',
  'button.remove': 'Smazat',
  'button.yes': 'Ano',
  'button.no': 'Ne',
  'button.edit': 'Upravit',
  'button.detail': 'Podrobnosti',
  'button.correctTheSetting': 'Opravte nastavení',
  'generic.select.other': 'Jiné...',
  'session.isExpired': 'Relace vypršela, přihlašte se prosím znovu.',
  'button.select': 'Vybrat',
  'button.sendInvitation': 'Send Invitation',

  // Login page
  'loginPage.title' : 'Continuity - přihlášení',
  'loginPage.email' : 'E-mail',
  'loginPage.password' : 'Heslo',
  'loginPage.remember-me' : 'Pamatuj si mě',
  'loginPage.signIn' : 'Přihlášení',
  'loginPage.forgotPassword' : 'Zapomněli jste heslo?',
  'loginPage.newToContinuity' : 'Používáte Continuity poprvé?',
  'loginPage.createAccount' : 'Registrace',
  'loginPage.or' : 'nebo',
  'loginPage.signInWithFacebook' : 'Přihlášení pomocí Facebooku',
  'loginPage.signInWithGoogle' : 'Přihlášení pomocí účtu Google',
  'loginPage.message.invalidEmailPassword': 'Neplatná emailová adresa nebo heslo',
  'loginPage.message.loginFail': 'Nezdařilé přihlášení',
  'loginPage.message.emptyField': 'Emailová adresa a heslo nemůže zůstat prázdné',

  // Register page
  'registerPage.title': 'Vytvořte si účet',
  'registerPage.nickName': 'Uživatelské jméno',
  'registerPage.email': 'E-mail',
  'registerPage.password': 'Heslo',
  'registerPage.confirmPassword': 'Potvrďte heslo',
  'registerPage.language': 'Jazyk',
  'registerPage.signUp': 'Registrace',
  'registerPage.nickNameRequired': 'Zadejte uživatelské jméno',
  'registerPage.emailRequired': 'Zadejte emailovou adresu',
  'registerPage.emailInvalid': 'Neplatná emailová adresa',
  'registerPage.passwordRequired': 'Zadejte heslo',
  'registerPage.passwordInvalid': 'Heslo musí mít alespoň 6 znaků',
  'registerPage.confirmPasswordRequired': 'Potvrďte heslo zadáním',
  'registerPage.confirmPasswordNotMatched': 'Zadaná hesla nesouhlasí',
  'registerPage.languageRequired': 'Je třeba vybrat jazyk',
  'registerPage.message.success': 'Registrace proběhla úspěšně. Zkontrolujte si prosím emailovou adresu, na kterou jsme Vám zaslali zprávu pro potvrzení',
  'registerPage.message.fail': 'Neúspěšná registrace.',
  'registerPage.agreed.fail': 'Službu Continuity můžete používat po přijetí Podmínek služby a Prohlášení o ochraně osobních údajů.',
  'registerPage.readMessage': 'Přečetl jsem si to a přijímám',
  'registerPage.termOfService': 'Podmínky služby',
  'registerPage.privacyPolicy': 'Prohlášení o ochraně osobních údajů',

  // active user page
  'activeAccountPage.verifySuccess': 'Vaše registrace byla potvrzena',
  'activeAccountPage.verifyFail': 'Vaši registraci nebylo možné potvrdit',

  // Accounts page
  'accountsPage.header.currentBalance': 'Aktuální zůstatek',
  'accountsPage.header.totalBalance': 'Celkový zůstatek',
  'accountsPage.generalInformation' : 'Obecné informace',
  'accountsPage.giroNumber' : 'Číslo bankovního účtu',
  'accountsPage.swift' : 'SWIFT/BIC kód',
  'accountsPage.lastUpdate': 'Naposledy aktualizováno',
  'accountsPage.addTransaction': 'Přidání transakce',
  'accountsPage.uploadTransaction': 'Načtení transakce',
  'accountsPage.updateAt': 'Upraveno',
  'accountsPage.name': 'Jméno',
  'accountsPage.nameRequired': 'Zadání jména je povinné',
  'accountsPage.nature': 'Peněženka nebo účet?',
  'accountsPage.natureRequired': 'Je nutné zadat, zda se jedná o peněženku nebo bankovní účet',
  'accountsPage.source': 'Zdroj dat',
  'accountsPage.sourceRequired': 'Je nutné zadat zdroj',
  'accountsPage.currency': 'Měna',
  'accountsPage.currencyRequired': 'Je nutné vybrat měnu',
  'accountsPage.initialBalance': 'Počáteční zůstatek',
  'accountsPage.initialDate': 'Vyberte datum počátečního zůstatku',
  'accountsPage.accountDetail': 'Podrobné údaje o účtu',
  'accountsPage.iban': 'IBAN (mezinárodní bankovní účet)',
  'accountsPage.accountNumber': 'Číslo bankovního účtu',
  'accountsPage.bic': 'BIC kód',
  'accountsPage.ibanOrAccountNumberRequired': 'Je nutné uvést číslo IBAN nebo číslo bankovního účtu',
  'accountPage.message.updateSuccess' : 'Údaje o účtu byly úspěšně změněny',
  'accountPage.message.updateError' : 'Informace o účtu se nepodařilo změnit',
  'accountPage.message.createSuccess' : 'Účet byl úspěšně přidán',
  'accountPage.message.createError' : 'Účet se nepodařilo přidat',
  'accountPage.nature.ACCOUNT': 'Bankovní účet',
  'accountPage.nature.CASH': 'Hotovost',
  'accountPage.nature.CREDIT': 'Úvěr',
  'accountPage.nature.CREDIT_CARD': 'Úvěrová karta',
  'accountPage.nature.BUILDING_SOCIETY': 'Building Society',
  'accountPage.nature.INVESTMENT': 'Investment',
  'accountPage.nature.ASSET': 'Asset',
  'accountPage.source.MANUAL': 'Ruční zadání',
  'accountPage.source.FILE': 'Soubor',
  'accountPage.source.TRANSFER': 'Transfer',
  'accountsPage.invalidPattern': 'Neplatný formát čísla',
  'accountPage.remove.success' : 'Účet byl úspěšně smazán',
  'accountPage.remove.fail' : 'Smazání účtu se nezdařilo',
  'accountPage.removeConfirmation.message' : 'Opravdu chcete účet smazat?',
  'accountPage.ibanAccountNumber.waningMessage': 'Zadání čísla IBAN nebo čísla bankovního účtu pomáhá funkčnosti procesům, jako je např. automatické štítkování',
  'accountsPage.generalInformationNotice': 'These settings can not be changed later. Select your choice carefully!',
  'accountsPage.accountDetailNotice': 'You can enter these data later, but once you have saved them you can not modify them.',

  // profile detail page
  'profilePage.name' : 'Jméno',
  'profilePage.nameRequired' : 'Zadání jména je povinné',
  'profilePage.currency' : 'Měna',
  'profilePage.currencyRequired' : 'Zadání měny je povinné',
  'profilePage.message.getFail' : 'Načtení údajů profilu se nezdařilo',
  'profilePage.message.updateSuccess' : 'Údaje o profilu byly úspěšně změněny',
  'profilePage.message.updateFail' : 'Nepodařilo se změnit údaje o profilu',
  'profilePage.profileImage' : 'Profilový obrázek',
  'profilePage.profileImageSelect' : 'Vyberte profilový obrázek.',
  'profilePage.selectFileToUpload': 'Vyberte soubor, který chcete nahrát.',
  'profilePage.cropImage': 'Oříznutí obrázku',
  'profilePage.preview': 'Vzhled',

  // transaction page
  'transactionPage.note' : 'Poznámka',
  'transactionPage.comment' : 'Popis',
  'transactionPage.selectLabelOfCategory': 'Vyberte štítek',
  'transactionPage.direction': 'Směr transakce',
  'transactionPage.nameOfPartner': 'Jméno partnera',
  'transactionPage.amount': 'Částka',
  'transactionPage.currency': 'Měna',
  'transactionPage.dateOfTransaction': 'Datum transakce',
  'transactionPage.regularFlag': 'Pravidelnost',
  'transactionPage.description': 'Popis',
  'transactionPage.ibanOfPartner': 'Mezinárodní číslo účtu partnera (IBAN)',
  'transactionPage.accountBicOfPartner': 'BIC kód partnera',
  'transactionPage.accountNumberOfThePartner': 'Číslo bankovního účtu partnera',
  'transactionPage.cardNumber': 'Číslo bankovní karty',
  'transactionPage.originalAmount': 'Původní částka',
  'transactionPage.originalCurrency': 'Původní měna',
  'transactionPage.dateOfBooking': 'Účetní datum',
  'currency.required': 'Zadání měny je povinné',
  'amount.required': 'Je třeba vybrat sloupec částky',
  'amount.min': 'Zadání záporné částky není povoleno',
  'nameOfPartner.required': 'Jméno partnera je povinné',
  'dateOfTransaction.required': 'Musí být vybráno datum transakce',
  'transactionPage.generalInformation': 'Obecné informace',
  'transactionPage.accountInformation': 'Informace o účtu',
  'transactionPage.message.createSuccess': 'Transakce byla úspěšně uložena',
  'transactionPage.message.createFail': 'Uložení transakce se nezdařilo',
  'transactionPage.message.getAccountFail': 'Načtení informací o účtu se nezdařilo',
  'transactionPage.message.getLabelFail': 'Načtení údajů značky se nezdařilo',
  'transactionPage.message.getTransactionFail': 'Načtení údajů transakce se nezdařilo',
  'transactionPage.message.updateSuccess': 'Údaje transakce byla úspěšně změněny',
  'transactionPage.message.updateFail': 'Úprava údajů transakce se nezdařila',
  'transactionPage.message.getBalanceFail': 'Dotaz na zůstatek se nezdařil',
  'transactionPage.message.getDefaultLabelCategory': 'Načtení výchozích kategorií se nezdařilo',
  'transactionPage.message.getListOfTransactionFail': 'Načtení transakcí se nezdařilo',
  'transactionPage.regularFlag.NO': 'Nepravidelné',
  'transactionPage.regularFlag.WEEKLY': 'Týdně',
  'transactionPage.regularFlag.MONTHLY': 'Měsíčně',
  'transactionPage.regularFlag.EVERY_SECOND_MONTH': 'Co 2 měsíce',
  'transactionPage.regularFlag.QUARTERLY': 'Čtvrtletně',
  'transactionPage.regularFlag.EVERY_HALF_YEAR': 'Co půl roku',
  'transactionPage.regularFlag.YEARLY': 'Ročně',
  'transactionPage.regularFlag.OTHER': 'S jinou frekvencí',
  'transactionPage.addNewTransaction': 'Přidejte novou transakci',
  'transactionPage.updateTransaction': 'Aktualizujte transakci',
  'transactionPage.removeTransaction.success': 'Transakce byla úspěšně smazána',
  'transactionPage.removeTransaction.fail': 'Transakci se nepodařilo smazat',
  'transactionPage.removeConfirmation.message': 'Opravdu chcete smazat transakci?',

  // UPLOAD-CSV SETTING PAGE
  'CSV-LOADER.FILE': 'Soubor',
  'CSV-LOADER.FIRST-STEP.TITLE': 'Nastavení nového zdroje dat pro načítání',
  'CSV-LOADER.FIRST-STEP.SELECT-CSV': 'Vyberte soubor CSV',
  'CSV-LOADER.FIRST-STEP.PDF': 'Pouze PDF soubor',
  'CSV-LOADER.FIRST-STEP.PLEASE-SELECT': 'Výběr souboru',
  'File.required': 'Vyberte soubor.',
  'CSV-LOADER.SECOND-STEP.TITLE': 'Nastavení načítání',
  'CSV-LOADER.SECOND-STEP.PREVIEW': 'Konvertovaný pohled',
  'CSV-LOADER.SECOND-STEP.CONFIGURATION': 'Nastavení',
  'CSV-LOADER.SECOND-STEP.DELIMITER': 'Hraniční znak pole',
  'CSV-LOADER.SECOND-STEP.QUOTE-CHARACTER': 'Typ uvozovky',
  'CSV-LOADER.SECOND-STEP.CHARACTER-SET': 'Kódování znaků',
  'CSV-LOADER.SECOND-STEP.HEADER-ROW': 'Počet řádků záhlaví',
  'numberOfHeaderLines.min.0': 'Počet řádků v záhlaví nesmí být záporný',
  'CSV-LOADER.SECOND-STEP.TRY-PARSE': 'Zkuste soubor interpretovat',
  'CSV-LOADER.THIRD-STEP.TITLE': 'Zobrazit interpretaci dat',
  'CSV-LOADER.THIRD-STEP.SELECT_COLUMN_INSTRUCTIONS': 'Vyberte alespoň následující sloupce:',
  'dateFormat.required': 'Je třeba vybrat formát data',
  'numberFormat.required': 'Je třeba zvolit formát čísla',
  'CSV-LOADER.FOURTH-STEP.STAGE-DATA': 'Předběžné načtení',
  'CSV-LOADER.SECOND-STEP.SELECT-REQUIRED-COLUMNS': 'Vyberte povinné sloupce:',

  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SEMICOLON': 'Středník',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COMMA': 'Čárka',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COLON': 'Dvojtečka',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.PIPE': 'Svislá čára',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.HASHMARK': 'Dvojitý kříž',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SPACE': 'Mezera',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.TAB': 'Tabulátor',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.NONE': 'Není',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.DOUBLE-QUOTE': 'Dvojitá uvozovka',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.SINGLE-QUOTE': 'Jednoduchá uvozovka',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.BACKSLASH' : 'Zpětné lomítko',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.DOUBLE-QUOTE' : 'Dvojitá uvozovka',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.SINGLE-QUOTE' : 'Jednoduchá uvozovka',
  'FIELDS.TRANSACTION.IGNORE': 'Ignorovat',
  'FIELDS.TRANSACTION.amount': 'Částka',
  'FIELDS.TRANSACTION.currency': 'Měna',
  'FIELDS.TRANSACTION.dateOfTransaction': 'Datum transakce',
  'FIELDS.TRANSACTION.dateOfBooking': 'Účetní datum',
  'FIELDS.TRANSACTION.amountIn': 'Připsaná částka',
  'FIELDS.TRANSACTION.amountOut': 'Odečtená částka',
  'FIELDS.TRANSACTION.directionFlag': 'Směr transakce',
  'FIELDS.TRANSACTION.namePartner': 'Název partnera',
  'FIELDS.TRANSACTION.note': 'Poznámka',
  'FIELDS.TRANSACTION.ibanPartner': 'Mezinárodní číslo účtu partnera (IBAN)',
  'FIELDS.TRANSACTION.accountBicPartner': 'BIC kód banky partnera',
  'FIELDS.TRANSACTION.accountNumberPartner': 'Číslo účtu partnera',
  'FIELDS.TRANSACTION.cardNo': 'Číslo bankovní karty',
  'FIELDS.TRANSACTION.amountOrig': 'Původní částka',
  'FIELDS.TRANSACTION.currencyOrig': 'Původní měna',
  'FIELDS.TRANSACTION.typeOfTransaction': 'Typ transakce',
  'FIELDS.TRANSACTION.description': 'Popis',
  'FIELDS.TRANSACTION.externalId': 'ID banky',
  'CSV-LOADER.MAP-COLUMNS.INCOME.LABEL' : 'Značka příjmů',
  'CSV-LOADER.MAP-COLUMNS.EXPENSE.LABEL' : 'Značka nákladů',
  'CSV-LOADER.MAP-COLUMNS.USE-SIGN.LABEL': 'Použijte znaménko částky',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.CUSTOM.LABEL': 'Vlastní formát',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.LABEL': 'Vyberte prosím formát data',
  'CSV-LOADER.MAP-COLUMNS.NUMBER-FORMAT.LABEL' : 'Vyberte prosím formát částky',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.SUGGEST-BUTTON': 'Navrhněte formát',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SEPARATOR.LABEL': 'Oddělovač desetinných míst',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SUGGEST-BUTTON': 'Navrhovaný oddělovač desetinných míst',
  'CSV-LOADER.MAP-COLUMNS.ERROR.NO-DATE-COLUMN': 'Vyberte prosím sloupec „Datum transakce“ ',
  'CSV-LOADER.SAVE.TITLE': 'Načtení dat',
  'CSV-LOADER.SAVE.RESULT': 'Načtení výsledku',
  'CSV-LOADER.SAVE.RESULT.SUCCESS': 'Transakce byly úspěšně nahrány.',
  'CSV-LOADER.SAVE.RESULT.FAIL': 'Transakce se nepodařilo načíst.',
  'CSV-LOADER.SAVE.INVALID-AMOUNT': 'Neplatná částka',
  'CSV-LOADER.SAVE.SAVE-SETTING-FAIL': 'Nastavení se nepodařilo uložit',
  'CSV-LOADER.SAVE.SAVE-STAGE-DATA-FAIL': 'Nepodařilo se načíst data',
  'CSV-LOADER.SAVE.CANCEL.SUCCESS': 'Načtení transakcí bylo přerušeno',
  'CSV-LOADER.OVERLAPPING.MODAL.TITLE': 'Překrývající se transakce',
  'CSV-LOADER.OVERLAPPING.MODAL.SUBTITLE': 'Data dříve načtených transakcí se překrývají s daty transakcí, které chcete nyní načíst.',
  'CSV-LOADER.OVERLAPPING.MODAL.SELECT': 'Vyberte jednu z možností:',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Append': 'Zachovat načtené transakce a přidat z aktuálního souboru období, které dříve nebylo zahrnuto.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Substitute': 'Přepsat dříve načtené transakce těmi v aktuálním souboru.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Merge': 'Transakce za dříve načtené období doplním transakcemi v tomto souboru. ',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Cancel': 'Stornovat načtení transakcí.',

  // first-time-login
  'firstTimeLoginPage.title' : 'Vítejte v Continuity!',
  'firstTimeLoginPage.subTitle': 'Zadejte prosím své první informace o účtu!',

  // menu items
  'menu.item.Overview': 'Přehled',
  'menu.item.AccountList': 'Moje účty',
  'menu.item.Profile': 'Profil',
  'menu.item.Analytics': 'Analýza',
  'menu.item.signOut': 'Odhlásit se',
  'menu.item.deleteMyAccount': 'Smazat uživatelský účet',
  'menu.item.Cash-flow': 'Tok peněz',
  'menu.item.inviteNewUser': 'Invite new user',
  'menu.item.help': 'Help',

  // enum icons
  'enum.name.ACCOMODATION' : 'Bydlení',
  'enum.name.TRANSPORT' : 'Doprava',
  'enum.name.MEALS' : 'Strava',
  'enum.name.CLOTHING' : 'Oblečení',
  'enum.name.ALCOHOL_TOBACCO' : 'Alkohol a Tabák',
  'enum.name.BANK' : 'Banka',
  'enum.name.EDUCATION' : 'Osobní rozvoj',
  'enum.name.ENTERTAINMENT' : 'Zábava',
  'enum.name.GIFT' : 'Dary',
  'enum.name.HOBBY' : 'Koníčky',
  'enum.name.SPORT' : 'Sport',
  'enum.name.HOLIDAY' : 'Dovolená',
  'enum.name.HYGIENE_HOUSEHOLD' : 'Hygiena a Domácnost',
  'enum.name.TELECOMMUNICATION' : 'Telekomunikace',
  'enum.name.HEALTH_CARE' : 'Zdravotní péče',
  'enum.name.OTHER' : 'Ostatní',
  'enum.name.SPOUSE' : 'Partner',
  'enum.name.MYSELF' : 'Já',
  'enum.name.MOM' : 'Matka',
  'enum.name.DAD' : 'Otec',
  'enum.name.FAMILY' : 'Rodina',
  'enum.name.FRIENDS' : 'Přátelé',
  'enum.name.IN-LAWS' : 'Příbuzní',
  'enum.name.CHILD1' : 'Dítě  1',
  'enum.name.CHILD2' : 'Dítě  2',
  'enum.name.CHILD3' : 'Dítě  3',
  'enum.name.CHILD4' : 'Dítě  4',
  'enum.name.CHEMICALS' : 'Chemikálie',
  'enum.name.ENERGY' : 'Energie',
  'enum.name.EQUIPMENT' : 'Vybavení',
  'enum.name.FIXTURE' : 'Díly',
  'enum.name.FOOD' : 'Potraviny',
  'enum.name.GADGET' : 'Přístroje',
  'enum.name.INSURANCE' : 'Pojištění',
  'enum.name.MAINTENANCE' : 'Údržba',
  'enum.name.PENALTIES' : 'Pokuta',
  'enum.name.RENTAL' : 'Pronájem',
  'enum.name.TAX' : 'Daně',
  'enum.name.TICKET_SERVICE' : 'Služby / Lístky',
  'enum.name.EMPLOYER' : 'Zaměstnavatel',
  'enum.name.CLIENT' : 'Klient',
  'enum.name.EARNING_FROM_INVESTMENT' : 'Výdělky z investic',
  'enum.name. RENTAL' : 'Pronájem',
  'enum.name.SALE_OF_ASSET' : 'Prodej aktiv',
  'enum.name.PET' : 'Háziállat',
  'enum.name.STATE' : 'Stát',
  'enum.name.TRANSFER' : 'Převod mezi účty',
  'enum.name.INCOME_LABELED_AS_TRANSFER' : 'Chybí protiúčet příjmu',
  'enum.name.EXPENSE_LABELED_AS_TRANSFER' : 'Chybí protiúčet výdaje',
  'enum.name.SUPPORT' : 'Pomoc/Podpora',
  'enum.name.SALARY' : 'Plat',
  'enum.category.TRANSFER' : 'Převod mezi účty',
  'enum.category.TITLE': 'Titul',
  'enum.category.PAYING': 'Platící',
  'enum.category.PURPOSE': 'Důvod',
  'enum.category.BENEFICIARY': 'Příjemce',
  'enum.category.TOOL_SERVICE': 'Nástroj nebo služba',
  'enum.category.THIS_MONTH': 'Tento měsíc',
  'enum.category.LAST_MONTH': 'Minulý měsíc',
  'enum.category.THIS_YEAR': 'Tento rok',
  'enum.category.LAST_YEAR': 'Minulý rok',
  'enum.category.THIS_WEEK': 'Tento týden',
  'enum.category.LAST_WEEK': 'Minulý týden',
  'enum.category.TODAY': 'Dnes',
  'enum.category.CUSTOM': 'Vlastní nastavení',

  // direction flag
  'direction.flag.INCOME': 'Příjem',
  'direction.flag.EXPENSE': 'Výdaj',
  'direction.flag.OUT_GOING_TRANSFER': 'Převod na jiný účet',
  'direction.flag.INCOMING_TRANSFER': 'Převod z jiného účtu',

  // analytics page
  'analyticsPage.incomeChart': 'Příjmy',
  'analyticsPage.expenseChart': 'Výdaje',
  'analyticsPage.noIncomeData': 'V daném období nejsou žádné příjmy',
  'analyticsPage.noExpenseData': 'V daném období nejsou žádné výdaje',
  'analyticsPage.getLabelFail': 'Dotaz na údaje štítku se nezdařil',
  'analyticsPage.getChartDataFail': 'Dotaz na údaje grafu se nezdařil',
  'analyticsPage.totalAmount': 'Celková částka',
  'analyticsPage.selectIncomeCategory': 'Vyberte rozmezí příjmů',
  'analyticsPage.selectExpenseCategory': 'Vyberte rozmezí výdajů',
  'analyticsPage.selectTimePeriod': 'Vybrané období',
  'analyticsPage.from': 'Od kdy?',
  'analyticsPage.to': 'Do kdy?',

  // cash-flow page
  'cash-flow.selectYourAccount': 'Vyberte účet',
  'cash-flow.allAccountOfYourProfile': 'Všechny účty profilu',
  'cash-flow.chartHeader': 'Peněžní tok',
  'cash-flow.subtextOfAnAccount': '',
  'cash-flow.subtextOfAllAccount': 'Souhrn',
  'cash-flow.warningMessage': 'Plán peněžních toků Continuity připravuje pouze na rok dopředu.',

  // invite-users page
  'inviteUserPage.email': 'Email',
  'inviteUserPage.message': 'Message',
  'inviteUserPage.emailRequired': 'Email is required',
  'inviteUserPage.emailInvalid': 'Email must be a valid email address',
  'inviteUserPage.messageRequired': 'Message is required',
  'inviteUsersPage.message.sendSuccess': 'Invite new user successfully',
  'inviteUsersPage.message.sendFail': 'Invite new user fail',
  'inviteUsersPage.message.defaultMessage': 'Please enter your messages...',
  'inviteUsersPage.email.subject': 'invited you to use Continuity',
  'inviteUsersPage.email.button': 'Register to Continuity',

  // error message
  'error.INITIAL_BALANCE_IS_LATER' : 'Transakce byla zadána před počátečním zůstatkem.',
  'error.FAILED' : 'Při výpočtu zůstatků na účtech došlo k chybě',

  // tool tip
  // add account page
  'toolTip.addAccount.name': 'Pojmenujte svůj účet, není nutné aby se shodoval se názvem ve finanční instituci. Jde o to, abyste toto jméno rozpoznal.',
  'toolTip.addAccount.nature': '#',
  'toolTip.addAccount.source': 'Své bankovní údaje můžete pravidelně nahrávat  ze souboru staženého z vašeho internetového bankovnictví. Nákupy v hotovosti doporučujeme zaznamenat v mobilní verzi Continuity co nejdříve.',
  'toolTip.addAccount.currency': 'Pokud máte u sebe v hotovosti více měn, přidejte pro každou měnu samostatnou peněženku.',
  'toolTip.addAccount.dateOfInitialBalance': 'Vyberte datum, ke kterému znáte zůstatek na konci dne.',
  'toolTip.addAccount.initialBalance': 'K datu zadejte zůstatek na konci dne. Není to povinné. Pokud jej nezadáte, Continuity nebude moci vypočítat zůstatek na účtu z kteréhokoliv dne.',
  'toolTip.addAccount.iban': 'Mezinárodní číslo bankovního účtu začínající kódem země a dvěma kontrolními číslicemi. Pokud jej zadáte, Continuity usnadní identifikaci převodů mezi vašimi účty. Nepovinné.',
  'toolTip.addAccount.accountNumber': 'Tuzemské číslo bankovního účtu. 2krát nebo 3krát 8 číslic. Pokud jej zadáte, Continuity usnadní identifikaci převodů mezi vašimi účty. Nepovinné.',
  'toolTip.addAccount.bic': 'Mezinárodní označení vaší banky. Známý také jako kód Swift.  Pokud jej zadáte, Continuity usnadní identifikaci převodů mezi vašimi účty. Nepovinné.',
  'toolTip.addAccount.saveBtn': 'Po uložení můžete stále změnit podrobnosti účtu, ale pokud jste již pro účet zaznamenali transakci, data již nelze změnit, kromě jména a zůstatku.',
  'toolTip.addAccount.resetBtn': 'Smazat dosud zadaná data.',

  // edit account page
  'toolTip.editAccount.name': 'Název svého účtu můžete kdykoli změnit.',
  'toolTip.editAccount.nature': 'Pokud jste již na účtu zaznamenali transakci, typ účtu již nelze změnit.',
  'toolTip.editAccount.source': 'Pokud jste již na účtu zaznamenali transakci, zdroj dat účtu již nelze změnit. ',
  'toolTip.editAccount.currency': 'Pokud jste již na účtu zaznamenali transakci, měnu účtu již nelze změnit.',
  'toolTip.editAccount.dateOfInitialBalance': 'Vyberte datum, ke kterému znáte zůstatek na konci dne.',
  'toolTip.editAccount.initialBalance': 'K datu zadejte zůstatek na konci dne. Není to povinné. Pokud jej nezadáte, Continuity nebude moci vypočítat zůstatek na účtu z kteréhokoliv dne.',
  'toolTip.editAccount.iban': 'Pokud jste již na účtu zaznamenali transakci, IBAN již nelze změnit.',
  'toolTip.editAccount.accountNumber': 'Pokud jste již na účtu zaznamenali transakci, číslo účtu již nelze změnit.',
  'toolTip.editAccount.bic': 'Pokud jste již na účtu zaznamenali transakci, kód BIC již nelze změnit.',
  'toolTip.editAccount.saveBtn': 'Změna údajů o účtu podle právě zadaných údajů.',
  'toolTip.editAccount.cancelBtn': 'Obnovení dřívějších dat.',
  'toolTip.editAccount.deleteBtn': 'Smazat účet se všemi souvisejícími transakcemi a nastaveními.',

  // add transaction page
  'toolTip.addTransaction.direction': 'Směr transakce automaticky určí také její znaménko. Pokud chcete zaznamenat opravnou položku, po uložení otevřete transakci pro úpravy, kde můžete změnit znaménko.',
  'toolTip.addTransaction.dateOfTransaction': 'Pokud se transakce neuskutečnila dnes, nezapomeňte nastavit datum.',
  'toolTip.addTransaction.amount': 'Zadejte částku bez znaménka.',
  'toolTip.addTransaction.namePartner': 'Společnost či osoba, které jste zaplatil, nebo která zaplatila vám.',
  'toolTip.addTransaction.regularFlag': 'Na základě pravidelně označených transakcí Continuity připravuje plán peněžních toků na jeden rok dopředu. Pokud jste dříve označili takovou transakci jako pravidelnou, neoznačujte ji znovu, protože bude zahrnuta do plánu dvakrát.',
  'toolTip.addTransaction.description': 'Informace o transakci, které považujete za důležité.',
  'toolTip.addTransaction.note': 'Vaše poznámka.',
  'toolTip.addTransaction.saveBtn': 'Po uložení lze transakci otevřít pro úpravy, pokud byl některý z údajů zadán nesprávně.',
  'toolTip.addTransaction.cancelBtn': 'Smažte dosud zadané údaje.',

  // the following seven fields do not appear on the data entry form
  'toolTip.addTransaction.ibanPartner': 'toolTip.addTransaction.ibanPartner',
  'toolTip.addTransaction.accountBicPartner': 'toolTip.addTransaction.accountBicPartner',
  'toolTip.addTransaction.accountNumberPartner': 'toolTip.addTransaction.accountNumberPartner',
  'toolTip.addTransaction.cardNo': 'toolTip.addTransaction.cardNo',
  'toolTip.addTransaction.amountOrig': 'toolTip.addTransaction.amountOrig',
  'toolTip.addTransaction.currency': 'toolTip.addTransaction.currency',
  'toolTip.addTransaction.dateOfBooking': 'toolTip.addTransaction.dateOfBooking',

  // edit transaction page
  'toolTip.editTransaction.directionFlag': 'Transakci s kladným znaménkem můžete také označit jako výdaj, pokud např. od poskytovatele obdržíte náhradu za přeplatek. Snižuje tedy vaše výdaje a nezvyšuje příjmy.',
  'toolTip.editTransaction.dateOfTransaction': 'Když byla platba provedena. Pokud je zdroj údajů ze souboru, nelze je upravit.',
  'toolTip.editTransaction.amount': 'Zaplacená částka v měně účtu. Pokud je zdroj údajů ze souboru, nelze je upravit.',
  'toolTip.editTransaction.namePartner': 'Společnost nebo osoba, které jste zaplatil, nebo která vám zaplatila. Pokud je zdroj údajů ze souboru, nelze je upravit.',
  'toolTip.editTransaction.regularFlag': 'Na základě transakcí označených jako pravidelné připravuje Continuity plán peněžních toků na jeden rok dopředu. Typ transakce označte jako pravidelný pouze jednou! Je vhodné označit poslední výskyt.',
  'toolTip.editTransaction.description': 'Pokud je zdroj údajů ze souboru, nelze je upravit.',
  'toolTip.editTransaction.note': 'Pokud je zdroj údajů ze souboru, nelze je upravit.',
  'toolTip.editTransaction.ibanPartner': 'Údaje ze souboru nelze upravit.',
  'toolTip.editTransaction.accountBicPartner': 'Údaje ze souboru nelze upravit.',
  'toolTip.editTransaction.accountNumberPartner': 'Údaje ze souboru nelze upravit.',
  'toolTip.editTransaction.cardNo': 'Číslo karty použité pro transakci. Údaje ze souboru nelze upravit.',
  'toolTip.editTransaction.amountOrig': 'Pokud transakce nebyla provedena v měně účtu, původní částka. Údaje ze souboru nelze upravit.',
  'toolTip.editTransaction.currencyOrig': 'Pokud transakce nebyla v měně účtu, jedná se o původní měnu. Údaje ze souboru nelze upravit.',
  'toolTip.editTransaction.dateOfBooking': 'Když vaše finanční instituce zaúčtovala transakci. Údaje ze souboru nelze upravit.',
  'toolTip.editTransaction.saveBtn': 'Uložit změny.',
  'toolTip.editTransaction.cancelBtn': 'Obnovení předchozích údajů.',
  'toolTip.editTransaction.removeBtn': 'Transakci můžete smazat, pokud jste ji omylem nahráli nebo zaznamenal vícekrát.',

  // account upload csv page
  'toolTip.accountUpload.fileName': 'toolTip.accountUpload.fileName',
  'toolTip.accountUpload.delimiter': 'Systém automaticky určuje, jaký znak odděluje údaje. Změnu proveďte, pouze pokud jste si jist.',
  'toolTip.accountUpload.quote': 'Systém automaticky určí, zda jsou data uzavřena v uvozovkách.  Změnu proveďte, pouze pokud jste si jist.',
  'toolTip.accountUpload.charset': 'Systém automaticky určí znakovou sadu použitou v souboru. Změnu proveďte, pouze pokud jste si jist.',
  'toolTip.accountUpload.numberOfHeaderLines': 'Nastavte počet řádků záhlaví po kterých následují skutečná data.',
  'toolTip.accountUpload.selectColumn': 'Automatické kategorizaci napomáhá, pokud kromě povinných polí vyplníte také jméno partnera, číslo účtu nebo IBAN a komentář.',
  'toolTip.accountUpload.dateFormat': 'Věnujte pozornost pořadí, odělovači a způsobu zápisu roku, měsíce a dne. Na základě těchto údajů si vyberte formát data.',
  'toolTip.accountUpload.numberFormat': 'Zjistěte, zda čísla obsahují desetinnou čárku nebo tečku. Pokud existuje oddělovací znak pro tisíce, zjistěte který to je. Na základě toho zvolte formát čísla.',
  'toolTip.accountUpload.backBtn': 'Vrátit se zpět k výběru souboru. ',
  'toolTip.accountUpload.nextBtn': 'Načíst vybraný soubor.',
  'toolTip.accountUpload.correctTheSettingBtn': 'Pokud zjistíte, že je potřeba změnit nastavení předešlého načtení, můžete se pomocí tohoto tlačítka vrátit zpět.',
  'toolTip.accountUpload.saveAndFinishBtn': 'Pokud jste s předběžným načtením spokojen, dokončete operaci.',

  // profile page
  'toolTip.profileDetail.name': 'Profile Detail Name Tooltip',
  'toolTip.profileDetail.currency': 'Profile Detail Currency Tooltip',
  'toolTip.profileDetail.attachFile': 'Profile Detail Attach Tooltip',
  'toolTip.profileDetail.removeBtn': 'Profile Detail Remove Account Tooltip',

  // sent new user page
  'toolTip.inviteUsers.email': 'Invite User email tooltip',
  'toolTip.inviteUsers.message': 'Invite User Message Tooltip',

  // transaction filter modal page
  'transactionFilterModal.title': 'Výpis kategorií: ',
  'transactionFilterModal.accountName': 'Název účtu',
  'transactionFilterModal.dateOfTransaction': 'Datum tranzakce',
  'transactionFilterModal.amount': 'Částka',
  'transactionFilterModal.descAndNote': 'Popis/Poznámka',
  'transactionFilterModal.partnerName': 'Jméno partnera',

  // delete my user account modal
  'deleteUserAccount.confirmMessage1': 'Opravdu chcete smazat svůj účet?',
  'deleteUserAccount.confirmMessage2': 'Po smazání nebudou vaše údaje možné obnovit a již se nebudete moci přihlásit pomocí svého současného uživatelského účtu.\n Opravdu chcete smazat svůj účet?',
  'deleteUserAccount.deleteSuccessfully': 'Váš uživatelský účet byl úspěšně smazán.',
  'deleteUserAccount.deleteUnsuccessfully': 'Váš uživatelský účet se nepodařilo smazat. Kontaktujte prosím náš zákaznický servis.',

  // help popup
  'helpPopup.step0.title': 'Overview',
  'helpPopup.step0.intro': 'Provide a summary of all of your account balances. You can create or upload transactions from this screen',
  'helpPopup.step1.title': 'Accounts Page',
  'helpPopup.step1.intro': 'Display a list of your accounts. You can create, edit or delete your accounts',
  'helpPopup.step2.title': 'Profile Page',
  'helpPopup.step2.intro': 'You can edit your name, default currency, and avatar on that page. You also able to delete your profile from the system, but please becareful',
  'helpPopup.step3.title': 'Analytics Page',
  'helpPopup.step3.intro': 'Gives you an overview of your income and expenses by providing statistical graphs',
  'helpPopup.step3.imageUrl': 'https://live.staticflickr.com/65535/51930070200_95da6a62ff_c.jpg',
  'helpPopup.step4.title': 'Cash-flow Page',
  'helpPopup.step4.intro': 'A cash flow visualization from time to time. You can select a specific account or all your accounts',
  'helpPopup.step4.imageUrl': 'https://live.staticflickr.com/65535/51928480722_346507237f_b.jpg',

  // first time login initial help
  'firstTimeLogin.popup.name': 'Please input name of your account, you can edit that name later.',
  'firstTimeLogin.popup.nature': 'Please input your Account Nature.',
  'firstTimeLogin.popup.source': 'Please input your account source.',
  'firstTimeLogin.popup.currency': 'Please select the currency for your account.',
};
