import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionService } from '../../service/transaction.service';
import { ToastServices } from '../../shared/toast.service';
import { TranslateService } from '../../translate';
import { TransactionFilterModel } from './transaction-filter-model';

@Component({
  selector: 'app-transaction-label-modal',
  templateUrl: './transaction-filter-modal.component.html',
  styleUrls: ['./transaction-filter-modal.component.css']
})
export class TransactionFilterModalComponent implements AfterViewInit  {

  constructor(
    private transactionService: TransactionService,
    private translateService: TranslateService,
    private toastServices: ToastServices,
    @Inject(MAT_DIALOG_DATA) public data: TransactionFilterModel) {}

    displayedColumns: string[] = this.data.displayedColumns;

    ngAfterViewInit() {
    }

}
