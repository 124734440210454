import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../service/profile.service';
import { UserService } from '../../service/user.service';
import { accountDetailMandatory } from '../../shared/helper/form.validator';
import { Router } from '@angular/router';
import { ToastServices } from '../../shared/toast.service';
import { CommonService } from '../../service/common.service';
import { ProfileModel } from './profile.model';
import { TranslateService } from '../../translate';
import { TransactionModel } from '../../transactions/transaction.model';
import { DataSharingService } from '../../shared/data-service-change';
import { BasePage } from '../../shared/BasePage';
import { ConfirmPopupComponent } from '../../shared/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HELP_POPUP_STEP } from '../../shared/data.utils';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.css']
})
export class ProfileDetailComponent extends BasePage implements OnInit  {

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private profileService: ProfileService,
              private commonService: CommonService,
              private toastService: ToastServices,
              private dataSharingService: DataSharingService,
              private translateService: TranslateService,
              private userService: UserService,
              private dialog: MatDialog,
              private authenService: AuthenticationService) {
    super();
  }
  updateProfileForm: FormGroup;
  listCurrency: [];
  languages = [];
  profile = new ProfileModel();
  profileImage = '';
  imageL = false;
  @Input() public firstTimeLogin = false;

  ngOnInit() {
    this.initCommonData();
    this.initFormGroup();
    this.initProfileData();
    this.checkFirstTimeLogin(HELP_POPUP_STEP.PROFILE, this.dataSharingService);
  }

  resetForm() {
    this.initProfileData();
    this.router.navigate([`/profile-detail`]);
    this.profileImage = null;
  }

  async onSubmit() {
    if (this.updateProfileForm.invalid) {
      return;
    }

    const formValue = this.updateProfileForm.value;
    const profileObj = {
      id: this.profile.id,
      userId: this.profile.userId,
      currency: formValue.currency,
      name: formValue.name,
      accountingPeriod: this.profile.accountingPeriod,
      type: this.profile.type,
      deleted: this.profile.deleted,
      profileImage: this.profileImage,
    };

    await this.userService.updateLanguage(formValue.language);
    localStorage.setItem('language', formValue.language);

    this.profileService.setProfile(profileObj).then(() => {
      this.toastService.showSuccess(this.translateService.instant('profilePage.message.updateSuccess'));
      this.userService.setDefaultCurrencyToLocal(formValue.currency);
      if (this.profileImage) {
        this.userService.setProfileImageToLocal(this.profileImage);
        this.dataSharingService.profileImageUpdate.next(true);
      }
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('profilePage.message.updateFail'));
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.updateProfileForm.controls[controlName].hasError(errorName);
  }

  initProfileData() {
    this.profileService.getProfile(this.userService.getProfileIdFromLocal()).then(result => {
      this.profile = result;
      this.fillFormData(result);
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('profilePage.message.getFail'));
    });
  }

  initFormGroup() {
    this.updateProfileForm = this.formBuilder.group({
      name: ['', Validators.required],
      currency: [[], Validators.required],
      language: [[], Validators.required]
    }, {
    });
  }

  public getProfilePicture(base64Image) {
    this.profileImage = base64Image;
  }

  fillFormData(profile: any) {
    const defaultLanguage = localStorage.getItem('language');
    this.updateProfileForm = this.formBuilder.group({
      name: [profile.name, Validators.required],
      currency: [profile.currency, Validators.required],
      language: [defaultLanguage || 'en', Validators.required],
    }, {
    });
  }

  initCommonData() {
    this.commonService.getAccountEnumValues().then(result => this.listCurrency = result.listCurrency);
    this.commonService.getLanguages().then(languages => this.languages = languages);
  }

  deleteMyUserAccount() {
    const dialogRef1 = this.dialog.open(ConfirmPopupComponent, {
      width: '320px',
      data: {
        message: 'deleteUserAccount.confirmMessage1',
        ok: false,
      }
    });

    dialogRef1.afterClosed().subscribe(result1 => {
      if (result1.ok) {
        const dialogRef2 = this.dialog.open(ConfirmPopupComponent, {
          width: '320px',
          data: {
            message: 'deleteUserAccount.confirmMessage2',
            ok: false,
          }
        });

        dialogRef2.afterClosed().subscribe(result2 => {
          if (result2.ok) {
            console.log('start delete user account');
            this.userService.deleteMyUserAccount().then(result => {
              this.toastService.showSuccess(this.translateService.instant('deleteUserAccount.deleteSuccessfully'));
              this.signOut();
            }).catch(e => {
              this.toastService.showError(this.translateService.instant('deleteUserAccount.deleteUnsuccessfully'));
              console.log('delete fail');
            });
          }
        });
      }
    });
  }

  signOut() {
    this.authenService.logOut().then(() => {
      this.router.navigate(['/login']);
    });
  }
}
