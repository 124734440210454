import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { DataSharingService } from '../../shared/data-service-change';
import { MatSidenav } from '@angular/material/sidenav';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  @ViewChild('snav', { static: true }) snav: MatSidenav;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    media: MediaMatcher,
    private dataSharingService: DataSharingService,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.dataSharingService.showTourGuide.subscribe(async (helpTrigger) => {
      console.log('helpTrigger:', helpTrigger);
      await this.setSidebarStatus(true);
    });
    this.dataSharingService.sidebarStatus.subscribe(async (sidebarStatus: boolean) => {
      await this.setSidebarStatus(sidebarStatus);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  async setSidebarStatus(status: boolean) {
    if (this.snav) {
      if (status) {
        await this.snav.open();
      } else {
        await this.snav.close();
      }
    }
  }

  isOpenSideBar() {
    if (!this.mobileQuery.matches) {
      return false;
    } else {
      return localStorage.getItem('showingSideBar') === 'true' ? true : false;
    }
  }

  toggleClick() {
    console.log(this.snav.opened);
    localStorage.setItem('showingSideBar', this.snav.opened.toString());
  }

  homePage(): void {
    this.router.navigate(['/']);
  }
}
