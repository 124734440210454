import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OverlappingOptions } from './overlap-options-enum';
import { TransactionOverlappingModel } from './transaction-overlapping-model';

@Component({
  selector: 'app-transaction-label-modal',
  templateUrl: './transaction-overlapping-modal.component.html',
  styleUrls: ['./transaction-overlapping-modal.component.css']
})
export class TransactionOverlappingModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TransactionOverlappingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionOverlappingModel) {}
    overlappingOptionsList: any[];

  ngOnInit() {
    this.overlappingOptionsList = OverlappingOptions.LIST;
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
