import { Injectable } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { ConstantSetting } from '../shared/constant.config';

@Injectable()
export class UserService {
    constructor(private http: HttpService,
    ) {
    }

  getUserInfo(): Promise<any>  {
    return this.http.get(ConstantSetting.GET_USER);
  }

  updateLanguage(language: string) {
    return this.http.put(ConstantSetting.UPDATE_LANGUAGE, language);
  }

  getUserFromLocalStorage() {
    const userJson = JSON.parse(localStorage.getItem('user'));
    return userJson ? userJson.nameNick : '' ;
  }

  setDefaultCurrencyToLocal(currency) {
    localStorage.setItem('defaultCurrency', currency);
  }

  getProfileIdFromLocal() {
    const userJson = JSON.parse(localStorage.getItem('user'));
    return userJson ? userJson.defaultProfileId : '';
  }

  signOut(): Promise<any> {
    return this.http.post(ConstantSetting.SIGN_OUT);
  }

  createUser(userObj: any): Promise<any> {
    return this.http.postWithoutToken(ConstantSetting.CREATE_USER, userObj);
  }

  updateFirstTimeLogin() {
    return this.http.put(ConstantSetting.UPDATE_FIRST_TIME_LOGIN, null);
  }

  forgotPassword(email: string): Promise<any> {
    return this.http.postWithoutToken(ConstantSetting.FORGOT_PASSWORD, email);
  }

  resetPassword(resetPassObj: any): Promise<any> {
    return this.http.postWithoutToken(ConstantSetting.RESET_PASSWORD, resetPassObj);
  }

  setProfileImageToLocal(profileImage: string) {
    localStorage.setItem('profileImage', profileImage);
  }

  removeProfile() {
    localStorage.removeItem('profileImage');
    localStorage.removeItem('defaultProfileId');
    localStorage.removeItem('user');
    localStorage.removeItem('timeFormat');
    localStorage.removeItem('dateFormat');
    localStorage.removeItem('firstTimeLoginVisitedSteps');
  }

  deleteMyUserAccount() {
    return this.http.delete(ConstantSetting.DELETE_USER_ACCOUNT);
  }

  inviteNewUser(inviteUserObj: any) {
    return this.http.post(ConstantSetting.INVITE_NEW_USER, inviteUserObj);
  }
}
