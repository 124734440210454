import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastServices } from '../shared/toast.service';
import { TranslateService } from '../translate';
import { UserService } from '../service/user.service';
import { BasePage } from '../shared/BasePage';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.css']
})
export class InviteUsersComponent extends BasePage implements OnInit {

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private toastService: ToastServices,
              private translateService: TranslateService,
              private userService: UserService) {
    super();
  }

  @ViewChild('formDirective', {static: false}) private formDirective: NgForm;
  inviteUsersForm: FormGroup;
  listCurrency: [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '15rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Calibri',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ]
  };
  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.inviteUsersForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      message: [this.translateService.instant('inviteUsersPage.message.defaultMessage'), Validators.required]
    }, {
    });
  }

  resetForm() {
    this.inviteUsersForm.reset();
    this.formDirective.resetForm();
    this.initFormGroup();
  }

  async onSubmit() {
    if (this.inviteUsersForm.invalid) {
      return;
    }

    const formValue = this.inviteUsersForm.value;
    const inviteUsersObj = {
      invitedMail: formValue.email,
      message: formValue.message.replace(/<a\b[^>]*>(.*?)<\/a>/i, ''),
      emailObj: {
        subject: this.translateService.instant('inviteUsersPage.email.subject'),
        button: this.translateService.instant('inviteUsersPage.email.button'),
      }
    };
    try {
      await this.userService.inviteNewUser(inviteUsersObj);
      this.toastService.showSuccess(this.translateService.instant('inviteUsersPage.message.sendSuccess'));
      this.resetForm();
      await this.router.navigate([`/accounts`]);
    } catch (e) {
      console.log(e);
      this.toastService.showError(this.translateService.instant('inviteUsersPage.message.sendFail'));
    }
  }

  hasError = (controlName: string, errorName: string) => {
    return this.inviteUsersForm.controls[controlName].hasError(errorName);
  }

}
