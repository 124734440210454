import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { mustMatch } from '../../shared/helper/form.validator';
import { ToastServices } from '../../shared/toast.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
              private renderer: Renderer2,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastService: ToastServices,
              private userService: UserService) { }
  resetPassForm: FormGroup;
  hidePassword = true;
  hideConfirmPassword = true;
  userId: number;
  secret: string;
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.userId = +params.get('userId');
      this.secret = params.get('secret');
    });
    this.initFormGroup();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.resetPassForm.controls[controlName].hasError(errorName);
  }

  resetPassword() {
    if (this.resetPassForm.invalid) {
      return;
    }

    const formValue = this.resetPassForm.value;
    const resetPassObj = {
      userId: this.userId,
      secret: this.secret,
      password: formValue.password
    };
    this.userService.resetPassword(resetPassObj).then(() => {
      this.toastService.showSuccess('Reset password successfully');
      this.router.navigate(['/login']);
    }).catch(() => {
      this.toastService.showSuccess('Reset password unsuccessfully');
    });
  }

  private initFormGroup() {
    this.resetPassForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: mustMatch('password', 'confirmPassword')
    });
  }

  ngAfterViewInit() {
    // this.elementRef.nativeElement.ownerDocument.body.classList.add('login-body');
    this.renderer.addClass(document.body, 'login-body');
  }

  ngOnDestroy() {
    // remove the class form body tag
    this.renderer.removeClass(document.body, 'login-body');
  }

}
