import { Injectable } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { ConstantSetting } from '../shared/constant.config';

@Injectable()
export class ProfileService {
    constructor(private http: HttpService,
    ) {
    }

  getProfile(profileId): Promise<any>  {
    return this.http.get(ConstantSetting.GET_PROFILE + `/${profileId}`);
  }

  setProfile(profileObj): Promise<any> {
      return this.http.put(ConstantSetting.CREATE_PROFILE, profileObj);
  }

  setCurrency(profileId, currency): Promise<any>  {
    const url = ConstantSetting.UPDATE_CURRENCY.replace(/\{\w+\}/ig, profileId.toString());
    return this.http.put(`${url}?currency=${currency}`, null);
  }
}
