import { FormGroup, ValidationErrors } from '@angular/forms';

// custom validator to check that two fields match
export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function accountDetailMandatory(nature: string, iban: string, accountNumber: string) {
  return (formGroup: FormGroup) => {
    const natureControl = formGroup.controls[nature];
    const ibanControl = formGroup.controls[iban];
    const accountNumberControl = formGroup.controls[accountNumber];
    if (natureControl.value === 'CASH') {
      ibanControl.setErrors(null);
      accountNumberControl.setErrors(null);
      return;
    } else {
      if (ibanControl.value.length === 0 && accountNumberControl.value.length === 0) {
         ibanControl.setErrors({ accountDetailMandatory: true });
         accountNumberControl.setErrors({ accountDetailMandatory: true });
      } else {
        ibanControl.setErrors(null);
        accountNumberControl.setErrors(null);
        return;
      }
    }
  };
}

export function bothCheckboxMustBeTrue(agreeToServices: string, agreeToPolicy: string) {
  return (formGroup: FormGroup) => {
    const agreeToServicesControl = formGroup.controls[agreeToServices];
    const agreeToPolicyControl = formGroup.controls[agreeToPolicy];
    if (agreeToServicesControl.value && agreeToPolicyControl.value) {
      agreeToServicesControl.setErrors(null);
      agreeToPolicyControl.setErrors(null);
      return;
    } else {
      if (!agreeToServicesControl.value) {
        agreeToServicesControl.setErrors({ agreeToServicesMandatory: true });
      }
      if (!agreeToPolicyControl.value) {
        agreeToPolicyControl.setErrors({ agreeToPolicyMandatory: true });
      }

    }
  };
}

export function oneOfFieldIsManadatory(dependControl: string, ...controlNames: string[]) {
  return (formGroup: FormGroup) => {
    let isValid = false;
    for (const controlName of controlNames) {
      const control = formGroup.controls[controlName];
      if (control.value.length !== 0) {
        isValid = true;
      }
    }
    if (!isValid) {
      for (const controlName of controlNames) {
        const control = formGroup.controls[controlName];
        control.setErrors({ oneOfFieldIsManadatory: true });
      }
    } else {
      for (const controlName of controlNames) {
        const control = formGroup.controls[controlName];
        control.setErrors(null);
      }
    }
  };
}
