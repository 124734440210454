import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModel } from './confirm-model';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.css']
})

export class ConfirmPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmModel) { }
  ngOnInit() {
  }

  onNoClick(): void {
    this.data.ok = false;
    this.dialogRef.close(this.data);
  }

  ok(): void {
    this.data.ok = true;
    this.dialogRef.close(this.data);
  }

}
