import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from '../../service/transaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../service/common.service';
import { TranslateService } from '../../translate';
import { AccountService } from '../../service/account.service';
import { ToastServices } from '../../shared/toast.service';
import { DirectionFlag } from '../enums/direction-flag-enums';
import { TransactionEnumsModel } from '../add-transaction/transaction-enums.model';
import { BasePage } from '../../shared/BasePage';
import { ConfirmPopupComponent } from '../../shared/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.css']
})
export class TransactionDetailComponent extends BasePage implements OnInit {
  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private commonService: CommonService,
              private dialog: MatDialog,
              private translateService: TranslateService,
              private transactionService: TransactionService,
              private accountService: AccountService,
              private toastServices: ToastServices) {
    super();
  }
  updateTransactionForm;
  accountId = 0;
  account: any;
  transactionId: number;
  mode = '';
  transaction: any;
  transactionEnumList = new TransactionEnumsModel();
  labelCategoryList = [];
  defaultLabelCategoryList = [];
  selectedLabels = [];
  originalDate = '';
  isTransferTransaction = false;
  ngOnInit() {
    this.initFormGroup();
    this.initCommonData();
    this.getDefaultLabelCategory();
    this.activatedRoute.paramMap.subscribe(params => {
      this.accountId = +params.get('accountId');
      this.transactionId = +params.get('transactionId');
      this.mode = params.get('mode');
      this.getAccountInfo();
      if (this.mode === 'view') {
        this.updateTransactionForm.getRawValue();
      }
    });
  }

  initTransaction() {
    this.transactionService.getTransactionById(this.accountId, this.transactionId).then(transaction => {
      this.transaction = transaction;
      this.originalDate = this.transaction.dateOfTransaction;
      this.fillFormGroup(transaction);
      this.initLabelList();
    }).catch(() => {

      this.toastServices.showError(this.translateService.instant('transactionPage.message.getTransactionFail'));
    });
  }

  filterType(type: string, directionFlag) {
    if (directionFlag === DirectionFlag.INCOME.value || directionFlag === DirectionFlag.EXPENSE.value) {
      return type === directionFlag;
    } else {
      return type === DirectionFlag.TRANSFER.value;
    }
  }

  getDefaultLabelCategory() {
    this.commonService.getDefaultLabelCategory().then(result => {
      this.defaultLabelCategoryList = result;
      this.initTransaction();
    }).catch(() => {
      this.toastServices.showError(this.translateService.instant('transactionPage.message.getDefaultLabelCategory'));
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.updateTransactionForm.controls[controlName].hasError(errorName);
  }

  async onSubmit() {
    if (this.updateTransactionForm.invalid) {
      return;
    }
    const formValue = this.updateTransactionForm.value;
    const transObj = {
      id: this.transactionId ? this.transactionId : null,
      accountId: this.accountId,
      directionFlag: this.getDirectionFlagValue(formValue.directionFlag),
      selectedLabelsId: this.getSelectedLabelId(),
      namePartner: formValue.namePartner,
      amount: this.getAmountByDirection(formValue.directionFlag, this.convertToNumber(formValue.amount)),
      currency: this.account.currency,
      dateOfTransaction: this.getDateValue(formValue.dateOfTransaction),
      description: formValue.description,
      note: formValue.note,
      regularFlag: formValue.regularFlag ? formValue.regularFlag : null,
      ibanPartner: formValue.ibanPartner,
      accountBicPartner: formValue.accountBicPartner,
      accountNumberPartner: formValue.accountNumberPartner,
      dateOfBooking: formValue.dateOfBooking,
      currencyOrig: formValue.currencyOrig ? formValue.currencyOrig : null,
      amountOrig: formValue.amountOrig,
      cardNo: formValue.cardNo,
    };

    try {
      await this.transactionService.updateFiTransaction(this.accountId, transObj);
      await this.updateBalance(this.accountId, this.defaultProfileId, this.transaction.id, this.originalDate);
      await this.delay(1000);
      this.toastServices.showSuccess(this.translateService.instant('transactionPage.message.updateSuccess'));
      this.backToTransactionList();
    } catch (err) {
      console.log(err);
      this.toastServices.showError(this.translateService.instant('transactionPage.message.updateFail'));
    }

    // this.transactionService.updateFiTransaction(this.accountId, transObj).then(() => {
    //   this.updateBalance(this.accountId, this.transaction.id, this.originalDate);
    //   this.toastServices.showSuccess(this.translateService.instant('transactionPage.message.updateSuccess'));
    //   this.backToTransactionList();
    // }).catch((err) => {
    //   this.toastServices.showError(this.translateService.instant('transactionPage.message.updateFail'));
    // });
  }

  updateBalance(accountId, profileId, transactionId, startingDate) {
    this.accountService.recalculateBalance(accountId, profileId, transactionId, startingDate, 'TRANSACTION').catch((err => {
      if (err.error && err.error.errorMessage === 'INITIAL_BALANCE_IS_LATER') {
        this.toastServices.showWarning(this.translateService.instant('error.INITIAL_BALANCE_IS_LATER'));
      } else {
        this.toastServices.showError(this.translateService.instant('error.FAILED'));
      }
    }));
  }

  initFormGroup() {
    this.updateTransactionForm = this.formBuilder.group({
      accountId: this.accountId,
      directionFlag: ['EXPENSE', Validators.required],
      namePartner: [''],
      amount: ['', [Validators.required]],
      dateOfTransaction: [new Date(), Validators.required],
      description: [''],
      note: [''],
      regularFlag: [''],
      ibanPartner: [''],
      accountBicPartner: [''],
      accountNumberPartner: [''],
      dateOfBooking: [''],
      currencyOrig: [''],
      amountOrig: [''],
      cardNo: [''],
    }, {
    });
  }

  fillFormGroup(transaction: any) {
    this.updateTransactionForm = this.formBuilder.group({
      accountId: this.accountId,
      directionFlag: [this.setDirectionFlagValue(transaction.directionFlag, transaction.amount), Validators.required],
      namePartner: [transaction.namePartner],
      amount: [this.setAmount(transaction.directionFlag, this.convertToNumberString(transaction.amount)), [Validators.required]],
      dateOfTransaction: [transaction.dateOfTransaction, Validators.required],
      description: [transaction.description],
      note: [transaction.note],
      regularFlag: [transaction.regularFlag],
      ibanPartner: [transaction.ibanPartner],
      accountBicPartner: [transaction.accountBicPartner],
      accountNumberPartner: [transaction.accountNumberPartner],
      dateOfBooking: [transaction.dateOfBooking],
      currencyOrig: [transaction.currencyOrig],
      amountOrig: [transaction.amountOrig],
      cardNo: [transaction.cardNo],
    }, {
    });

    this.selectedLabels = [];
    for (const l of transaction.labels) {
      this.selectedLabels.push(l.label);
    }
  }

  getAccountInfo() {
    this.accountService.getAccount(this.accountId).then(account => {
      this.account = account;
    }).catch(() => {
      this.toastServices.showError(this.translateService.instant('transactionPage.message.getAccountFail'));
    });
  }

  initCommonData() {
    this.commonService.getTransactionEnumValues().then(result => {
      this.transactionEnumList = result;
      this.transactionEnumList.listDirectionFlag = DirectionFlag.LIST;
    });
  }

  changeDirection(event) {
    this.selectedLabels = [];
    this.labelCategoryList = [];
    if (event.value === 'OUT_GOING_TRANSFER' || event.value === 'INCOMING_TRANSFER') {
      event.value = 'TRANSFER';
      this.isTransferTransaction = true;
    } else {
      this.isTransferTransaction = false;
    }
    this.getLabelList(event.value, null);
  }

  initLabelList() {
    if (this.transaction.directionFlag === 'TRANSFER') {
      this.isTransferTransaction = true;
    } else {
      this.isTransferTransaction = false;
    }
    if (this.transaction.directionFlag) {
      this.getLabelList(this.transaction.directionFlag, this.transaction);
    }
  }

  getLabelList(direction: string, transaction: any) {
    this.labelCategoryList = [];
    const categoryList = this.defaultLabelCategoryList.filter( a => {
      return this.filterType(a.directionFlag, direction);
    });
    this.transactionService.getLabelByDirectionFlag(direction).then(result => {
      for (const category of categoryList) {
        for (const i in result) {
          let selectedItem = {};
          if (transaction) {
            for (const label of result[i]) {
              for (const temp of transaction.labels) {
                if (temp.label.id === label.id) {
                  selectedItem = label;
                  break;
                }
              }
            }
          }
          if (category.enumId === i) {
            this.labelCategoryList.push({
              categoryType: i,
              listLabelsOfCategory: result[i],
              selectedItem: selectedItem,
            });
          }
        }
      }
    }).catch(() => {
      this.toastServices.showError(this.translateService.instant('transactionPage.message.getLabelFail'));
    });

  }

  updateLabelSelected(selectedLabel: any) {
    const temp = [];
    let existed = false;
    for (const label of this.selectedLabels) {
      if (label.labelCategory.enumId.trim() === selectedLabel.label.labelCategory.enumId.trim()) {
        existed = true;
        // replace label
        // label = selectedLabel.label;
        temp.push(selectedLabel.label);
      } else {
        temp.push(label);
      }
    }
    if (!existed) {
      temp.push(selectedLabel.label);
    }

    this.selectedLabels = temp;
  }

  getSelectedLabelId() {
    const selectedList = [];
    for (const label of this.selectedLabels) {
      selectedList.push(label.id);
    }
    return selectedList;
  }

  getAmountByDirection(directionFlag: string, amount: number) {
    if (this.account && this.account.source === 'FILE') {
      return amount;
    }
    if (directionFlag === DirectionFlag.INCOME.value) {
      return amount;
    } else if (directionFlag === DirectionFlag.EXPENSE.value) {
      return -amount;
    } else if (directionFlag === DirectionFlag.INCOMING_TRANSFER.value) {
      return amount;
    } else if (directionFlag === DirectionFlag.OUT_GOING_TRANSFER.value) {
      return -amount;
    }
    return 0;
  }

  getDirectionFlagValue(directionFlag: string) {
    if (directionFlag === DirectionFlag.OUT_GOING_TRANSFER.value || directionFlag === DirectionFlag.INCOMING_TRANSFER.value) {
      return 'TRANSFER';
    } else {
      return directionFlag;
    }
  }

  setDirectionFlagValue(directionFlag: string, amount: number) {
    if (directionFlag === 'TRANSFER') {
      return (amount < 0 ? DirectionFlag.OUT_GOING_TRANSFER.value : DirectionFlag.INCOMING_TRANSFER.value);
    } else {
      return directionFlag;
    }
  }

  setAmount(directionFlag: string, amount: number) {
    if (this.account && this.account.source === 'FILE') {
      return amount;
    }
    if (directionFlag === 'EXPENSE' || directionFlag === 'TRANSFER') {
      return (amount < 0 ? -amount : amount);
    } else {
      return amount;
    }
  }

  resetForm() {
    this.initTransaction();
  }

  isReadOnly() {
    return this.mode === 'view';
  }

  backToTransactionList() {
    const month = this.dateToYYYYMM(new Date(this.transaction.dateOfTransaction));
    this.router.navigate([`/accounts/${this.accountId}/transactions/list/${month}`]);
  }

  removeTransaction() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '320px',
      data: {
        message: 'transactionPage.removeConfirmation.message',
        ok: false,
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.ok) {
        try {
          await this.transactionService.removeTransaction(this.accountId, this.transaction.id);
          await this.updateBalance(this.accountId, this.defaultProfileId, this.transaction.id, this.transaction.dateOfTransaction);
          this.toastServices.showSuccess(this.translateService.instant('transactionPage.removeTransaction.success'));
          await this.delay(1000);
          this.backToTransactionList();
        } catch (err) {
          console.log(err);
          this.toastServices.showError(this.translateService.instant('transactionPage.removeTransaction.fail'));
        }
      }
    });
  }

  disabledDateOfTrans() {
    return this.account && this.account.source === 'FILE';
  }

  public orderListInAlphabetical(listLabelsOfCategory) {
    return this.orderListInAlphabeticalWithSelectedLanguage(this.translateService, listLabelsOfCategory);
  }
}
