import { Component, OnInit } from '@angular/core';
import { AccountService } from '../service/account.service';
import { UserService } from '../service/user.service';
import { AccountModel } from './account.model';
import { BasePage } from '../shared/BasePage';
import { HELP_POPUP_STEP } from '../shared/data.utils';
import { DataSharingService } from '../shared/data-service-change';

@Component({
  selector: 'app-transactions',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent extends BasePage implements OnInit {

  constructor(private accountService: AccountService,
              private dataSharingService: DataSharingService,
              private userService: UserService) {
    super();
  }

  listAccountInfos: Array<AccountModel> = [];
  totalBalance = 0;

  ngOnInit() {
    this.initBankData();
    this.checkFirstTimeLogin(HELP_POPUP_STEP.OVERVIEW, this.dataSharingService);
  }

  initBankData() {
    let totalBalanceInEur = 0;
    this.accountService.getProfileListAccount(this.userService.getProfileIdFromLocal()).then(result => {
      this.listAccountInfos = result;
      this.accountService.getExchangeRates().then(rates => {
        for (const account of this.listAccountInfos) {
          totalBalanceInEur += account.currentBalance * this.getRate(rates, account.currency);
        }
        this.totalBalance = totalBalanceInEur / this.getRate(rates, this.defaultCurrency);
      });
    });
  }

}
