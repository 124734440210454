import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../../authentication/token.service';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { BasePage } from '../../../shared/BasePage';
import { DataSharingService } from '../../../shared/data-service-change';
// import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class AppHeaderComponent extends BasePage {
  username = '';
  constructor(private router: Router,
              private dataSharingService: DataSharingService,
              private tokenService: TokenService,
              private userService: UserService,
              private authenService: AuthenticationService) {
    super();
    this.dataSharingService.profileImageUpdate.subscribe( () => {
      this.profileImage = localStorage.getItem('profileImage');
    });
    this.username = this.userService.getUserFromLocalStorage();
  }

  signOut() {
    this.authenService.logOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  moveToPage(page: string) {
    this.router.navigate([`/${page}`]);
  }

  showingHelpPopup() {
    this.dataSharingService.showTourGuide.next(true);
  }
}
