import { AppSettings } from '../app.config';

export class ConstantSetting {

  // user api link
  private static USER_ROOT = 'user/';
  private static GET_USER_INFO_LINK = 'getUser';
  private static SIGN_OUT_LINK = 'logout';
  private static CREATE_USER_LINK = 'createUser';
  private static ACTIVATE_USER_LINK = 'activateUser';
  private static UPDATE_FIRST_TIME_LOGIN_LINK = 'updateFirstTimeLogin';
  private static FORGOT_PASSWORD_LINK = 'forgotPassword';
  private static RESET_PASSWORD_LINK = 'resetPassword';
  private static DELETE_USER_ACCOUNT_LINK = 'deleteUserAccount';
  private static INVITE_NEW_USER_LINK = 'inviteNewUser';
  private static UPDATE_LANGUAGE_LINK = 'updateLanguage';

  public static get GET_USER(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.GET_USER_INFO_LINK; }
  public static get SIGN_OUT(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.SIGN_OUT_LINK; }
  public static get CREATE_USER(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.CREATE_USER_LINK; }
  public static get ACTIVATE_USER(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.ACTIVATE_USER_LINK; }
  public static get UPDATE_FIRST_TIME_LOGIN(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.UPDATE_FIRST_TIME_LOGIN_LINK; }
  public static get FORGOT_PASSWORD(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.FORGOT_PASSWORD_LINK; }
  public static get RESET_PASSWORD(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.RESET_PASSWORD_LINK; }
  public static get DELETE_USER_ACCOUNT(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.DELETE_USER_ACCOUNT_LINK; }
  public static get INVITE_NEW_USER(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.INVITE_NEW_USER_LINK; }
  public static get UPDATE_LANGUAGE(): string { return AppSettings.API_ENDPOINT + this.USER_ROOT + this.UPDATE_LANGUAGE_LINK; }

  // common api link
  private static COMMON_ROOT = 'common/';
  private static GET_LANGUAGES_LINK = 'getLanguageList';
  private static GET_ACCOUNT_ENUM_VALUES_LINK = 'getAccountEnumValues';
  private static GET_TRANSACTION_ENUM_VALUES_LINK = 'getTransactionEnumValues';
  private static GET_LIST_DEFAULT_LABEL_CATEGORIES_LINK = 'getListDefaultLabelCategories';

  public static get GET_LANGUAGE(): string { return AppSettings.API_ENDPOINT + this.COMMON_ROOT + this.GET_LANGUAGES_LINK; }
  public static get GET_ACCOUNT_ENUM_VALUES(): string { return AppSettings.API_ENDPOINT + this.COMMON_ROOT + this.GET_ACCOUNT_ENUM_VALUES_LINK; }
  public static get GET_TRANSACTION_ENUM_VALUES(): string { return AppSettings.API_ENDPOINT + this.COMMON_ROOT + this.GET_TRANSACTION_ENUM_VALUES_LINK; }
  public static get GET_LIST_DEFAULT_LABEL_CATEGORIES(): string { return AppSettings.API_ENDPOINT + this.COMMON_ROOT + this.GET_LIST_DEFAULT_LABEL_CATEGORIES_LINK; }

  // account api link
  private static ACCOUNT_ROOT = ConstantSetting.USER_ROOT + 'accounts/';
  private static CREATE_ACCOUNT_LINK = 'createAccount';
  private static SET_ACCOUNT_LINK = 'setAccount';
  private static GET_ACCOUNT_OF_PROFILES_LIST_LINK = 'getAccountsOfProfileList';
  private static GET_EXCHANGE_RATE_LINK = 'getLastExchangeRates';
  private static GET_BALANCE_FOR_PERIODS_LINK = 'getBalancesForPeriods';
  private static RECALCULATE_BALANCE_LINK = 'recalculateBalance';
  private static GET_CASH_FLOW_DATA_LINK = 'getCashFlowData';

  public static get CREATE_ACCOUNT(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT + this.CREATE_ACCOUNT_LINK; }
  public static get GET_ACCOUNT(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT; }
  public static get SET_ACCOUNT(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT + this.SET_ACCOUNT_LINK; }
  public static get GET_ACCOUNT_OF_PROFILES_LIST(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT + this.GET_ACCOUNT_OF_PROFILES_LIST_LINK; }
  public static get GET_EXCHANGE_RATE(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT + this.GET_EXCHANGE_RATE_LINK; }
  public static get GET_BALANCE_FOR_PERIODS(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT + this.GET_BALANCE_FOR_PERIODS_LINK; }
  public static get RECALCULATE_BALANCE(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT + this.RECALCULATE_BALANCE_LINK; }
  public static get GET_CASH_FLOW_DATA(): string { return AppSettings.API_ENDPOINT + this.ACCOUNT_ROOT + this.GET_CASH_FLOW_DATA_LINK; }

  // profile api link
  private static PROFILE_ROOT = ConstantSetting.USER_ROOT + 'profile/';
  private static CREATE_PROFILE_LINK = '';
  private static UPDATE_CURRENCY_LINK = '{profileId}/updateCurrency';

  public static get GET_PROFILE(): string { return AppSettings.API_ENDPOINT + this.PROFILE_ROOT; }
  public static get CREATE_PROFILE(): string { return AppSettings.API_ENDPOINT + this.PROFILE_ROOT + this.CREATE_PROFILE_LINK; }
  public static get UPDATE_CURRENCY(): string { return AppSettings.API_ENDPOINT + this.PROFILE_ROOT + this.UPDATE_CURRENCY_LINK; }

  // transaction api link
  private static TRANSACTION_ROOT = ConstantSetting.USER_ROOT + 'accounts/{accountId}/transactions';
  private static TRANSACTIONS_LINK = ConstantSetting.USER_ROOT + 'transactions';
  private static UPDATE_TRANSACTION_LABEL_LINK = ConstantSetting.USER_ROOT + 'transactions/{transactionId}/updateTransactionLabel';
  public static get GET_TRANSACTIONS_OF_PROFILE_GROUP_BY_LABEL_ID(): string {return 'getTransactionsOfProfileGroupByLabelId'; }
  public static get TRANSACTIONS(): string { return AppSettings.API_ENDPOINT + this.TRANSACTIONS_LINK; }
  public static get GET_TRANSACTION_OF_ACCOUNT_LIST(): string { return AppSettings.API_ENDPOINT + this.TRANSACTION_ROOT; }
  public static get GET_TRANSACTION_BY_ID(): string { return AppSettings.API_ENDPOINT + this.TRANSACTION_ROOT; }
  public static get DELETE_TRANSACTION_BY_ID(): string { return AppSettings.API_ENDPOINT + this.TRANSACTION_ROOT; }
  public static get SAVE_TRANSACTION(): string { return AppSettings.API_ENDPOINT + this.TRANSACTION_ROOT; }
  public static get UPDATE_TRANSACTION_LABEL(): string { return AppSettings.API_ENDPOINT + this.UPDATE_TRANSACTION_LABEL_LINK; }

  // label api link
  private static LABEL_ROOT = ConstantSetting.USER_ROOT + 'labels/';
  private static GET_LABEL_OF_CATEGORY_LINK = 'getLabelsOfCategory/{labelCategoryId}';
  private static GET_LABEL_BY_DIRECTION_FLAG_LINK = 'getLabelByDirectionFlag/{directionFlag}';
  private static GET_LIST_LABEL_CATEGORIES_BY_PROFILE_ID_LINK = 'getListLabelCategoriesByProfileId/{profileId}';
  public static get GET_LABEL_OF_CATEGORY(): string { return AppSettings.API_ENDPOINT + this.LABEL_ROOT + this.GET_LABEL_OF_CATEGORY_LINK; }
  public static get GET_LABEL_BY_DIRECTION_FLAG(): string { return AppSettings.API_ENDPOINT + this.LABEL_ROOT + this.GET_LABEL_BY_DIRECTION_FLAG_LINK; }
  public static get GET_LIST_LABEL_CATEGORIES_BY_PROFILE_ID(): string { return AppSettings.API_ENDPOINT + this.LABEL_ROOT + this.GET_LIST_LABEL_CATEGORIES_BY_PROFILE_ID_LINK; }

  // csv-loader link
  private static CSV_LOADER_ROOT = ConstantSetting.USER_ROOT + 'csv-loader/';
  private static GET_DELIMITERS_LINK = 'delimiters';
  private static GET_COLUMNS_LINK = 'columns';
  private static GET_REQUIRED_COLUMNS_LINK = 'columns/required';
  private static PARSE_LINK = 'parse';
  private static SUGGEST_DATE_FORMAT_LINK = 'date-formats';
  private static SUGGEST_NUMBER_FORMAT_LINK = 'number-formats';
  private static STAGE_CSV_LINK = 'accounts/{accountId}/loaders/new/stage';
  private static STAGE_SAVE_LINK = 'accounts/{accountId}/transactions/stageSave';
  private static SAVE_ACCOUNT_UPLOAD_SETTING_LINK = 'accounts/{accountId}/accountUploadSetting';
  private static GET_ACCOUNT_UPLOAD_SETTING_LINK = 'accounts/{accountId}/getAccountUploadSetting';
  private static CSV_SAVE_TRANSACTIONS_LINK = 'accounts/{accountId}/csvSaveTransactions';
  private static CSV_CHECK_STAGE_TRANSACTIONS_LINK = 'accounts/{accountId}/csvCheckStageTransactions';
  public static get GET_DELIMITERS(): string { return AppSettings.API_ENDPOINT + this.CSV_LOADER_ROOT + this.GET_DELIMITERS_LINK; }
  public static get GET_COLUMNS(): string { return AppSettings.API_ENDPOINT + this.CSV_LOADER_ROOT + this.GET_COLUMNS_LINK; }
  public static get GET_REQUIRED_COLUMNS(): string { return AppSettings.API_ENDPOINT + this.CSV_LOADER_ROOT + this.GET_REQUIRED_COLUMNS_LINK; }
  public static get PARSE(): string { return AppSettings.API_ENDPOINT + this.CSV_LOADER_ROOT + this.PARSE_LINK; }
  public static get SUGGEST_DATE_FORMAT(): string { return AppSettings.API_ENDPOINT + this.CSV_LOADER_ROOT + this.SUGGEST_DATE_FORMAT_LINK; }
  public static get SUGGEST_NUMBER_FORMAT(): string { return AppSettings.API_ENDPOINT + this.CSV_LOADER_ROOT + this.SUGGEST_NUMBER_FORMAT_LINK; }
  public static get STAGE_CSV(): string {return AppSettings.API_ENDPOINT + this.USER_ROOT + this.STAGE_CSV_LINK; }
  public static get STAGE_SAVE(): string {return AppSettings.API_ENDPOINT + this.USER_ROOT + this.STAGE_SAVE_LINK; }
  public static get SAVE_ACCOUNT_UPLOAD_SETTING(): string {return AppSettings.API_ENDPOINT + this.USER_ROOT + this.SAVE_ACCOUNT_UPLOAD_SETTING_LINK; }
  public static get GET_ACCOUNT_UPLOAD_SETTING(): string {return AppSettings.API_ENDPOINT + this.USER_ROOT + this.GET_ACCOUNT_UPLOAD_SETTING_LINK; }
  public static get CSV_SAVE_TRANSACTIONS(): string {return AppSettings.API_ENDPOINT + this.USER_ROOT + this.CSV_SAVE_TRANSACTIONS_LINK; }
  public static get CSV_CHECK_STAGE_TRANSACTIONS(): string {return AppSettings.API_ENDPOINT + this.USER_ROOT + this.CSV_CHECK_STAGE_TRANSACTIONS_LINK; }
}
