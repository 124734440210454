import { User } from './user.model';

export class Token {
  constructor(public accessToken: string,
              public expiresIn: string,
              public refreshToken: string,
              public tokenType: string,
              public scope: string) {

  }
}
