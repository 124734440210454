import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { DescribedStringDto } from '../account-dto/described-string-dto';
import { ErrorDto } from '../account-dto/error-dto';
import { ColumnDto } from '../account-dto/column-dto';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { loadTextFile } from '../../shared/file.util';
import { CHARSET_MAPPING } from '../account-dto/character-set';
import { ActivatedRoute, Router } from '@angular/router';
import { CsvLoaderService } from '../../service/csv-loader.service';
import { MatPaginator } from '@angular/material/paginator';
import { ToastServices } from '../../shared/toast.service';
import { TranslateService } from '../../translate';
import { FiTransactionDto } from '../account-dto/fi-transaction-dto';
import { AccountService } from '../../service/account.service';
import { BasePage } from '../../shared/BasePage';

import * as moment from 'moment';
import { StringUtils } from '../../shared/String.config';
import { AccountUploadSettingDto } from '../account-dto/account-upload-setting-dto';
import { MatDialog } from '@angular/material/dialog';
import { TransactionOverlappingModalComponent } from './transaction-overlapping-modal/transaction-overlapping-modal.component';
import { OverlappingOptions } from './transaction-overlapping-modal/overlap-options-enum';

@Component({
  selector: 'app-account-upload-setting',
  templateUrl: './account-upload-setting.component.html',
  styleUrls: ['./account-upload-setting.component.css']
})
export class AccountUploadSettingComponent extends BasePage implements OnInit {

  constructor(private formBuilder: FormBuilder,
              private csvService: CsvLoaderService,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private translateService: TranslateService,
              private accountService: AccountService,
              private toastService: ToastServices,
              private router: Router, ) {
    super();
  }
  hidePreviewTable = true;
  tryParseSuccessfully = false;
  saveTransactionSuccessfully = false;
  public guessedCsvEncoding: string;
  public guessedCsvDelimiter: string;
  public guessedCsvQuote: string;
  fileSelectFormGroup: FormGroup;
  cancelUpload = false;
  isMissingRequiredColumns = [];
  selectedColumnsList = [];
  availableColumnList = [];
  tryParseFormGroup: FormGroup;
  displayedColumns: string[];
  displayedColumnsObj: any[];
  csvContent = '';
  accountName = '';
  accountId: number;
  account: any;
  public delimiters: DescribedStringDto[];
  public quoteChars: DescribedStringDto[];
  public escapeChars: DescribedStringDto[];
  public charsets: string[];
  public parsedContent: string[][];
  public parsingSuccessful: boolean = null;
  public parsingError: ErrorDto;

  public columns: ColumnDto[];
  public requiredColumns: string[];
  public mappingSuccessful: boolean = null;
  public mappingError: ErrorDto;
  public suggestedDateFormats: DescribedStringDto[];
  public suggestedNumberFormats: DescribedStringDto[];
  public accountUploadSetting: AccountUploadSettingDto;

  public stagedColumns: string[];
  public stagedData: FiTransactionDto[];

  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  color: ThemePalette = 'primary';
  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.accountId = +params.get('accountId');
      this.initStaticData();
      this.getAccountInfo();
    });
    this.initFormData();
  }

  initFormData() {
    this.fileSelectFormGroup = this.formBuilder.group({
      file: [undefined, [Validators.required]]
    });
    this.tryParseFormGroup = this.formBuilder.group({
      delimiterChar: [this.accountUploadSetting ? this.accountUploadSetting.delimiterChar.trim() : null, [Validators.required]],
      quoteChar: [this.accountUploadSetting ? this.accountUploadSetting.quoteChar.trim() : null, null],
      escapeChar: [null],
      numberOfHeaderLines: [this.accountUploadSetting ? this.accountUploadSetting.numberOfHeaderLines : 0, Validators.min(0)],
      charset: [this.accountUploadSetting ? this.accountUploadSetting.charset.trim() : null],
      columns: this.formBuilder.array([]),
      dateFormat: [this.accountUploadSetting ? this.accountUploadSetting.dateFormat.trim() : '', Validators.required],
      numberFormat: [this.accountUploadSetting ? this.accountUploadSetting.numberFormat.trim() : '', Validators.required],
      loaderName: [this.accountUploadSetting ? this.accountUploadSetting.loaderName : ''],
    });
  }

  getValue(attribute: string, data: string[], selectedCols: []) {
    for (const index in selectedCols) {
        if (selectedCols[index] === attribute) {
          return data[index];
        }
    }
    return null;
  }

  stage(): void {

    if (this.isMissingRequiredColumns.length > 0) {
      const plains = this.isMissingRequiredColumns.map(el => ' ' + this.translateService.instant('FIELDS.TRANSACTION.' + el));
      this.toastService.showError(this.translateService.instant('CSV-LOADER.SECOND-STEP.SELECT-REQUIRED-COLUMNS') + plains.toString());
      return;
    }

    const transactionList = new Array<any>();
    const selectedCols = this.tryParseFormGroup.value.columns;

    for (const [index, data] of this.parsedContent.entries()) {
      if (index >= this.tryParseFormGroup.value.numberOfHeaderLines) {

        const amountIn = StringUtils.convertToNumberWithRegex(this.getValue('amountIn', data, selectedCols), this.tryParseFormGroup.value.numberFormat);
        const amountOut = StringUtils.convertToNumberWithRegex(this.getValue('amountOut', data, selectedCols), this.tryParseFormGroup.value.numberFormat);
        let amount: any;
        if (selectedCols.includes('amountIn') && selectedCols.includes('amountOut')) {
          if (amountIn && amountIn !== 'invalid') {
            amount = amountIn;
          } else if (amountOut && amountOut !== 'invalid') {
            amount = - amountOut;
          }
        } else {
          amount = StringUtils.convertToNumberWithRegex(this.getValue('amount', data, selectedCols), this.tryParseFormGroup.value.numberFormat);
        }

        if (amount === 'invalid') {
          this.toastService.showError(this.translateService.instant('CSV-LOADER.SAVE.INVALID-AMOUNT'));
          return;
        }

        const transaction = {
          externalId : this.getValue('externalId', data, selectedCols),
          id: null,
          accountId: this.accountId,
          namePartner: this.getValue('namePartner', data, selectedCols),
          ibanPartner: this.getValue('ibanPartner', data, selectedCols),
          accountBicPartner: this.getValue('accountBicPartner', data, selectedCols),
          accountNumberPartner: this.getValue('accountNumberPartner', data, selectedCols),
          cardNo: this.getValue('cardNo', data, selectedCols),
          amount: amount,
          currency: this.account ? this.account.currency : this.defaultCurrency,
          directionFlag: this.getValue('directionFlag', data, selectedCols),
          amountOrig: this.getValue('amountOrig', data, selectedCols),
          currencyOrig: this.getValue('currencyOrig', data, selectedCols),
          dateOfTransaction: moment.utc(this.getValue('dateOfTransaction', data, selectedCols), this.tryParseFormGroup.value.dateFormat),
          dateOfBooking: moment.utc(this.getValue('dateOfBooking', data, selectedCols), this.tryParseFormGroup.value.dateFormat),
          typeOfTransaction: this.getValue('typeOfTransaction', data, selectedCols),
          description: this.getValue('description', data, selectedCols),
          note: this.getValue('note', data, selectedCols),
        };
        transactionList.push(transaction);
      }
    }

    const accountSetting = {
      columnMapping: this.tryParseFormGroup.get('columns').value.toString(),
      dateFormat: this.tryParseFormGroup.get('dateFormat').value.trim(),
      numberFormat: this.tryParseFormGroup.get('numberFormat').value.trim(),
      accountId: this.accountId,
      loaderName: this.account.accountName,
      delimiterChar: this.tryParseFormGroup.get('delimiterChar').value.trim(),
      quoteChar: this.tryParseFormGroup.get('quoteChar').value.trim(),
      numberOfHeaderLines: this.tryParseFormGroup.get('numberOfHeaderLines').value,
      charset: this.tryParseFormGroup.get('charset').value.trim(),
    };

    this.csvService.saveStage(this.accountId, transactionList).then(res => {
      this.csvService.saveAccountUploadSetting(this.accountId, accountSetting).then(() => {
        this.completeStep();
        this.stagedData = res;
      }).catch(() => {
        this.toastService.showError(this.translateService.instant('CSV-LOADER.SAVE.SAVE-SETTING-FAIL'));
      });
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('CSV-LOADER.SAVE.SAVE-STAGE-DATA-FAIL'));
    });

  }

  fillPreview(): void {
    loadTextFile(this.csvFile).subscribe(content => {
      this.csvContent = content.content;
      this.guessedCsvEncoding = content.encoding;
      this.guessedCsvDelimiter = this.detectDelimiterCharacter();
      this.guessedCsvQuote = this.detectQuoteCharacter();
      this.tryParseFormGroup.enable();
      this.tryParseFormGroup.patchValue({
        charset: CHARSET_MAPPING[this.guessedCsvEncoding]
      });
      if (this.guessedCsvDelimiter) {
        this.tryParseFormGroup.patchValue({
          delimiterChar: this.guessedCsvDelimiter
        });
      }
      if (this.guessedCsvQuote) {
        this.tryParseFormGroup.patchValue({
          quoteChar: this.guessedCsvQuote
        });
      }
      this.tryParse();
    });
  }

  get csvFile(): File {
    return this.fileSelectFormGroup.get('file').value.files[0];
  }

  completeStep(): void {
    this.stepper.selected.completed = true;
    // if (this.stepper.selected.stepControl) {
    //   this.stepper.selected.stepControl.disable();
    // }
    this.stepper.next();
  }

  completeOnly() {
    this.stepper.selected.completed = true;
  }

  isHeader(rowIndex) {
    return rowIndex < this.tryParseFormGroup.value.numberOfHeaderLines;
  }

  checkMissingRequiredColumns(selectedColumnList) {
    if (!this.requiredColumns) {
      this.isMissingRequiredColumns = [];
    }
    if (selectedColumnList.includes('amountIn') && selectedColumnList.includes('amountOut')) {
      selectedColumnList.push('amount');
    }
    this.isMissingRequiredColumns = this.requiredColumns
      .filter(req => selectedColumnList.indexOf(req) < 0 && ! (req === 'directionFlag' && this.useSign));
  }

  selectedColumns() {
    this.selectedColumnsList = [];
    const formValue = this.tryParseFormGroup.value;
    if (this.displayedColumns) {
      this.displayedColumns.forEach(i => {
        if (formValue.columns[i]) {
          this.selectedColumnsList.push(formValue.columns[i]);
        }
      });
    }
    this.checkMissingRequiredColumns(this.selectedColumnsList);
    this.showSelectedColumnList();
  }

  getDisplayedColumns() {
    const a = [];
    if (this.displayedColumnsObj) {
      for (const i of this.displayedColumnsObj) {
        if (!i.hidden) {
          a.push(i.value);
        }
      }
    }
    return a;
  }

  showSelectedColumnList() {
    for (const c of this.columns) {
      let existed = false;
      // if c not in this.selectedColumnsList
      for (const i of this.selectedColumnsList) {
        if (c.name === i) {
          existed = true;
          break;
        }
      }
      if (!existed || c.type === 'SKIP') {
        c.hide = false;
      } else {
        c.hide = true;
      }

    }
  }

  useSign(): boolean {
    return (this.tryParseFormGroup.get('directionFlags') as FormGroup).get('useSign').value;
  }

  initStaticData(): void {

    Promise.all([ this.csvService.getDelimiters(),
                        this.csvService.getColumns(),
                        this.csvService.getRequiredColumns(),
                        this.csvService.suggestDateFormats(),
                        this.csvService.suggestedNumberFormats(),
                        this.csvService.getAccountUploadSetting(this.accountId)])
      .then(responses => {
      [ this.delimiters,
        this.columns,
        this.requiredColumns,
        this.suggestedDateFormats,
        this.suggestedNumberFormats,
        this.accountUploadSetting
      ] = responses;
      this.quoteChars = [
        { value: '"', desc: 'DOUBLE-QUOTE' },
        { value: '\'', desc: 'SINGLE-QUOTE' },
      ];
      this.escapeChars = [
        { value: '\\', desc: 'BACKSLASH' },
        { value: '"', desc: 'DOUBLE-QUOTE' },
        { value: '\'', desc: 'SINGLE-QUOTE' },
      ];
      this.charsets = [
        'UTF-8',
        'UTF-16',
        'UTF-32',
        'US-ASCII',
        'ISO-8859-1',
      ];
      this.stagedColumns = this.columns.filter(c => c.type !== 'SKIP' && c.name !== 'amountIn' && c.name !== 'amountOut').map(c => c.name);
      this.isMissingRequiredColumns = this.requiredColumns;
      this.availableColumnList = this.columns;
      for (const c of this.columns) {
        c.hide = false;
      }
      this.initFormData();
    });
  }

  tryParse(): void {
    this.csvService
      .parse(this.csvFile, this.tryParseFormGroup.value)
      .then(data => {
          // Clearing the form array in preparation to the push below.
        while ((this.tryParseFormGroup.get('columns') as FormArray).length > 0) {
          (this.tryParseFormGroup.get('columns') as FormArray).removeAt(0);
        }
        this.parsedContent = data;
        this.displayedColumns = [...Array(data[0].length).keys()].map(i =>  i.toString());

        const columnListWithNoEmpty =  this.filterEmptyColumn();
        this.displayedColumnsObj = [];
        for (const c of this.displayedColumns) {
          this.displayedColumnsObj.push({
            hidden: columnListWithNoEmpty.includes(c) ? false : true,
            value: c
          });
        }

        this.parsingSuccessful = true;
        this.displayedColumns.forEach(i => {
          this.tryParseFormGroup.addControl(i, this.formBuilder.control(null));
        });
        this.displayedColumns.forEach(
          (i) => (this.tryParseFormGroup.get('columns') as FormArray).push(this.formBuilder.control(undefined)));
        if (this.accountUploadSetting) {
          const preSetColumns = this.accountUploadSetting.columnMapping.split(',');
          if (this.tryParseFormGroup.get('columns').value.length === preSetColumns.length) {
            this.tryParseFormGroup.get('columns').setValue(preSetColumns);
            this.checkMissingRequiredColumns(preSetColumns);
          } else {
            this.checkMissingRequiredColumns([]);
          }
        } else {
          this.checkMissingRequiredColumns([]);
        }
        this.tryParseSuccessfully = true;

        // skip step 2 if config already added and no missing required columns
        if (this.accountUploadSetting && this.isMissingRequiredColumns.length === 0) {
          this.completeOnly();
          this.stage();
        }

      }).catch(err => {
        console.log(err);
    });
    this.hidePreviewTable = false;
  }

  changeNumberOfHeadLines() {
    const columnListWithNoEmpty =  this.filterEmptyColumn();
    this.displayedColumnsObj = [];
    for (const c of this.displayedColumns) {
      this.displayedColumnsObj.push({
        hidden: columnListWithNoEmpty.includes(c) ? false : true,
        value: c
      });
    }
    console.log(this.displayedColumnsObj);
  }

  filterEmptyColumn() {
    const numberOfHeaderLines = this.tryParseFormGroup.value.numberOfHeaderLines;
    const result = [];
    for (let i = 0 ; i < this.parsedContent.length; i++) {
      let count = 0;
      let isEmpty = true;
      for (const a of this.parsedContent) {
        if (count >= numberOfHeaderLines) {
          if (a[i] !== '' && a[i] !== null) {
            isEmpty = false;
            break;
          }
        }
        count ++;
      }
      if (!isEmpty) {
        result.push(this.displayedColumns[i]);
      }
    }
    return result;
  }

  editFileSelectionStep(): void {
    this.fileSelectFormGroup.enable();
    this.resetParsingStep();
  }

  onStepChange(event: StepperSelectionEvent): void {
    if (event.selectedIndex < event.previouslySelectedIndex && event.selectedStep.completed) {
    } else {
    }
  }

  resetParsingStep(): void {
    this.tryParseFormGroup.reset();
    this.tryParseFormGroup.enable();
    this.parsedContent = null;
    this.displayedColumns = null;
    this.parsingSuccessful = null;
    this.parsingError = null;
    this.resetMappingStep();
  }

  resetMappingStep(): void {
    this.tryParseFormGroup.reset();
    this.tryParseFormGroup.enable();
    this.columns = null;
    this.mappingSuccessful = null;
    this.mappingError = null;
    this.suggestedDateFormats = null;
    this.suggestedNumberFormats = null;
    this.resetStagingStep();
  }

  resetStagingStep(): void {
    this.stagedColumns = null;
    this.stagedData = null;
  }

  detectQuoteCharacter() {
    for (const quote of this.quoteChars) {
      const count = (this.csvContent.match(new RegExp(quote.value, 'g')) || []).length;
      console.log(count);
      if (count > 3) {
        return quote.value;
      }
    }
    return '"';
  }

  backToAccountList() {
    this.router.navigate([`/accounts`]);
  }

  detectDelimiterCharacter() {
    for (const delimiter of this.delimiters) {
      const detectDelimiter = delimiter.value === 't' ? '\t' : delimiter.value;
      const count = (this.csvContent.match(new RegExp(detectDelimiter, 'g')) || []).length;
      if (count > 5) {
        return delimiter.value;
      }
    }
    return null;
  }

  getAccountInfo() {
    this.accountService.getAccount(this.accountId).then(res => {
      this.account = res;
      this.accountName = res.accountName;
    }).catch( () => {
      this.toastService.showError(this.translateService.instant('transactionPage.message.getAccountFail'));
    });
  }

  saveTransaction() {
    this.csvService.checkStageTransactions(this.accountId, this.stagedData).then(existed => {
      if (existed === true) {
        const dialogRef = this.dialog.open(TransactionOverlappingModalComponent, {
          width: '680px',
          data: {
            selectedOption: null,
          }
        });
        dialogRef.afterClosed().subscribe(selectedOption => {
          if (selectedOption) {
            this.saveTransToServer(selectedOption);
          }
        });
      } else {
        this.saveTransToServer(OverlappingOptions.NOT_OVERLAPPING.value);
      }
    });
  }

  saveTransToServer(selectedOption: string) {
    const saveTransactionObj = {
      listTransaction: [...this.stagedData],
      overlappingAction: selectedOption,
      profileId: this.defaultProfileId
    };

    saveTransactionObj.listTransaction.forEach(item => delete item.id);

    this.csvService.saveTransaction(this.accountId, saveTransactionObj).then((monthView) => {
      if (selectedOption === OverlappingOptions.CANCEL.value) {
        this.cancelUpload = true;
      } else {
        this.cancelUpload = false;
        this.toastService.showSuccess(this.translateService.instant('CSV-LOADER.SAVE.RESULT.SUCCESS'));
        this.router.navigate([`/accounts/${this.accountId}/transactions/list/${monthView}`]);
      }
      this.saveTransactionSuccessfully = true;
      this.completeStep();
    }).catch((err) => {
      console.log(err);
      this.toastService.showError(this.translateService.instant('CSV-LOADER.SAVE.RESULT.FAIL'));
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.tryParseFormGroup.controls[controlName].hasError(errorName);
  }

}

function setRequired(control: FormControl, isRequired: boolean): void {
  if (isRequired) {
    control.enable();
    control.setValidators(Validators.required);
  } else {
    control.reset();
    control.disable();
    control.clearValidators();
  }

}
