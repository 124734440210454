import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastServices {
  constructor(private toastrService: ToastrService) {
  }

  public static toastrConfig = {
    timeOut: 10000,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    maxOpened: 2,
    autoDismiss: false,
    closeButton: true,
    newestOnTop: true,
    iconClasses: {
      error: 'toast-error',
      info: 'toast-info',
      success: 'toast-success',
      warning: 'toast-warning'
    }
  };

  showSuccess(message) {
    this.toastrService.success(message);
  }

  showWarning(message) {
    this.toastrService.warning(message);
  }

  showError(message) {
    this.toastrService.error(message);
  }

  showInfo(message) {
    this.toastrService.info(message);
  }

}
