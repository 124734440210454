export class DirectionFlag {
  public static INCOME = {
    name : 'Income',
    value: 'INCOME'
  };
  public static EXPENSE = {
    name : 'Expense',
    value: 'EXPENSE'
  };
  public static OUT_GOING_TRANSFER = {
    name : 'Outgoing transfer',
    value: 'OUT_GOING_TRANSFER'
  };
  public static INCOMING_TRANSFER = {
    name : 'Incoming transfer',
    value: 'INCOMING_TRANSFER'
  };
  public static TRANSFER = {
    name : 'Transfer',
    value: 'TRANSFER'
  };

  public static get LIST(): Array<any> {
    return [
      this.INCOME, this.EXPENSE, this.OUT_GOING_TRANSFER, this.INCOMING_TRANSFER
    ];
  }
}
