export const CHARSET_MAPPING = {
  UTF32: 'UTF-32',
  UTF16: 'UTF-16',
  UTF16BE: 'UTF-16BE',
  UTF16LE: 'UTF-16LE',
  BINARY: null,
  ASCII: 'US-ASCII',
  JIS: null,
  UTF8: 'UTF-8',
  EUCJP: null,
  SJIS: null,
  UNICODE: 'ISO-8859-1',
};
