import { Component, OnInit } from '@angular/core';
import { AccountModel } from '../../accounts/account.model';
import { AccountService } from '../../service/account.service';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { DataSharingService } from '../../shared/data-service-change';
import { HELP_POPUP_STEP } from '../../shared/data.utils';
import { BasePage } from '../../shared/BasePage';

@Component({
  selector: 'app-user-account-list',
  templateUrl: './user-account-list.component.html',
  styleUrls: ['./user-account-list.component.css']
})
export class UserAccountListComponent extends BasePage implements OnInit {

  public listAccountInfos: AccountModel[];
  constructor(private accountService: AccountService,
              private userService: UserService,
              private dataSharingService: DataSharingService,
              private router: Router) {
    super();
  }

  displayedColumns: string[] = ['accountName', 'nature', 'currency'];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.listAccountInfos.filter = filterValue.trim().toLowerCase();
  }

  async ngOnInit() {
    this.accountService.getAccountList().then(result => {
      this.listAccountInfos = result;
      this.accountService.getExchangeRates().then(rates => {
        for (const account of this.listAccountInfos) {
        }
      });
    });
    await this.checkFirstTimeLogin(HELP_POPUP_STEP.ACCOUNTS, this.dataSharingService);
  }

  editAccount(id) {
    this.router.navigate([`/accounts/${id}/edit`]);
  }

  moveToNewAccount() {
    this.router.navigate([`/create-account`]);
  }

  backToAccountList() {
    this.router.navigate([`/accounts`]);
  }
}
