import { Injectable } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { ConstantSetting } from '../shared/constant.config';

@Injectable()
export class AccountService {
    constructor(private http: HttpService,
    ) {
    }

  createAccount(accountObj: any): Promise<any> {
    return this.http.post(ConstantSetting.CREATE_ACCOUNT, accountObj);
  }

  recalculateBalance(accountId: any, profileId: any, transactionId: any, startDate: string, type: string): Promise<any> {
    let url = ConstantSetting.RECALCULATE_BALANCE + `/${accountId}?startingDate=${startDate}&type=${type}&profileId=${profileId}`;
    if (transactionId) {
      url += `&transactionId=${transactionId}`;
    }
    return this.http.put(url, null);
  }

  getAccount(accountId: number): Promise<any> {
    return this.http.get(ConstantSetting.GET_ACCOUNT + `${accountId}`);
  }

  getAccountList(): Promise<any> {
    return this.http.get(ConstantSetting.GET_ACCOUNT);
  }

  setAccount(accountObj: any): Promise<any> {
      return this.http.post(ConstantSetting.SET_ACCOUNT, accountObj);
    }

  getProfileListAccount(profileId: string): Promise<any> {
      return this.http.get(ConstantSetting.GET_ACCOUNT_OF_PROFILES_LIST + `/${profileId}`);
  }

  getExchangeRates(): Promise<any> {
    return this.http.get(ConstantSetting.GET_EXCHANGE_RATE);
  }

  getBalanceForPeriods(accountId: number, month: string): Promise<any>  {
    return this.http.get(ConstantSetting.GET_BALANCE_FOR_PERIODS + `/${accountId}/${month}`);
  }

  removeAccount(accountId: number): Promise<any> {
    return this.http.delete(ConstantSetting.GET_ACCOUNT + `${accountId}`);
  }

  getCashFlowData(profileId: string, accountId: number, from: string, to: string): Promise<any> {
      if (accountId) {
        return this.http.get(ConstantSetting.GET_CASH_FLOW_DATA + `/${profileId}?accountId=${accountId}&from=${from}&to=${to}`);
      } else {
        return this.http.get(ConstantSetting.GET_CASH_FLOW_DATA + `/${profileId}?&from=${from}&to=${to}`);
      }
  }
}
