import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransactionLabelModel } from './transaction-label-model';
import { TransactionService } from '../../service/transaction.service';
import { ToastServices } from '../../shared/toast.service';
import { TranslateService } from '../../translate';

@Component({
  selector: 'app-transaction-label-modal',
  templateUrl: './transaction-label-modal.component.html',
  styleUrls: ['./transaction-label-modal.component.css']
})
export class TransactionLabelModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TransactionLabelModalComponent>,
    private transactionService: TransactionService,
    private translateService: TranslateService,
    private toastServices: ToastServices,
    @Inject(MAT_DIALOG_DATA) public data: TransactionLabelModel) {}

  readOnly = false;

  saveData() {
    this.transactionService.updateTransactionLabel(this.data.transactionId, this.data.selectedLabel.id, this.data.originalLabelId)
      .then(() => {
        this.toastServices.showSuccess(this.translateService.instant('transactionPage.message.updateSuccess'));
        this.closeDialog({
          updateResult: true,
          selectedLabel: this.data.selectedLabel,
          originalLabelId: this.data.originalLabelId,
        });
      }).catch(() => {
        this.toastServices.showError(this.translateService.instant('transactionPage.message.updateFail'));
    });
  }

  ngOnInit() {
    if (this.data && this.data.selectedLabel && this.data.selectedLabel.labelCategory && this.data.selectedLabel.labelCategory.directionFlag === 'TRANSFER') {
      this.readOnly = true;
    }
  }

  closeDialog(result: any) {
    this.dialogRef.close(result);
  }

}
