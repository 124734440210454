export const LANG_HU_TRANS = {
  // global
  'button.save': 'Mentés',
  'button.cancel': 'Mégsem',
  'button.ok': 'OK',
  'button.close': 'Bezárás',
  'button.back': 'Vissza',
  'button.next': 'Következő',
  'button.saveAndFinish': 'Mentés és befejezés',
  'button.remove': 'Törlés',
  'button.yes': 'Igen',
  'button.no': 'Nem',
  'button.edit': 'Szerkesztés',
  'button.detail': 'Részletek',
  'button.correctTheSetting': 'Javítsa ki a beállítást',
  'generic.select.other': 'Egyéb...',
  'session.isExpired': 'A munkamenet véget ért, jelentkezzen be újra!',
  'button.select': 'Választ',
  'button.sendInvitation': 'Meghívó küldés',

  // Login page
  'loginPage.title' : 'Continuity - bejelentkezés',
  'loginPage.email' : 'E-mail',
  'loginPage.password' : 'Jelszó',
  'loginPage.remember-me' : 'Emlékezz rám',
  'loginPage.signIn' : 'Bejelentkezés',
  'loginPage.forgotPassword' : 'Elfelejtette a jelszavát?',
  'loginPage.newToContinuity' : 'Első alkalommal használja a Continuity-t?',
  'loginPage.createAccount' : 'Regisztráció',
  'loginPage.or' : 'vagy',
  'loginPage.signInWithFacebook' : 'Bejelentkezés Facebook profillal',
  'loginPage.signInWithGoogle' : 'Bejelentkezés Google fiókkal',
  'loginPage.message.invalidEmailPassword': 'Érvénytelen email cím vagy jelszó',
  'loginPage.message.loginFail': 'Sikertelen bejelentkezés',
  'loginPage.message.emptyField': 'Az e-mail cím és a jelszó nem lehet kitöltetlen',

  // Register page
  'registerPage.title': 'Hozzon létre egy fiókot',
  'registerPage.nickName': 'Felhasználónév',
  'registerPage.email': 'E-mail',
  'registerPage.password': 'Jelszó',
  'registerPage.confirmPassword': 'Jelszó megerősítése',
  'registerPage.language': 'Nyelv',
  'registerPage.signUp': 'Regisztráció',
  'registerPage.nickNameRequired': 'Felhasználónév megadása kötelező',
  'registerPage.emailRequired': 'E-mail cím megadása kötelező',
  'registerPage.emailInvalid': 'Érvénytelen e-mail cím',
  'registerPage.passwordRequired': 'A jelszó kitöltése kötelező',
  'registerPage.passwordInvalid': 'A jelszónak legalább 6 karakter hosszúnak kell lennie',
  'registerPage.confirmPasswordRequired': 'A jelszó megerősítése kötelező',
  'registerPage.confirmPasswordNotMatched': 'A megadott két jelszó eltérő',
  'registerPage.languageRequired': 'A nyelv kiválasztása kötelező',
  'registerPage.message.success': 'Regisztrációja megtörtént. Kérjük ellenőrizze a postafiókját, és a kapott e-mail-el erősítse meg regisztrációját!',
  'registerPage.message.fail': 'Sikertelen regisztráció.',
  'registerPage.agreed.fail': 'A Szolgáltatási feltételek és az Adatvédelmi nyilatkozat elfogadása után használhatod a Continuity szolgáltatásait.',
  'registerPage.readMessage': 'Elolvastam és elfogadom',
  'registerPage.termOfService': 'a Szolgáltatási feltételeket',
  'registerPage.privacyPolicy': 'az Adatvédelmi nyilatkozatot',

  // active user page
  'activeAccountPage.verifySuccess': 'Regisztrációjának megerősítése sikerült',
  'activeAccountPage.verifyFail': 'A regisztrációjának megerősítése nem sikerült',

  // Accounts page
  'accountsPage.header.currentBalance': 'Aktuális egyenleg',
  'accountsPage.header.totalBalance': 'Összesített egyenleg',
  'accountsPage.generalInformation' : 'Általános információk',
  'accountsPage.giroNumber' : 'Bankszámlaszám',
  'accountsPage.swift' : 'SWIFT/BIC kód',
  'accountsPage.lastUpdate': 'Utoljára frissítve',
  'accountsPage.addTransaction': 'Tranzakció hozzáadása',
  'accountsPage.uploadTransaction': 'Tranzakciók feltöltése',
  'accountsPage.updateAt': 'Módosítva',
  'accountsPage.name': 'Név',
  'accountsPage.nameRequired': 'A név megadása kötelező',
  'accountsPage.nature': 'Válaszd ki a számlatípusát!',
  'accountsPage.natureRequired': 'A számla típusának kiválasztása kötelező',
  'accountsPage.source': 'Az adatbevitel módja',
  'accountsPage.sourceRequired': 'Az adatbevitel módját kötelező kiválasztani',
  'accountsPage.currency': 'Pénznem',
  'accountsPage.currencyRequired': 'A pénznem kiválasztása kötelező',
  'accountsPage.initialBalance': 'Nyitó egyenleg',
  'accountsPage.initialDate': 'Válassza ki a nyitó egyenleg dátumát',
  'accountsPage.accountDetail': 'Részletes számla adatok',
  'accountsPage.iban': 'IBAN (nemzetközi bankszámlaszám)',
  'accountsPage.accountNumber': 'Bankszámlaszám',
  'accountsPage.bic': 'BIC kód',
  'accountsPage.ibanOrAccountNumberRequired': 'Az IBAN és a bankszámlaszám közül legalább az egyik megadása kötelező',
  'accountPage.message.updateSuccess' : 'A számla adatok módosítása sikeres',
  'accountPage.message.updateError' : 'A számla adatok módosítása sikertelen',
  'accountPage.message.createSuccess' : 'A számla hozzáadása sikeres',
  'accountPage.message.createError' : 'A számla hozzáadása sikertelen',
  'accountPage.nature.ACCOUNT': 'Bankszámla',
  'accountPage.nature.CASH': 'Készpénz',
  'accountPage.nature.CREDIT': 'Hitel',
  'accountPage.nature.CREDIT_CARD': 'Hitelkártya',
  'accountPage.nature.BUILDING_SOCIETY': 'Lakáskassza',
  'accountPage.nature.INVESTMENT': 'Befektetés',
  'accountPage.nature.ASSET': 'Eszköz',
  'accountPage.source.MANUAL': 'Kézi bevitel',
  'accountPage.source.FILE': 'Fájl',
  'accountPage.source.TRANSFER': 'Transfer',
  'accountsPage.invalidPattern': 'Érvénytelen számformátum',
  'accountPage.remove.success' : 'A fiók törlése sikerült',
  'accountPage.remove.fail' : 'A fiók törlése nem sikerült',
  'accountPage.removeConfirmation.message' : 'Biztosan törli a fiókot?',
  'accountPage.ibanAccountNumber.waningMessage': 'Az IBAN vagy a bankszámlaszám megadása segíti az olyan folyamatok működését, mint pl. az automatikus cimkézés',
  'accountsPage.generalInformationNotice': 'Ezek a beállítások később nem változtathatók. Válasszon körültekintően!',
  'accountsPage.accountDetailNotice': 'Ezeket az adatokat később is megadhatja, de ha egyszer megadta, utána nem tud rajtuk változtatni.',

  // profile detail page
  'profilePage.name' : 'Név',
  'profilePage.nameRequired' : 'A név megadása kötelező',
  'profilePage.currency' : 'Pénznem',
  'profilePage.currencyRequired' : 'A pénznem megadása kötelező',
  'profilePage.message.getFail' : 'Nem sikerült betölteni a profil adatokat',
  'profilePage.message.updateSuccess' : 'A profil adatok módosítása sikeres',
  'profilePage.message.updateFail' : 'A profil adatok módosítása sikertelen',
  'profilePage.profileImage' : 'Profil kép',
  'profilePage.profileImageSelect' : 'Válassza ki a profil képét!',
  'profilePage.selectFileToUpload': 'Válassza ki a feltöltendő fájlt!',
  'profilePage.cropImage': 'Kép kivágása',
  'profilePage.preview': 'Megjelenés',

  // transaction page
  'transactionPage.note' : 'Megjegyzés',
  'transactionPage.comment' : 'Leírás',
  'transactionPage.selectLabelOfCategory': 'Válassza ki a cimkét!',
  'transactionPage.direction': 'Tranzakció iránya',
  'transactionPage.nameOfPartner': 'A partner neve',
  'transactionPage.amount': 'Összeg',
  'transactionPage.currency': 'Pénznem',
  'transactionPage.dateOfTransaction': 'Tranzakció dátuma',
  'transactionPage.regularFlag': 'Rendszeresség',
  'transactionPage.description': 'Leírás',
  'transactionPage.ibanOfPartner': 'Partner nemzetközi számlaszáma (IBAN)',
  'transactionPage.accountBicOfPartner': 'Partner bankjának BIC kódja',
  'transactionPage.accountNumberOfThePartner': 'Partner bankszámlaszáma',
  'transactionPage.cardNumber': 'Bankkártya száma',
  'transactionPage.originalAmount': 'Eredeti összeg',
  'transactionPage.originalCurrency': 'Eredeti pénznem',
  'transactionPage.dateOfBooking': 'Könyvelés dátuma',
  'currency.required': 'A pénznem megadása kötelező',
  'amount.required': 'Az összeg oszlopának kiválasztása kötelező',
  'amount.min': 'Negatív összeg nem megengedett',
  'nameOfPartner.required': 'A partner neve kötelező',
  'dateOfTransaction.required': 'A tranzakció dátumának kiválasztása kötelező',
  'transactionPage.generalInformation': 'Általános információ',
  'transactionPage.accountInformation': 'Számla információ',
  'transactionPage.message.createSuccess': 'Tranzakció rögzítése sikeres',
  'transactionPage.message.createFail': 'Tranzakció rögzítése sikertelen',
  'transactionPage.message.getAccountFail': 'Számla adatok betöltése sikertelen',
  'transactionPage.message.getLabelFail': 'Cimke adatok betöltése sikertelen',
  'transactionPage.message.getTransactionFail': 'Tranzakció adatok betöltése sikertelen',
  'transactionPage.message.updateSuccess': 'Tranzakció adatok módosítása sikeres',
  'transactionPage.message.updateFail': 'Tranzakció adatok módosítása sikertelen',
  'transactionPage.message.getBalanceFail': 'Egyenleg lekérdezés sikertelen',
  'transactionPage.message.getDefaultLabelCategory': 'Alapértelmezett kategóriák betöltése sikertelen',
  'transactionPage.message.getListOfTransactionFail': 'Tranzakciók betöltése sikertelen',
  'transactionPage.regularFlag.NO': 'Nem rendszeres',
  'transactionPage.regularFlag.WEEKLY': 'Hetente',
  'transactionPage.regularFlag.MONTHLY': 'Havonta',
  'transactionPage.regularFlag.EVERY_SECOND_MONTH': 'Kéthavonta',
  'transactionPage.regularFlag.QUARTERLY': 'Negyedévente',
  'transactionPage.regularFlag.EVERY_HALF_YEAR': 'Félévente',
  'transactionPage.regularFlag.YEARLY': 'Évente',
  'transactionPage.regularFlag.OTHER': 'Más gyakorisággal',
  'transactionPage.addNewTransaction': 'Adjon hozzá egy új tranzakciót',
  'transactionPage.updateTransaction': 'Frissítse a tranzakciót',
  'transactionPage.removeTransaction.success': 'A tranzakció törlése sikerült',
  'transactionPage.removeTransaction.fail': 'A tranzakció törlése nem sikerült',
  'transactionPage.removeConfirmation.message': 'Biztosan törölni szeretné a tranzakciót?',

  // UPLOAD-CSV SETTING PAGE
  'CSV-LOADER.FILE': 'Fájl',
  'CSV-LOADER.FIRST-STEP.TITLE': 'Új feltöltő beállításai',
  'CSV-LOADER.FIRST-STEP.SELECT-CSV': 'Válasszon egy CSV fájlt',
  'CSV-LOADER.FIRST-STEP.PDF': 'Csak PDF fájl',
  'CSV-LOADER.FIRST-STEP.PLEASE-SELECT': 'Fájl kiválasztása',
  'File.required': 'Válasszon ki egy fájlt!',
  'CSV-LOADER.SECOND-STEP.TITLE': 'Feltöltés beállítása',
  'CSV-LOADER.SECOND-STEP.PREVIEW': 'Konvertált nézet',
  'CSV-LOADER.SECOND-STEP.CONFIGURATION': 'Beállítások',
  'CSV-LOADER.SECOND-STEP.DELIMITER': 'Mező határoló karakter',
  'CSV-LOADER.SECOND-STEP.QUOTE-CHARACTER': 'Idézőjel típusa',
  'CSV-LOADER.SECOND-STEP.CHARACTER-SET': 'Karakter kódolás',
  'CSV-LOADER.SECOND-STEP.HEADER-ROW': 'Fejléc sorok száma',
  'numberOfHeaderLines.min.0': 'A fejléc sorainak száma nem lehet negatív',
  'CSV-LOADER.SECOND-STEP.TRY-PARSE': 'Próbálja meg értelmezni a fájlt',
  'CSV-LOADER.THIRD-STEP.TITLE': 'Adatok értelmezésének megtekintése',
  'CSV-LOADER.THIRD-STEP.SELECT_COLUMN_INSTRUCTIONS': 'Legalább a következő oszlopokat válassza ki:',
  'dateFormat.required': 'A dátum formátum kiválasztása kötelező',
  'numberFormat.required': 'A szám formátum kiválasztása kötelező',
  'CSV-LOADER.FOURTH-STEP.STAGE-DATA': 'Előzetes betöltés',
  'CSV-LOADER.SECOND-STEP.SELECT-REQUIRED-COLUMNS': 'Válassza ki a kötelező oszlopokat:',

  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SEMICOLON': 'Pontosvessző',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COMMA': 'Vessző',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COLON': 'Kettőspont',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.PIPE': 'Függőleges vonal',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.HASHMARK': 'Kettős kereszt',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SPACE': 'Szóköz',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.TAB': 'Tabulátor',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.NONE': 'Nincs',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.DOUBLE-QUOTE': 'Dupla idézőjel',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.SINGLE-QUOTE': 'Szimpla idézőjel',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.BACKSLASH' : 'Fordított \ jel',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.DOUBLE-QUOTE' : 'Dupla idézőjel',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.SINGLE-QUOTE' : 'Szimpla idézőjel',
  'FIELDS.TRANSACTION.IGNORE': 'Hagyja figyelmen kívül',
  'FIELDS.TRANSACTION.amount': 'Összeg',
  'FIELDS.TRANSACTION.currency': 'Pénznem',
  'FIELDS.TRANSACTION.dateOfTransaction': 'Tranzakció dátuma',
  'FIELDS.TRANSACTION.dateOfBooking': 'Könyvelés dátuma',
  'FIELDS.TRANSACTION.amountIn': 'Jóváírt összeg',
  'FIELDS.TRANSACTION.amountOut': 'Levonásra került összeg',
  'FIELDS.TRANSACTION.directionFlag': 'Tranzakció iránya',
  'FIELDS.TRANSACTION.namePartner': 'Partner neve',
  'FIELDS.TRANSACTION.note': 'Megjegyzés',
  'FIELDS.TRANSACTION.ibanPartner': 'Partner nemzetközi bankszámlaszáma (IBAN)',
  'FIELDS.TRANSACTION.accountBicPartner': 'Partner bankjának BIC kódja',
  'FIELDS.TRANSACTION.accountNumberPartner': 'Partner számlaszáma',
  'FIELDS.TRANSACTION.cardNo': 'Bankkártya száma',
  'FIELDS.TRANSACTION.amountOrig': 'Eredeti összeg',
  'FIELDS.TRANSACTION.currencyOrig': 'Eredeti pénznem',
  'FIELDS.TRANSACTION.typeOfTransaction': 'Tranzakció típusa',
  'FIELDS.TRANSACTION.description': 'Leírás',
  'FIELDS.TRANSACTION.externalId': 'Banki azonosító',
  'CSV-LOADER.MAP-COLUMNS.INCOME.LABEL' : 'Bevételek jele',
  'CSV-LOADER.MAP-COLUMNS.EXPENSE.LABEL' : 'Költségek jele',
  'CSV-LOADER.MAP-COLUMNS.USE-SIGN.LABEL': 'Használja az összeg előjelét',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.CUSTOM.LABEL': 'Egyéni formátum',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.LABEL': 'Kérjük válassza ki a dátum formátumot',
  'CSV-LOADER.MAP-COLUMNS.NUMBER-FORMAT.LABEL' : 'Kérjük válassza ki az összeg formátumát',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.SUGGEST-BUTTON': 'Javasoljon formátumot',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SEPARATOR.LABEL': 'Tizedes elválasztó',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SUGGEST-BUTTON': 'Javaslat tizedes elválasztóra',
  'CSV-LOADER.MAP-COLUMNS.ERROR.NO-DATE-COLUMN': 'Kérjük válassza ki a "Tranzakció dátuma" oszlopot',
  'CSV-LOADER.SAVE.TITLE': 'Az adatok feltöltése',
  'CSV-LOADER.SAVE.RESULT': 'Az eredmény feltöltése',
  'CSV-LOADER.SAVE.RESULT.SUCCESS': 'A tranzakciókat sikeresen feltöltötte.',
  'CSV-LOADER.SAVE.RESULT.FAIL': 'A tranzakciók feltöltése sikertelen.',
  'CSV-LOADER.SAVE.INVALID-AMOUNT': 'Érvénytelen összeg',
  'CSV-LOADER.SAVE.SAVE-SETTING-FAIL': 'A beállítások elmentése sikertelen',
  'CSV-LOADER.SAVE.SAVE-STAGE-DATA-FAIL': 'Az adatok feltöltése sikertelen',
  'CSV-LOADER.SAVE.CANCEL.SUCCESS': 'A tranzakciók feltöltése megszakítva',
  'CSV-LOADER.OVERLAPPING.MODAL.TITLE': 'Átfedő tranzakciók',
  'CSV-LOADER.OVERLAPPING.MODAL.SUBTITLE': 'Átfedés van a korábban feltöltött tranzakciók és a most feltölteni kívánt tranzakciók dátumai között.',
  'CSV-LOADER.OVERLAPPING.MODAL.SELECT': 'Válassza ki, hogy mit szeretne tenni:',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Append': 'Megtartom a már feltöltött tranzakciókat, és hozzáadom a mostani fájlból azt az időszakot, ami korábban nem szerepelt.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Substitute': 'Felülírom a korábban feltöltött tranzakciókat a mostani fájlban szereplőkkel.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Merge': 'A korábban feltöltött időszak tranzakcióit kiegészítem az ebben a fájlban lévőkkel.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Cancel': 'Megszakítom a feltöltést.',

  // first-time-login
  'firstTimeLoginPage.title' : 'Üdvözli a Continuity!',
  'firstTimeLoginPage.subTitle': 'Kérjük, adja meg első számlájának adatait!',

  // menu items
  'menu.item.Overview': 'Áttekintés',
  'menu.item.AccountList': 'Számláim',
  'menu.item.Profile': 'Profil',
  'menu.item.Analytics': 'Elemzés',
  'menu.item.signOut': 'Kijelentkezés',
  'menu.item.deleteMyAccount': 'Felhasználói fiók törlése',
  'menu.item.Cash-flow': 'Cash-flow',
  'menu.item.inviteNewUser': 'Meghívó küldése',
  'menu.item.help': 'Segítség',

  // enum icons
  'enum.name.ACCOMODATION' : 'Lakhatás',
  'enum.name.TRANSPORT' : 'Közlekedés',
  'enum.name.MEALS' : 'Étkezés',
  'enum.name.CLOTHING' : 'Ruházkodás',
  'enum.name.ALCOHOL_TOBACCO' : 'Alkohol & Dohány',
  'enum.name.BANK' : 'Bank',
  'enum.name.EDUCATION' : 'Képzés',
  'enum.name.ENTERTAINMENT' : 'Szórakozás',
  'enum.name.GIFT' : 'Ajándék',
  'enum.name.HOBBY' : 'Hobby',
  'enum.name.SPORT' : 'Sport',
  'enum.name.HOLIDAY' : 'Vakáció',
  'enum.name.HYGIENE_HOUSEHOLD' : 'Higénia & Háztartás',
  'enum.name.TELECOMMUNICATION' : 'Távközlés',
  'enum.name.HEALTH_CARE' : 'Egészségügy',
  'enum.name.OTHER' : 'Egyéb',
  'enum.name.SPOUSE' : 'Társ',
  'enum.name.MYSELF' : 'Én',
  'enum.name.MOM' : 'Anya',
  'enum.name.DAD' : 'Apa',
  'enum.name.FAMILY' : 'Család',
  'enum.name.FRIENDS' : 'Barátok',
  'enum.name.IN-LAWS' : 'Rokonság',
  'enum.name.CHILD1' : 'Gyermek 1',
  'enum.name.CHILD2' : 'Gyermek 2',
  'enum.name.CHILD3' : 'Gyermek 3',
  'enum.name.CHILD4' : 'Gyermek 4',
  'enum.name.CHEMICALS' : 'Vegyszerek',
  'enum.name.ENERGY' : 'Energia',
  'enum.name.EQUIPMENT' : 'Berendezés',
  'enum.name.FIXTURE' : 'Alkatrész',
  'enum.name.FOOD' : 'Étel',
  'enum.name.GADGET' : 'Kütyü',
  'enum.name.INSURANCE' : 'Biztosítás',
  'enum.name.MAINTENANCE' : 'Karbantartás',
  'enum.name.PENALTIES' : 'Büntetés',
  'enum.name.RENTAL' : 'Bérleti díj',
  'enum.name.TAX' : 'Adó',
  'enum.name.TICKET_SERVICE' : 'Szolgáltatás/Jegy',
  'enum.name.EMPLOYER' : 'Munkáltató',
  'enum.name.CLIENT' : 'Ügyfél',
  'enum.name.EARNING_FROM_INVESTMENT' : 'Befektetés hozama',
  'enum.name. RENTAL' : 'Bérleti díj',
  'enum.name.SALE_OF_ASSET' : 'Eszköz eladása',
  'enum.name.PET' : 'Háziállat',
  'enum.name.STATE' : 'Állam',
  'enum.name.TRANSFER' : 'Átvezetés számlák között',
  'enum.name.INCOME_LABELED_AS_TRANSFER' : 'Az átvezetés párja hiányzik',
  'enum.name.EXPENSE_LABELED_AS_TRANSFER' : 'Az átvezetés párja hiányzik',
  'enum.name.SUPPORT' : 'Segély/Támogatás',
  'enum.name.SALARY' : 'Fizetés',
  'enum.category.TRANSFER' : 'Átvezetés számlák között',
  'enum.category.TITLE': 'Jogcím',
  'enum.category.PAYING': 'Kifizető',
  'enum.category.PURPOSE': 'Cél',
  'enum.category.BENEFICIARY': 'Kedvezményezett',
  'enum.category.TOOL_SERVICE': 'Eszköz vagy szolgáltatás',
  'enum.category.THIS_MONTH': 'Aktuális hónap',
  'enum.category.LAST_MONTH': 'Múlt hónap',
  'enum.category.THIS_YEAR': 'Aktuális év',
  'enum.category.LAST_YEAR': 'Múlt év',
  'enum.category.THIS_WEEK': 'Aktuális hét',
  'enum.category.LAST_WEEK': 'Múlt hét',
  'enum.category.TODAY': 'Ma',
  'enum.category.CUSTOM': 'Egyéni beállítás',

  // direction flag
  'direction.flag.INCOME': 'Bevétel',
  'direction.flag.EXPENSE': 'Kiadás',
  'direction.flag.OUT_GOING_TRANSFER': 'Átvezetés másik számlára',
  'direction.flag.INCOMING_TRANSFER': 'Átvezetés másik számláról',

  // analytics page
  'analyticsPage.incomeChart': 'Bevételek',
  'analyticsPage.expenseChart': 'Kiadások',
  'analyticsPage.noIncomeData': 'Nincs bevétel a megadott időszakban',
  'analyticsPage.noExpenseData': 'Nincs kiadás a megadott időszakban',
  'analyticsPage.getLabelFail': 'A címke adatok lekérdezése sikertelen',
  'analyticsPage.getChartDataFail': 'A diagramhoz szükséges adatok lekérdezése sikertelen',
  'analyticsPage.totalAmount': 'Teljes összeg',
  'analyticsPage.selectIncomeCategory': 'Válasszon bevételi dimenziót',
  'analyticsPage.selectExpenseCategory': 'Válasszon költség dimenziót',
  'analyticsPage.selectTimePeriod': 'Kiválasztott időszak',
  'analyticsPage.from': 'Mikortól?',
  'analyticsPage.to': 'Meddig?',

  // cash-flow page
  'cash-flow.selectYourAccount': 'Válasszon számlát',
  'cash-flow.allAccountOfYourProfile': 'A profil minden számlája',
  'cash-flow.chartHeader': 'Cash-flow',
  'cash-flow.subtextOfAnAccount': '',
  'cash-flow.subtextOfAllAccount': 'Összesített',
  'cash-flow.warningMessage': 'Csak egy évre előre készít a Continuity cash-flow tervet.',

  // invite-users page
  'inviteUserPage.email': 'e-mail',
  'inviteUserPage.message': 'Üzenet',
  'inviteUserPage.emailRequired': 'Az e-mail cím megadása kötelező',
  'inviteUserPage.emailInvalid': 'A megadott e-mail cím érvénytelen',
  'inviteUserPage.messageRequired': 'Írjon üzenetet!',
  'inviteUsersPage.message.sendSuccess': 'A meghívást sikeresen elküldte',
  'inviteUsersPage.message.sendFail': 'A meghívás elküldése nem sikerült',
  'inviteUsersPage.message.defaultMessage': 'Szia! \nÉn a Continuity-val tartom kézben a pénzügyeimet és tervezem meg a cash-flow-mat. \nHasználd te is!',
  'inviteUsersPage.email.subject': 'meghívta Önt, hogy használja a Continuity-t.',
  'inviteUsersPage.email.button': 'Continuity regisztráció',

  // error message
  'error.INITIAL_BALANCE_IS_LATER' : 'A nyitó egyenleg dátumánál korábbi tranzakció lett rögzítve.',
  'error.FAILED' : 'A számlaegyenlegek számítása során hiba történt',

  // tool tip
  // add account page
  'toolTip.addAccount.name': 'Adjon egy nevet a számlájának, nem szükséges, hogy megegyezzen azzal a névvel, amit a pénzintézet adott neki. A lényeg, hogy Ön felismerje erről a névről.',
  'toolTip.addAccount.nature': 'Válassza ki a számla \"természetét\" - pl. pénztárca vagy hitelszámla...',
  'toolTip.addAccount.source': 'A banki adatokat rendszeresen fel tudja tölteni a netbankjából letöltött fájlból. A készpénzes vásárlásokat érdemes mielőbb felírni a Continuity mobil verziójában.',
  'toolTip.addAccount.currency': 'Ha több féle valutát is tart magánál, akkor azoknak valutánként vegyen fel külön pénztárcát!',
  'toolTip.addAccount.dateOfInitialBalance': 'Válasszon egy dátumot, amelynek ismeri a nap végi egyenlegét!',
  'toolTip.addAccount.initialBalance': 'Adja meg a dátumhoz tartozó nap végi egyenleget! Nem kötelező. Ha nem adja meg, akkor a Continuity nem fogja tudni kiszámolni számlája mindenkori egyenlegét.',
  'toolTip.addAccount.iban': 'Ország kóddal és két ellenőrző számjeggyel kezdődő nemzetközi bankszámlaszám. Ha megadja, akkor a Continuity könnyebben azonosítja a számlái közötti átvezetéseket. Nem kötelező.',
  'toolTip.addAccount.accountNumber': 'Belföldön használatos bankszámlaszám. 2-szer vagy 3-szor 8 számjegy. Ha megadja, akkor a Continuity könnyebben azonosítja a számlái közötti átvezetéseket. Nem kötelező.',
  'toolTip.addAccount.bic': 'A bankjának a nemzetközi azonosítója. Swift kódként is ismert. Ha megadja, akkor a Continuity könnyebben azonosítja a számlái közötti átvezetéseket. Nem kötelező.',
  'toolTip.addAccount.saveBtn': 'Mentés után még változtathat a számla adatain, de ha már tranzakciót is rögzített a számlához, akkor a név és az egyenleg kivételével az adatok többé nem változtathatók.',
  'toolTip.addAccount.resetBtn': 'Az eddig beírt adatok törlése.',

  // edit account page
  'toolTip.editAccount.name': 'A számlája nevét bármikor megváltoztathatja.',
  'toolTip.editAccount.nature': 'Ha már rögzített tranzakciót a számlán, akkor a számla típusa többé nem változtatható.',
  'toolTip.editAccount.source': 'Ha már rögzített tranzakciót a számlán, akkor a számla adatforrása többé nem változtatható.',
  'toolTip.editAccount.currency': 'Ha már rögzített tranzakciót a számlán, akkor a számla pénzneme többé nem változtatható.',
  'toolTip.editAccount.dateOfInitialBalance': 'Válasszon egy dátumot, amelynek ismeri a nap végi egyenlegét!',
  'toolTip.editAccount.initialBalance': 'Adja meg a dátumhoz tartozó nap végi egyenleget! Nem kötelező. Ha nem adja meg, akkor a Continuity nem fogja tudni kiszámolni számlája mindenkori egyenlegét.',
  'toolTip.editAccount.iban': 'Ha már rögzített tranzakciót a számlán, akkor az IBAN többé nem változtatható.',
  'toolTip.editAccount.accountNumber': 'Ha már rögzített tranzakciót a számlán, akkor a számlaszám többé nem változtatható.',
  'toolTip.editAccount.bic': 'Ha már rögzített tranzakciót a számlán, akkor a BIC kód többé nem változtatható.',
  'toolTip.editAccount.saveBtn': 'A számla adatainak módosítása a most beírt adatokkal.',
  'toolTip.editAccount.cancelBtn': 'A korábbi adatok visszaállítása.',
  'toolTip.editAccount.deleteBtn': 'A számla törlése minden hozzá tartozó tranzakcióval és beállítással együtt.',

  // add transaction page
  'toolTip.addTransaction.direction': 'A tranzakció iránya automatikusan meghatározza az előjelét is. Ha korrekciós tételt akar rögzíteni, akkor mentés után nyissa meg a tranzakciót szerkesztésre, ott tuja változtatni az előjelet.',
  'toolTip.addTransaction.dateOfTransaction': 'Ne felejtse el beállítani, ha nem ma történt a tranzakció!',
  'toolTip.addTransaction.amount': 'Írja be az összeget előjel nélkül!',
  'toolTip.addTransaction.namePartner': 'A cég vagy a személy, akinek Ön fizetett, vagy aki fizetett Önnek.',
  'toolTip.addTransaction.regularFlag': 'A rendszeresnek jelölt tranzakciók alapján a Continuity egy évre előre cash-flow tervet készít. Ha korábban mármegjelölt egy ilyen tranzakciót rendszeresként, akkor ne jelölje meg újra, mert akkor duplán fog bekerülni a tervbe.',
  'toolTip.addTransaction.description': 'Az Ön által fontosnak gondolt információ a tranzakcióról.',
  'toolTip.addTransaction.note': 'Az Ön megjegyzése.',
  'toolTip.addTransaction.saveBtn': 'Mentés után a tranzakció szerkesztésre megnyitható, ha valamelyik adatot tévesen írta be.',
  'toolTip.addTransaction.cancelBtn': 'Az eddig beírt adatok törlése.',

  // the following seven fields do not appear on the data entry form
  'toolTip.addTransaction.ibanPartner': 'toolTip.addTransaction.ibanPartner',
  'toolTip.addTransaction.accountBicPartner': 'toolTip.addTransaction.accountBicPartner',
  'toolTip.addTransaction.accountNumberPartner': 'toolTip.addTransaction.accountNumberPartner',
  'toolTip.addTransaction.cardNo': 'toolTip.addTransaction.cardNo',
  'toolTip.addTransaction.amountOrig': 'toolTip.addTransaction.amountOrig',
  'toolTip.addTransaction.currency': 'toolTip.addTransaction.currency',
  'toolTip.addTransaction.dateOfBooking': 'toolTip.addTransaction.dateOfBooking',

  // edit transaction page
  'toolTip.editTransaction.directionFlag': 'Egy pozitív előjelű tranzakciót is megjelölhet kiadásként, ha pl. egy szolgáltatótól túlfizetés miatt visszatérítést kap. Így ez a kiadásait csökkenti, nem a bevételeit növeli.',
  'toolTip.editTransaction.dateOfTransaction': 'Amikor a kifizetés történt. Ha fájl az adatok forrása, akkor nem módosítható.',
  'toolTip.editTransaction.amount': 'A kifizetett összeg a számla pénznemében. Ha fájl az adatok forrása, akkor nem módosítható.',
  'toolTip.editTransaction.namePartner': 'A cég vagy a személy, akinek Ön fizetett, vagy aki fizetett Önnek. Ha fájl az adatok forrása, akkor nem módosítható.',
  'toolTip.editTransaction.regularFlag': 'A rendszeresnek jelölt tranzakciók alapján a Continuity egy évre előre cash-flow tervet készít. Egy tranzakció fajtát csak egyszer jelöljön meg rendszeresként! Célszerű az utolsó előfordulásánál megjelölni.',
  'toolTip.editTransaction.description': 'Ha fájl az adatok forrása, akkor nem módosítható.',
  'toolTip.editTransaction.note': 'Ha fájl az adatok forrása, akkor nem módosítható.',
  'toolTip.editTransaction.ibanPartner': 'Fájlból származó adat, nem módosítható.',
  'toolTip.editTransaction.accountBicPartner': 'Fájlból származó adat, nem módosítható.',
  'toolTip.editTransaction.accountNumberPartner': 'Fájlból származó adat, nem módosítható.',
  'toolTip.editTransaction.cardNo': 'A tranzakcióhoz használt kártya száma. Fájlból származó adat, nem módosítható.',
  'toolTip.editTransaction.amountOrig': 'Ha nem a számla pénznemében történt a tranzakció, akkor az eredeti összeg. Fájlból származó adat, nem módosítható.',
  'toolTip.editTransaction.currencyOrig': 'Ha nem a számla pénznemében történt a tranzakció, akkor az eredeti pénznem. Fájlból származó adat, nem módosítható.',
  'toolTip.editTransaction.dateOfBooking': 'Amikor az Ön pénzintézete lekönyvelte a tranzakciót. Fájlból származó adat, nem módosítható.',
  'toolTip.editTransaction.saveBtn': 'A változtatások mentése.',
  'toolTip.editTransaction.cancelBtn': 'A korábbi adatok visszaállítása.',
  'toolTip.editTransaction.removeBtn': 'Törölheti a tranzakciót, ha véletlenül többször töltötte fel, vagy rögzítette.',

  // account upload csv page
  'toolTip.accountUpload.fileName': 'toolTip.accountUpload.fileName',
  'toolTip.accountUpload.delimiter': 'A rendszer automatikusan megállapítja, hogy az adatok milyen karakterrel vannak elválasztva. Csak akkor változtasson rajta, ha biztos a dolgában.',
  'toolTip.accountUpload.quote': 'A rendszer automatikusan megállapítja, hogy az adatok idézőjelek között szerepelnek-e. Csak akkor változtasson rajta, ha biztos a dolgában.',
  'toolTip.accountUpload.charset': 'A rendszer automatikusan megállapítja a fájlban használt karakter készletet. Csak akkor változtasson rajta, ha biztos a dolgában.',
  'toolTip.accountUpload.numberOfHeaderLines': 'Állítsa be, hogy a fájl elején hány sort tesz ki a fejléc, ami után a tényleges adatok következnek.',
  'toolTip.accountUpload.selectColumn': 'Az automatizált kategorizálást segíti, ha a partner nevét, számlaszámát vagy IBAN-jét, és a megjegyzés mezőt is feltölti a kötelező mezőkön kívül.',
  'toolTip.accountUpload.dateFormat': 'Figyeljen az év a hónap és a nap sorrendjére, írásmódjára és az elválasztó karakterekre. Ezek alapján válasszon dátum formátumot!',
  'toolTip.accountUpload.numberFormat': 'Figyelje meg, hogy a számok tizedes vesszőt vagy tizedes pontot tartalmaznak-e? Az ezresek után van-e elválasztó karakter, és ha van, akkor mi az? Ezek alapján válasszon számformátumot!',
  'toolTip.accountUpload.backBtn': 'Visszalépés a fájl kiválasztásához.',
  'toolTip.accountUpload.nextBtn': 'A kiválasztott fájl betöltése.',
  'toolTip.accountUpload.correctTheSettingBtn': 'Ha az előzetes betöltés alapján úgy látja, hogy módosítania kell a beállításokon, akkor ezzel a gombbal tud visszamenni.',
  'toolTip.accountUpload.saveAndFinishBtn': 'Ha elégedett az előzetes betöltéssel, akkor fejezze be a műveletet.',

  // profile page
  'toolTip.profileDetail.name': 'Nevezze el a felhasználói profilját tetszése szerint!',
  'toolTip.profileDetail.currency': 'Adja meg profiljának pénznemét! Ha több valutában is van pénze, akkor számlái áttekintésekor ebben a valutában fogja látni az összesített egyenlegét.',
  'toolTip.profileDetail.attachFile': 'Tegye személyessé profilját egy képpel!',
  'toolTip.profileDetail.removeBtn': 'Itt kezdeményezheti profilja törlését. A törlés után a korábbi e-mail címmel már nem tud újra regisztrálni.',

  // sent new user page
  'toolTip.inviteUsers.email': 'A meghívott e-mail címe. Ha ezzel az e-mail címmel használja a meghívott a Continuity-t, akkor tudjuk értékelni a közreműködését.',
  'toolTip.inviteUsers.message': 'Tegye személyessé a meghívást',

  // transaction filter modal page
  'transactionFilterModal.title': 'Kategória listázása: ',
  'transactionFilterModal.accountName': 'Számla neve',
  'transactionFilterModal.dateOfTransaction': 'Tranzakció dátuma',
  'transactionFilterModal.amount': 'Összeg',
  'transactionFilterModal.descAndNote': 'Leírás/Megjegyzés',
  'transactionFilterModal.partnerName': 'Partner neve',

  // delete my user account modal
  'deleteUserAccount.confirmMessage1': 'Biztos benne, hogy törölni akarja fiókját?',
  'deleteUserAccount.confirmMessage2': 'A törlés után az adatai nem lesznek visszaállíthatók, és a mostani azonosítóival nem fog tudni többé bejelentkezni.\n Biztosan törli a fiókját?',
  'deleteUserAccount.deleteSuccessfully': 'Felhasználói fiókját sikeresen töröltük.',
  'deleteUserAccount.deleteUnsuccessfully': 'A felhasználói fiókjának törlése nem sikerült. Kérjük, vegye fel a kapcsolatot ügyfélszolgálatunkkal!',

  // help popup
  'helpPopup.step0.title': 'Áttekintés',
  'helpPopup.step0.intro': 'Az \"Összesített egyenleg\" a számlák aktuális egyenlegének összege a profilja pénznemében. Azaz itt látja, hogy mennyi pénze van összesen. A profilétól eltérő pénznemben vezetett számláinak az egyenlegét a rendszer átszámolja a profil pénznemére az utolsó aktuális árfolyamon. Alatta látható az összes számlájának íz aktuális egyenlege a számla pénznemében. Az aktuális egyenleg csak a tényeket mutatja, a terveket nem. Tehát csak a beírt vagy fájlból feltöltött tranzakciók összeggét tartalmazza az egyenleg, a tervezett tranzakciók nem szerepelnek benne. A profilod pénznemét egyébként a \"Profil\" menüpont alatt tudja megváltoztatni.',
  'helpPopup.step1.title': 'Számláim',
  'helpPopup.step1.intro': 'Itt tud új számlákat hozzáadni a profiljához, és a már meglévő számláinak az adatait kiegészíteni vagy módosítani. De mi is az a számla? Jobb híján számlának hívunk mindent, ahol pénzt vagy más vagyoni elemeket tarthat. Tehát egy lakástakarék betét, egy befektetési számla, sőt a pénztárcád is egy-egy \"számla\". És ha a pénztárcájában forint mellett más valuta is van, akkor azt is érdemes külön számlaként felvenni, mert egy számlának csak egy pénzneme lehet. A számlák megadott tulajdonságait a rendszer figyelembe veszi, a számítások során, pl. amikor a számlák közötti átvezetés esetén keresi egy tranzakció párját. Ezért a számla nevének és az induló egyenleg adatainak kivételével a számlaadatok utólag nem módosíthatók. Ha valamilyen adatot tévesen adott meg, akkor érdemes azt a számlát törölni, és újra létrehozni. Remélhetőleg egyébként az ilyen tévedés gyorsan kiderül, és így nem vész kárba sok adat a törléskor.',
  'helpPopup.step2.title': 'Profil',
  'helpPopup.step2.intro': 'Itt adhatja meg vagy változtathatja meg a profilja nevét, pénznemét, nyelvét és a profil képet. Ha törölni akarja a profilját, azt is itt teheti meg, de azért gondolja meg! Nekünk hiányozni fog, és később ugyanezzel az e-mail címmel már nem tud majd újra regisztrálni.',
  'helpPopup.step3.title': 'Elemzés',
  'helpPopup.step3.intro': 'Megmutatja, hogy mire mennyit költött, és milyen forrásokból származnak a bevételei. Ha már egy-két hónapja használja a rendszert, akkor érdemes elemeznie a diagrammokat. Ha túl sok az \"egyéb\" kategóriába sorolt bevétel vagy kiadás, akkor javasoljuk, hogy fordítson kicsit több figyelmet a kategorizálásra.',
  'helpPopup.step3.imageUrl': 'https://live.staticflickr.com/65535/51930070200_95da6a62ff_c.jpg',
  'helpPopup.step4.title': 'Cash-flow',
  'helpPopup.step4.intro': 'A rendszeresnek jelölt, vagy a rendszer által rendszeresnek érzékelt tranzakciók alapján a rendszer készít Önnek egy cash-flow tervet. Ezt érdemes számlánként is és összességében is rendszeresen ellenőrizni. Ha a terv valamikorra negatív egyenleget mutat egy számlán vagy összességében, akkor még időben tud korrigálni. Pl. be tud fizetni arra a számlára, ahol el fog fogyni a pénz, vagy el tud halasztani egy kiadást, esetleg előleget tud kérni valamelyik későbbi bevételéből.',
  'helpPopup.step4.imageUrl': 'https://live.staticflickr.com/65535/51928480722_346507237f_b.jpg',

  // first time login initial help
  'firstTimeLogin.popup.name': 'Nevezze el az első számláját! Bárhogyan hívhatja, a lényeg, hogy ráismerjen a neve alapján, mert ezen a néven fog szerepelni itt a továbbiakban. Először érdemes a gyakran használt számlákat fevenni a rendszerbe.',
  'firstTimeLogin.popup.nature': 'Válassza ki a számla típusát! Általában mindenkinek van legalább egy pénztárcája és egy banki folyószámlája. Kezdje ezekkel!',
  'firstTimeLogin.popup.source': 'Válassza ki az adatbevitel módját. A készpénzes tranzakciókat kézzel kell beírnia. A banki tranzakciókat feltöltheti a netbankból letöltött fájl segítségével is.',
  'firstTimeLogin.popup.currency': 'Válassza ki a számla pénznemét! Az egyes számláinak a pénzneme lehet különböző, de egy számlának csak egy pénzneme lehet, amit később nem tud megváltoztatni. Egy új pénznemhez új számlát kell felvennie.',
};
