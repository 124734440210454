import { StringUtils } from './String.config';
import * as moment from 'moment';
import * as introJs from 'intro.js/intro.js';

export class BasePage {
  dateFormat = 'MM-dd-yyyy hh:mm:ss';
  timeFormat = 'MM-dd-yyyy hh:mm:ss';
  defaultCurrency = 'EUR';
  defaultProfileId = '';
  defaultLanguage = 'en';
  profileImage = null;
  toolTipPosition = 'below';
  introJS = introJs();
  constructor() {
    this.initCommon();
  }
  protected initCommon() {
    this.dateFormat = localStorage.getItem('dateFormat');
    this.timeFormat = localStorage.getItem('timeFormat');
    this.defaultCurrency = localStorage.getItem('defaultCurrency');
    this.defaultProfileId = localStorage.getItem('defaultProfileId');
    this.defaultLanguage = localStorage.getItem('language');
    this.profileImage = localStorage.getItem('profileImage') ? localStorage.getItem('profileImage') : null;
  }

  formatMoney(balance) {
    return !this.isNull(balance) ? StringUtils.formatMoney(balance) : '-';
  }

  getRate(rates: any, currency: string) {
    for (const rate of rates) {
      if (rate.currency === currency) {
        return rate.rate;
      }
    }
    return 0;
  }

  dateToYYYYMM(date: Date): string {
    const ds: string = date.getFullYear()
      + '-' + ('0' + (date.getMonth() + 1)).slice(-2);
    return ds;
  }

  getDateInFormat(date: Date, format: string): string {
    return moment(date).format(format);
  }

  getDateValue(time) {
    const date2 = moment.utc(time)
      .add(moment(time).utcOffset(), 'm');
    return date2;
  }

  convertToNumber(amount) {
    if (!amount || this.defaultLanguage !== 'hu') {
      return amount;
    } else {
      if (amount.toString().includes(',')) {
        return amount.toString().replace(',', '.');
      } else {
        return amount;
      }
    }
  }

  convertToNumberString(amount) {
    if (!amount || this.defaultLanguage !== 'hu') {
      return amount;
    } else {
      if (amount.toString().includes('.')) {
        return amount.toString().replace('.', ',');
      } else {
        return amount.toString();
      }
    }

  }

  isNull(obj: any) {
    return obj === null || (typeof obj === 'undefined');
  }

  public orderListInAlphabeticalWithSelectedLanguage(translateService, listLabelsOfCategory) {
    listLabelsOfCategory.sort((label1, label2) => {
      const i18nLabel1 = translateService.instant('enum.name.' + label1.enumId.trim()).toUpperCase();
      const i18nLabel2 = translateService.instant('enum.name.' + label2.enumId.trim()).toUpperCase();
      return (i18nLabel1 < i18nLabel2) ? -1 : (i18nLabel1 > i18nLabel2) ? 1 : 0;
    });
    return listLabelsOfCategory;
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async checkFirstTimeLogin(helpPopupStep, dataSharingService) {
    await this.delay(1000);
    const firstTimeLoginVisitedSteps = localStorage.getItem('firstTimeLoginVisitedSteps');
    const visitedSteps = firstTimeLoginVisitedSteps ? firstTimeLoginVisitedSteps.split(',') : [];
    const checking = visitedSteps.filter(i => i === helpPopupStep.toString());
    if (visitedSteps.length > 0 && checking.length === 0) {
      visitedSteps.push(helpPopupStep.toString());
      localStorage.setItem('firstTimeLoginVisitedSteps', visitedSteps.toString());
      dataSharingService.showTourGuide.next(true);
    }
  }

}
