import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-first-time-login',
  templateUrl: './first-time-login.component.html',
  styleUrls: ['./first-time-login.component.css']
})
export class FirstTimeLoginComponent implements OnInit {

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.updateFirstTimeLogin();
  }

}
