import { NativeDateAdapter } from '@angular/material';
import { formatDate } from '@angular/common';
import { PeriodEnums } from '../analytics/period-enums';
import * as moment from 'moment';
export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date, localStorage.getItem('dateFormat'), this.locale);
    } else {
      return date.toDateString();
    }
  }
}

export class DateUtil {
  static getDateFromTimeRange(period) {
    const dateObj = {
      from: '',
      to: ''
    };
    switch (period) {
      case PeriodEnums.THIS_MONTH.value: {
        dateObj.from = moment().startOf('month').format('YYYY-MM-DD');
        dateObj.to = moment().endOf('month').format('YYYY-MM-DD');
        break;
      }
      case PeriodEnums.LAST_MONTH.value: {
        dateObj.from = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        dateObj.to = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        break;
      }
      case PeriodEnums.THIS_WEEK.value: {
        dateObj.from = moment().startOf('week').format('YYYY-MM-DD');
        dateObj.to = moment().endOf('week').format('YYYY-MM-DD');
        break;
      }
      case PeriodEnums.LAST_WEEK.value: {
        dateObj.from = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
        dateObj.to = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
        break;
      }
      case PeriodEnums.THIS_YEAR.value: {
        dateObj.from = moment().startOf('year').format('YYYY-MM-DD');
        dateObj.to = moment().endOf('year').format('YYYY-MM-DD');
        break;
      }
      case PeriodEnums.LAST_YEAR.value: {
        dateObj.from = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
        dateObj.to = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
        break;
      }
      case PeriodEnums.TODAY.value: {
        dateObj.from = moment().format('YYYY-MM-DD');
        dateObj.to = moment().format('YYYY-MM-DD');
        break;
      }
      default: {
        break;
      }
    }
    return dateObj;
  }
  static today() {
    return moment();
  }
}
