import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../service/common.service';
import { AccountEnumsModel } from './account-enums.model';
import { AccountService } from '../../service/account.service';
import { ToastServices } from '../../shared/toast.service';
import { Router } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PICK_FORMATS, PickDateAdapter } from '../../shared/date.util';
import { TranslateService } from '../../translate';
import { BasePage } from '../../shared/BasePage';
import { ProfileService } from '../../service/profile.service';
import { UserService } from '../../service/user.service';
import { DataSharingService } from '../../shared/data-service-change';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css'],
  providers: [
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
  ]
})

export class AddAccountComponent extends BasePage implements OnInit, AfterViewInit {

  addAccountForm: FormGroup;
  accountEnumsValue = new AccountEnumsModel();
  @Input() public firstTimeLogin = false;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private commonService: CommonService,
              private translateService: TranslateService,
              private userService: UserService,
              private toastService: ToastServices,
              private profileService: ProfileService,
              private accountService: AccountService) {
    super();
  }

  ngOnInit() {
    this.initFormGroup();
    this.initCommonData();
  }

  async ngAfterViewInit() {
    if (this.firstTimeLogin) {
      this.initFirstHelp();
      await this.delay(1000);
      this.introJS.start();
    }
  }

  initFirstHelp() {
    this.introJS.setOptions({
      steps: [
        {
          element: '#nameAccountInput',
          intro: this.translateService.instant('firstTimeLogin.popup.name'),
          position: 'bottom'
        },
        {
          element: '#natureAccountInput',
          intro: this.translateService.instant('firstTimeLogin.popup.nature'),
          position: 'bottom'
        },
        {
          element: '#sourceAccountInput',
          intro: this.translateService.instant('firstTimeLogin.popup.source'),
          position: 'bottom'
        },
        {
          element: '#currencyAccountInput',
          intro: this.translateService.instant('firstTimeLogin.popup.currency'),
          position: 'bottom'
        }
      ],
      showProgress: false,
      tooltipClass: 'customTooltip',
      nextLabel: this.translateService.instant('button.next'),
      prevLabel: this.translateService.instant('button.back'),
      skipLabel: 'Skip',
      doneLabel: this.translateService.instant('button.ok'),
      showStepNumbers: false,
      showButtons: true,
      showBullets: false,
      scrollToElement: true,
    });
  }

  initFormGroup() {
    const defaultCurrency = this.firstTimeLogin ? this.defaultCurrency : '';
    this.addAccountForm = this.formBuilder.group({
      accountName: ['', Validators.required],
      nature: ['', Validators.required],
      source: ['', Validators.required],
      currency: [defaultCurrency, Validators.required],
      iban: [''],
      accountNumber: [''],
      bic: [''],
      initialBalance: ['0'],
      dateOfInitialBalance: [new Date()]
    });
  }

  initCommonData() {
    this.commonService.getAccountEnumValues().then(result => this.accountEnumsValue = {
        ...result,
        listAccountNature: result.listAccountNature.filter(a => a !== 'ASSET'),
        listAccountSource: result.listAccountSource.filter(a => a !== 'TRANSFER'),
    });
  }

  hasError = (controlName: string, errorName: string) => {
    return this.addAccountForm.controls[controlName].hasError(errorName);
  }

  changeNature(event) {
    if (event.value === 'CASH') {
      this.addAccountForm.get('source').setValue('MANUAL');
    }
  }

  isCashNature() {
    const formValue = this.addAccountForm.value;
    return formValue.nature === 'CASH';
  }

  onSubmit() {
    if (this.addAccountForm.invalid) {
      return;
    }

    const formValue = this.addAccountForm.value;
    const accountObj = {
      accountName: formValue.accountName,
      nature: formValue.nature,
      source: formValue.source,
      currency: formValue.currency,
      initialBalance: this.convertToNumber(formValue.initialBalance),
      dateOfInitialBalance: this.getDateInFormat(formValue.dateOfInitialBalance, 'YYYY-MM-DD'),
      iban: formValue.iban ? formValue.iban.trim() : null,
      accountNumber: formValue.accountNumber ? formValue.accountNumber.trim() : null,
      bic: formValue.bic ? formValue.bic.trim() : null,
      type: 'PERSON'
    };

    this.accountService.createAccount(accountObj).then((result) => {
        this.recalculateBalance(result, this.defaultProfileId, null, accountObj.dateOfInitialBalance);
        this.router.navigate(['/accounts']);
        this.toastService.showSuccess(this.translateService.instant('accountPage.message.createSuccess'));
        if (this.firstTimeLogin) {
          this.profileService.setCurrency(this.defaultProfileId, accountObj.currency).then(() => {
            this.userService.setDefaultCurrencyToLocal(formValue.currency);
          });
        }
        if (accountObj.nature !== 'CASH' && this.isNull(accountObj.iban) && this.isNull(accountObj.accountNumber)) {
          this.toastService.showWarning(this.translateService.instant('accountPage.ibanAccountNumber.waningMessage'));
        }
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('accountPage.message.createError'));
    });

  }

  recalculateBalance(accountId, profileId, transactionId, startingDate) {
    this.accountService.recalculateBalance(accountId, profileId, transactionId, startingDate, 'ACCOUNT').catch((err => {
      if (err.error && err.error.errorMessage === 'INITIAL_BALANCE_IS_LATER') {
        this.toastService.showError(this.translateService.instant('error.INITIAL_BALANCE_IS_LATER'));
      } else {
        this.toastService.showError(this.translateService.instant('error.FAILED'));
      }
    }));
  }

  backToAccountList() {
    this.router.navigate([`/accounts`]);
  }

}
