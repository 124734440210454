export class ProfileModel {
  constructor() {}
  public id: string;
  public userId: string;
  public name: string;
  public currency: string;
  public accountingPeriod: string;
  public type: string;
  public deleted: boolean;
}
