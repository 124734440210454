import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '../../translate';

@Component({
  selector: 'app-transaction-label-selection',
  templateUrl: './transaction-label-selection.component.html',
  styleUrls: ['./transaction-label-selection.component.css']
})
export class TransactionLabelSelectionComponent implements OnInit {

  @Input() public data: any;
  @Input() public listLabelsOfCategory = [];
  @Input() public selectedItem = { id : ''};
  @Output() updateLabelSelected = new EventEmitter();
  @Input() labelId: string;
  @Input() readOnly: boolean;
  @Input() isTransferTransaction: boolean;
  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    if (this.isTransferTransaction) {
      this.readOnly = true;
      if (!this.labelId) {
        this.selectedItem = this.getLabelByEnumId('TRANSFER');
        this.labelId = this.selectedItem.id.toString();
        this.updateLabelSelected.emit({ label: this.getLabelById(this.labelId)});
      }
    }
  }

  labelChanged(selectedLabel) {
    this.labelId = selectedLabel;
    this.updateLabelSelected.emit({ label: this.getLabelById(this.labelId)});
  }

  getLabelName() {
    if (this.labelId) {
      return this.translateService.instant('enum.name.' + this.getLabelById(this.labelId).enumId.trim());
    }
  }

  getLabelById(id) {
    for (const item of this.listLabelsOfCategory) {
      if (item.id.toString() === id.toString()) {
        return item;
      }
    }
  }

  getLabelByEnumId(enumId) {
    for (const item of this.listLabelsOfCategory) {
      if (item.enumId.toString().trim() === enumId) {
        return item;
      }
    }
  }
}
