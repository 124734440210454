import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit, OnInit
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { TokenService } from '../../../authentication/token.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { UserService } from '../../../service/user.service';
import { BasePage } from '../../../shared/BasePage';
import { DataSharingService } from '../../../shared/data-service-change';
import { getHelpPopupStepFromRoute, HELP_POPUP_STEP } from '../../../shared/data.utils';
import { TranslateService } from '../../../translate';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})

export class AppSidebarComponent extends BasePage implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  showTourGuide = 'false';
  private _mobileQueryListener: () => void;

  steps =     [
    {
      element: `#step0`,
      title: this.translateService.instant('helpPopup.step0.title'),
      intro: this.translateService.instant('helpPopup.step0.intro'),
      position: 'right'
    },
    {
    element: '#step1',
    title: this.translateService.instant('helpPopup.step1.title'),
    intro: this.translateService.instant('helpPopup.step1.intro'),
    position: 'right'
    },
    {
      element: '#step2',
      title: this.translateService.instant('helpPopup.step2.title'),
      intro: this.translateService.instant('helpPopup.step2.intro'),
      position: 'right'
    },
    {
      element: '#step3',
      title: this.translateService.instant('helpPopup.step3.title'),
      intro: `<p>${this.translateService.instant('helpPopup.step3.intro')}</p> <br/>
              <img src="${this.translateService.instant('helpPopup.step3.imageUrl')}" />`,
      position: 'right'
    },
    {
      element: '#step4',
      title: this.translateService.instant('helpPopup.step4.title'),
      intro: `<p>${this.translateService.instant('helpPopup.step4.intro')}</p> <br/>
              <img src="${this.translateService.instant('helpPopup.step4.imageUrl')}" />`,
      position: 'right'
    },
  ];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private router: Router,
    private authenService: AuthenticationService,
    private userService: UserService,
    private translateService: TranslateService,
    private dataSharingService: DataSharingService,
  ) {
    super();
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  subscription: any;
  ngOnInit() {
    this.dataSharingService.profileImageUpdate.subscribe( () => {
      this.profileImage = localStorage.getItem('profileImage');
    });
    this.subscription = this.dataSharingService.showTourGuide.subscribe(async (wasTriggered) => {
      console.log(this.router.url);
      console.log(wasTriggered);
      // const showTourGuide = localStorage.getItem('showTourGuide');
      const helpPopupStep = getHelpPopupStepFromRoute(this.router.url);
      console.log(helpPopupStep);
      if (wasTriggered) {
        this.setHelpPopupOption(helpPopupStep);
        await this.delay(500);
        this.introJS.start();
      }
    });
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.subscription.unsubscribe();
  }

  setHelpPopupOption(index: number) {
    const cssClass = this.mobileQuery.matches ? 'desktopTourCss' : 'mobileTourCss';
    this.introJS.setOptions({
      steps: [this.steps[index]],
      showProgress: false,
      tooltipClass: cssClass,
      nextLabel: this.translateService.instant('button.next'),
      prevLabel: this.translateService.instant('button.back'),
      skipLabel: 'Skip',
      doneLabel: this.translateService.instant('button.ok'),
      showStepNumbers: false,
      showButtons: true,
      exitOnOverlayClick: true,
      showBullets: false,
      scrollToElement: true,
    }).oncomplete(() => {
      const sidebarStatus = localStorage.getItem('showingSideBar') === 'true';
      this.dataSharingService.sidebarStatus.next(sidebarStatus);
      this.dataSharingService.showTourGuide.next(false);
      console.log('sidebarStatus', sidebarStatus);
    });
  }
}
