import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountEnumsModel } from '../add-account/account-enums.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { ToastServices } from '../../shared/toast.service';
import { AccountService } from '../../service/account.service';
import { accountDetailMandatory } from '../../shared/helper/form.validator';
import { BasePage } from '../../shared/BasePage';
import { TranslateService } from '../../translate';
import { ConfirmPopupComponent } from '../../shared/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.css']
})

export class AccountDetailComponent extends BasePage implements OnInit {
  addAccountForm: FormGroup;
  accountEnumsValue = new AccountEnumsModel();
  accountId = 0;
  mode = 'edit';
  account: any;
  currentBalance = 0;
  currency = '';
  lastUpdated = '-';
  hasTransactions = false;
  originalDate = '';

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private commonService: CommonService,
              private toastService: ToastServices,
              private translateService: TranslateService,
              private accountService: AccountService) {
    super();
  }

  ngOnInit() {
    this.initFormGroup();
    this.initCommonData();
    this.activatedRoute.paramMap.subscribe(params => {
      this.accountId = +params.get('id');
      this.mode = params.get('mode');
      if (this.mode !== 'edit' && this.mode !== 'view') {
        this.router.navigate(['/page-not-found']);
      }
      this.initAccountData();
    });
  }

  initAccountData() {
    this.accountService.getAccount(this.accountId).then(account => {
      this.account = account;
      this.originalDate = this.account.dateOfInitialBalance;
      this.currentBalance = account.currentBalance;
      this.currency = account.currency;
      this.fillFormData(account);
      this.lastUpdated = account.lastUpdated;
      this.hasTransactions = account.lastUpdated ? true : false;
    }).catch(() => {
      this.toastService.showError('Get account unsuccessfully');
    });
  }

  initFormGroup() {
      this.addAccountForm = this.formBuilder.group({
        accountName: ['', Validators.required],
        nature: ['', Validators.required],
        source: ['', Validators.required],
        currency: ['', Validators.required],
        iban: [''],
        accountNumber: [''],
        bic: [''],
        initialBalance: ['0'],
        dateOfInitialBalance: [new Date()]
      }, {
         validator: accountDetailMandatory('nature', 'iban', 'accountNumber')
      });
  }

  resetForm() {
    this.initAccountData();
  }

  fillFormData(account) {
    this.addAccountForm = this.formBuilder.group({
      accountName: [account.accountName, Validators.required],
      nature: [account.nature, Validators.required],
      source: [account.source, Validators.required],
      currency: [account.currency, Validators.required],
      iban: [account.iban],
      accountNumber: [account.accountNumber],
      bic: [account.bic],
      initialBalance: [this.convertToNumberString(account.initialBalance)],
      dateOfInitialBalance: [account.dateOfInitialBalance]
    });
  }

  initCommonData() {
    this.commonService.getAccountEnumValues().then(result => this.accountEnumsValue = result);
  }

  hasError = (controlName: string, errorName: string) => {
    return this.addAccountForm.controls[controlName].hasError(errorName);
  }

  changeNature(event) {
    if (event.value === 'CASH') {
      this.addAccountForm.get('source').setValue('MANUAL');
    }
  }

  isCashNature() {
    const formValue = this.addAccountForm.value;
    return formValue.nature === 'CASH';
  }

  onSubmit() {
    if (this.addAccountForm.invalid) {
      return;
    }
    const formValue = this.addAccountForm.value;
    const accountObj = {
      id: this.account.id,
      userId: this.account.userId,
      currentBalance: this.currentBalance,
      lastUpdated: this.lastUpdated,
      accountName: formValue.accountName,
      nature: formValue.nature,
      source: formValue.source,
      currency: formValue.currency,
      initialBalance: this.convertToNumber(formValue.initialBalance),
      dateOfInitialBalance: this.getDateInFormat(formValue.dateOfInitialBalance, 'YYYY-MM-DD'),
      iban: formValue.iban ? formValue.iban.trim() : null,
      accountNumber: formValue.accountNumber ? formValue.accountNumber.trim() : null,
      bic: formValue.bic ? formValue.bic.trim() : null,
      type: 'PERSON',
    };
    this.accountService.setAccount(accountObj).then(() => {
      this.recalculateBalance(this.account.id, this.defaultProfileId, null, this.originalDate);
      this.toastService.showSuccess(this.translateService.instant('accountPage.message.updateSuccess'));
      this.initAccountData();
      if (accountObj.nature !== 'CASH' && this.isNull(accountObj.iban) && this.isNull(accountObj.accountNumber)) {
        this.toastService.showWarning(this.translateService.instant('accountPage.ibanAccountNumber.waningMessage'));
      }
      this.backToAccountList();
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('accountPage.message.updateError'));
    });
  }

  accountDetailEditable(field: string) {
    // return !this.addAccountForm.get(field).value;
    if (field === 'iban') {
      return this.account && this.account.iban;
    } else if (field === 'accountNumber') {
      return this.account && this.account.accountNumber;
    } else if (field === 'bic') {
      return this.account && this.account.bic;
    }
  }

  recalculateBalance(accountId, profileId, transactionId, startingDate) {
    this.accountService.recalculateBalance(accountId, profileId, transactionId, startingDate, 'ACCOUNT')
      .then(() => {
        this.initAccountData();
      }).catch((err => {
      if (err.error && err.error.errorMessage === 'INITIAL_BALANCE_IS_LATER') {
        this.toastService.showWarning(this.translateService.instant('error.INITIAL_BALANCE_IS_LATER'));
      } else {
        this.toastService.showError(this.translateService.instant('error.FAILED'));
      }
    }));
  }

  backToAccountList() {
    this.router.navigate([`/accounts`]);
  }

  deleteAccount() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '320px',
      data: {
        message: 'accountPage.removeConfirmation.message',
        ok: false,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.ok) {
         this.accountService.removeAccount(this.accountId).then(() => {
           this.toastService.showSuccess(this.translateService.instant('accountPage.remove.success'));
           this.backToAccountList();
         }).catch(() => {
           this.toastService.showError(this.translateService.instant('accountPage.remove.fail'));
         });
      }
    });
  }
}
