import { Injectable } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { ConstantSetting } from '../shared/constant.config';

@Injectable()
export class CommonService {
  constructor(private http: HttpService) {}

  getLanguages(): Promise<any> {
    return this.http.getWithoutToken(ConstantSetting.GET_LANGUAGE);
  }

  getAccountEnumValues(): Promise<any> {
    return this.http.getWithoutToken(ConstantSetting.GET_ACCOUNT_ENUM_VALUES);
  }

  getTransactionEnumValues(): Promise<any> {
    return this.http.getWithoutToken(ConstantSetting.GET_TRANSACTION_ENUM_VALUES);
  }

  activateAccount(keycloakUserId: string): Promise<any> {
    return this.http.putWithoutTokenAndBody(ConstantSetting.ACTIVATE_USER + '/' + keycloakUserId);
  }

  getDefaultLabelCategory(): Promise<any> {
    return this.http.getWithoutToken(ConstantSetting.GET_LIST_DEFAULT_LABEL_CATEGORIES);
  }
}
