import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { TranslateService } from '../../translate';
import { ToastServices } from '../../shared/toast.service';

@Component({
  selector: 'app-active-account',
  templateUrl: './active-account.component.html',
  styleUrls: ['./active-account.component.css']
})
export class ActiveAccountComponent implements OnInit {
  constructor(
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private commonService: CommonService,
              private toastService: ToastServices,
              private translateService: TranslateService,
              ) {}

  keycloakUserId = '';
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.keycloakUserId = params.get('id');
    });
    this.commonService.activateAccount(this.keycloakUserId).then(() => {
      this.router.navigate(['/login']);
      this.toastService.showSuccess(this.translateService.instant('activeAccountPage.verifySuccess'));
    }).catch(err => {
      console.log(err);
      this.router.navigate(['/login']);
      this.toastService.showError(this.translateService.instant('activeAccountPage.verifyFail'));
    });
  }
}
