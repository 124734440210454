import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: 'accounts', type: 'link', name: 'Overview', icon: 'dashboard' },
  { state: 'user-account-list', type: 'link', name: 'AccountList', icon: 'account_balance' },
  { state: 'profile-detail', type: 'link', name: 'Profile', icon: 'recent_actors' },
  { state: 'analytics', name: 'Analytics', type: 'link', icon: 'pie_chart' },
  { state: 'cash-flow', name: 'Cash-flow', type: 'link', icon: 'trending_up' },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
