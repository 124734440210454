import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.config';
import { Token } from './token.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as queryString from 'querystring';

@Injectable()
export class TokenService {
  TOKEN_KEY = 'token';
  LANGUAGE_KEY = 'language';
  USER_KEY = 'user';
  DATE_FORMAT = 'dateFormat';
  TIME_FORMAT = 'timeFormat';
  DEFAULT_CURRENCY = 'defaultCurrency';
  DEFAULT_PROFILE_ID = 'defaultProfileId';
  PROFILE_IMAGE = 'profileImage';
  GRANT_PATH = `auth/realms/${AppSettings.KEYCLOAK_REAM}/protocol/openid-connect/token`;
  REVOKE_PATH = `auth/realms/${AppSettings.KEYCLOAK_REAM}/protocol/openid-connect/logout`;
  GRANT_TYPE_PASSWORD = 'password';
  GRANT_TYPE_ACCESS_TOKEN = 'refresh_token';

  token: Token;

  constructor(private http: HttpClient) {

  }

  /**
   * build token object from json
   *
   * @param tokenJson
   * @returns {Token}
   */
  buildTokenFromJSON(tokenJson: any): Token {
    return new Token(tokenJson.access_token || tokenJson.accessToken,
      tokenJson.expires_in || tokenJson.expiresIn,
      tokenJson.refresh_token || tokenJson.refreshToken,
      tokenJson.token_type || tokenJson.tokenType,
      tokenJson.scope
    );
  }

  /**
   * set token to localstorage
   */
  setToken(token: Token) {
    this.token = token;
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(token));
  }

  setUser(user: any) {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    const lang = (user && user.language.i18nCode) ? user.language.i18nCode : 'en';
    const dateFormat = (user && user.language.dateFormat) ? user.language.dateFormat : 'MM-dd-yyyy';
    const timeFormat = (user && user.language.timeFormat) ? user.language.timeFormat : 'MM-dd-yyyy hh:mm:ss';
    const defaultCurrency = (user && user.defaultCurrency) ? user.defaultCurrency : 'EUR';
    const defaultProfileId = (user && user.defaultProfileId) ? user.defaultProfileId : '0';
    const profileImage = (user && user.profileImage) ? user.profileImage : null;
    localStorage.setItem(this.LANGUAGE_KEY, lang);
    localStorage.setItem(this.DATE_FORMAT, dateFormat);
    localStorage.setItem(this.TIME_FORMAT, timeFormat);
    localStorage.setItem(this.DEFAULT_CURRENCY, defaultCurrency);
    localStorage.setItem(this.DEFAULT_PROFILE_ID, defaultProfileId);
    if (profileImage) {
      localStorage.setItem(this.PROFILE_IMAGE, profileImage);
    } else {
      localStorage.removeItem(this.PROFILE_IMAGE);
    }
  }

  /**
   * get token from localstorage
   */
  getToken(): Token {
    let tokenJson: any;

    if (this.token !== undefined && this.token !== null) {
      return this.token;
    }

    tokenJson = JSON.parse(localStorage.getItem(this.TOKEN_KEY));

    if (tokenJson !== undefined && tokenJson !== null) {
      this.token = this.buildTokenFromJSON(tokenJson);
      return this.token;
    }

    return null;
  }

  /**
   * remove token from localstorage
   */
  removeToken() {
    localStorage.removeItem(this.TOKEN_KEY);
    this.token = null;
  }

  /**
   * check if user has been authenticated by checking
   * existing of access token
   *
   * @returns {boolean}
   */
  isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  /**
   * call a post request to obtain access token from authentication server
   *
   * @param userName
   * @param password
   *
   * @returns {Observable}
   */
  login(userName: string, password: string): Promise<any> {
    const body = {
        client_id: AppSettings.CLIENT_ID,
        grant_type: this.GRANT_TYPE_PASSWORD,
        scope: 'openid',
        username: userName,
        password,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: queryString.stringify(body),
    };

    return new Promise(((resolve, reject) => {
      return this.http.request('POST', AppSettings.API_KEYCLOAK_URL + this.GRANT_PATH, httpOptions)
        .toPromise().then(response => {
          const token: Token = this.buildTokenFromJSON(response);
          this.setToken(token);
          resolve(token);
        }).catch(err => {
          reject(err);
        });
    }));
  }

  /**
   * request server to refresh token
   */
  refreshToken() {
    const body = {
      client_id: AppSettings.CLIENT_ID,
      grant_type: this.GRANT_TYPE_ACCESS_TOKEN,
      refresh_token: this.getRefreshToken(),
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: queryString.stringify(body),
    };
    return this.http.request('POST', AppSettings.API_KEYCLOAK_URL + this.GRANT_PATH, httpOptions)
      .toPromise().then(response => {
        const token: Token = this.buildTokenFromJSON(response);
        this.setToken(token);
      }).catch(err => {
        console.log(err);
      });
  }

  /**
   * get access token from localstorage
   */
  getAccessToken(): string {
    const token = this.getToken();

    if (token !== undefined && token !== null) {
      return token.accessToken;
    }

    return null;
  }

  /**
   * get refresh token from localstorage
   */
  getRefreshToken(): string {
    const token = this.getToken();

    if (token !== undefined && token !== null) {
      return token.refreshToken;
    }

    return null;
  }

  /**
   * build authentication header from token
   *
   * @returns {string}
   */
  getAuthorizationHeader(): string {
    const token: Token = this.getToken();
    if (token !== undefined && token !== null) {
      return token.accessToken;
    }

    return null;
  }

  // tkRequest(url: string, body?: any, options?: RequestOptionsArgs): Observable<Response> {
  //   const requestOptions = new RequestOptions({
  //     method: RequestMethod.Get,
  //     url: AppSettings.API_ENDPOINT + url,
  //     body: body
  //   }).merge(options);
  //
  //   const headers = requestOptions.headers || new Headers();
  //   const accessToken = this.getAuthorizationHeader();
  //   headers.set('Authorization', 'Bearer ' + accessToken);
  //   requestOptions.headers = headers;
  //
  //   const request = new Request(requestOptions);
  //
  //   const dataReturn = this.http.request(request);
  //
  //   return dataReturn;
  // }

  // Error handling
  errorHandl(error): Observable<any> {
   return Observable.throw(error);
  }
}
