import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from '../service/transaction.service';
import { CommonService } from '../service/common.service';
import { AccountService } from '../service/account.service';
import { BasePage } from '../shared/BasePage';
import { TranslateService } from '../translate';
import { ToastServices } from '../shared/toast.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { AppCalendarHeaderComponent } from './app-calendar-header';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM',
  },
  display: {
    dateInput: 'YYYY-MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TransactionsComponent extends BasePage implements OnInit {

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastServices: ToastServices,
              private translateService: TranslateService,
              private commonService: CommonService,
              private accountService: AccountService,
              private transactionService: TransactionService) {
    super();
  }
  accountId = 0;
  account: any;
  listTransactions = [];
  defaultLabelCategoryList = [];
  month = new Date();
  monthString = '';
  currentBalanceObj = {
    firstDayOfMonthBalance: 'N/A',
    endDayOfMonthBalance: 'N/A'
  };
  jumpToDate = new FormControl(moment());
  customCalendarHeader = AppCalendarHeaderComponent;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.accountId = +params.get('accountId');
      this.monthString = params.get('month');
      if (params.get('month')) {
        this.month = new Date(params.get('month'));
      }
      await this.getDefaultLabelCategory();
      await this.getAccountInfo();
      await this.initBalance();
      this.initTiming(this.month);
    });
  }

  initTiming(month) {
    const toMonth = moment(month);
    this.jumpToDate = new FormControl(toMonth);
  }

  chosenYearHandler(normalizedYear: Moment, time: FormControl) {
    const ctrlValue = time.value;
    ctrlValue.year(normalizedYear.year());
    time.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, time: FormControl) {
    const ctrlValue = time.value;
    ctrlValue.month(normalizedMonth.month());
    time.setValue(ctrlValue);
    datepicker.close();
    const targetMonth = this.jumpToDate.value.format('YYYY-MM');
    this.router.navigate([`/accounts/${this.accountId}/transactions/list/${targetMonth}`]);
  }

  setJumpToDateEvent() {
    const targetMonth = this.jumpToDate.value.format('YYYY-MM');
    this.router.navigate([`/accounts/${this.accountId}/transactions/list/${targetMonth}`]);
  }

  async initBalance() {
    try {
      this.currentBalanceObj = await this.accountService.getBalanceForPeriods(this.accountId, this.dateToYYYYMMDD(this.month));
    } catch (e) {
      this.toastServices.showError(this.translateService.instant('transactionPage.message.getBalanceFail'));
    }
  }

  async getDefaultLabelCategory() {
    try {
      this.defaultLabelCategoryList = await this.commonService.getDefaultLabelCategory();
      await this.getListTransactions(this.accountId, this.month);
    } catch (e) {
      this.toastServices.showError(this.translateService.instant('transactionPage.message.getDefaultLabelCategory'));
    }
  }

  async getListTransactions(accountId: number, month: Date) {
    const from = new Date(month.getFullYear(), month.getMonth(), 1);
    const to = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const trans = [];
    try {
      const result = await this.transactionService.getTransactionsOfAccountList(accountId, this.dateToYYYYMMDD(from), this.dateToYYYYMMDD(to));
      Object.entries(result).forEach( ([key, value]) => {
        trans.push({
          day: key,
          transactionsListOfDay: result[key]
        });
      });
      this.sortingList(trans);
    } catch (err) {
      console.log(err);
      this.toastServices.showError(this.translateService.instant('transactionPage.message.getListOfTransactionFail'));
    }
  }

  sortingList(trans) {
    this.listTransactions = trans.sort((trans1, trans2) => {
      if (trans1.day > trans2.day) {
        return -1;
      }
      if (trans1.day < trans2.day) {
        return 1;
      }
      return 0;
    });
}

  dateToYYYYMMDD(Date: Date): string {
    const ds: string = Date.getFullYear()
      + '-' + ('0' + (Date.getMonth() + 1)).slice(-2)
      + '-' + ('0' + Date.getDate()).slice(-2);
    return ds;
  }

  getMoneyOfTheDay(dayTransaction) {
    return this.formatMoney(this.totalMoneyInDay(dayTransaction));
  }

  totalMoneyInDay(dayTransaction) {
    let totalBalance = 0;
    for (const tran of dayTransaction.transactionsListOfDay) {
      totalBalance += tran.amount;
    }
    return totalBalance;
  }

  next() {
    const nextMonth = this.dateToYYYYMM(new Date(this.month.getFullYear(), this.month.getMonth() + 1, 2));
    this.router.navigate([`/accounts/${this.accountId}/transactions/list/${nextMonth}`]);
  }

  previous() {
    const previousMonth = this.dateToYYYYMM(new Date(this.month.getFullYear(), this.month.getMonth() - 1, 2));
    this.router.navigate([`/accounts/${this.accountId}/transactions/list/${previousMonth}`]);
  }

  onSwipeLeft() {
    alert('');
  }

  getPreviousMonth() {
    return new Date(this.month.getFullYear(), this.month.getMonth() - 1, 2);
  }

  getNextMonth() {
    return new Date(this.month.getFullYear(), this.month.getMonth() + 1, 2);
  }

  async getAccountInfo() {
    try {
      this.account = await this.accountService.getAccount(this.accountId);
    } catch (e) {
      this.toastServices.showError(this.translateService.instant('transactionPage.message.getAccountFail'));
    }
  }

  public backToAccountList() {
    this.router.navigate([`/accounts`]);
  }

  public addTransaction() {
    const monthT = this.dateToYYYYMM(this.month);
    if (this.isSourceFile()) {
      this.router.navigate([`/accounts/${this.account.id}/transactions/upload-transaction`]);
    } else {
      this.router.navigate([`/accounts/${this.account.id}/transactions/create-transaction/${monthT}`]);
    }
  }

  public isSourceFile() {
    return this.account && this.account.source === 'FILE';
  }

  public isNullBalance(balance: string) {
    return (balance === null || balance === 'N/A');
  }

  public isPositiveAccount(balance: string) {
    if (!this.isNullBalance(balance)) {
      const count = Number(balance);
      return count > 0;
    } else {
      return false;
    }
  }

  onSwipe(event) {
    const x = Math.abs(event.deltaX) > 40 ? (event.deltaX > 0 ? 'left' : 'right') : '';
    console.log(`You swiped to <b> ${x} </b> direction`);
    if (x === 'right') {
      this.next();
    }
    if (x === 'left') {
      this.previous();
    }
  }
}
