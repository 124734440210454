export const LANG_EN_TRANS = {
  // global
  'button.save': 'Save',
  'button.cancel': 'Cancel',
  'button.ok': 'OK',
  'button.close': 'Close',
  'button.back': 'Back',
  'button.next': 'Next',
  'button.remove': 'Delete',
  'button.yes': 'Yes',
  'button.no': 'No',
  'button.edit': 'Edit',
  'button.detail': 'Detail',
  'button.saveAndFinish': 'Save & Finish',
  'button.correctTheSetting': 'Correct the setting',
  'generic.select.other': 'Other...',
  'session.isExpired': 'Your session has expired. Please log in again.',
  'button.select': 'Select',
  'button.sendInvitation': 'Send Invitation',

  // Login page
  'loginPage.title' : 'Sign in Continuity',
  'loginPage.email' : 'Email',
  'loginPage.password' : 'Password',
  'loginPage.remember-me' : 'Remember Me',
  'loginPage.signIn' : 'Sign In',
  'loginPage.forgotPassword' : 'Forgot Password?',
  'loginPage.newToContinuity' : 'New to Continuity?',
  'loginPage.createAccount' : 'Create Account',
  'loginPage.or' : 'or',
  'loginPage.signInWithFacebook' : 'Sign In With Facebook',
  'loginPage.signInWithGoogle' : 'Sign In With Google',
  'loginPage.message.invalidEmailPassword': 'Invalid email or password',
  'loginPage.message.loginFail': 'Login was unsuccessful',
  'loginPage.message.emptyField': 'Email and password are mandatory',

  // Register page
  'registerPage.title': 'Create an account',
  'registerPage.nickName': 'Nick Name',
  'registerPage.email': 'Email',
  'registerPage.password': 'Password',
  'registerPage.confirmPassword': 'Confirm Password',
  'registerPage.language': 'Language',
  'registerPage.signUp': 'Sign Up',
  'registerPage.nickNameRequired': 'Nick name is required',
  'registerPage.emailRequired': 'Email is required',
  'registerPage.emailInvalid': 'Email is invalid',
  'registerPage.passwordRequired': 'Password is required',
  'registerPage.passwordInvalid': 'The password must contain at least 6 characters',
  'registerPage.confirmPasswordRequired': 'Confirmation of password is required',
  'registerPage.confirmPasswordNotMatched': 'The two passwords are different',
  'registerPage.languageRequired': 'Language is required',
  'registerPage.message.success': 'You have registered successfully. Please check your mail box to verify your account.',
  'registerPage.message.fail': 'Sign up was unsuccessful',
  'registerPage.agreed.fail': 'You can use Continuity only if you have accepted our Terms of Service and our Privacy Policy.',
  'registerPage.readMessage': 'I have read and agree to the',
  'registerPage.termOfService': 'Terms of Services',
  'registerPage.privacyPolicy': 'Privacy Policy',

  // active user page
  'activeAccountPage.verifySuccess': 'You have verified your account successfully',
  'activeAccountPage.verifyFail': 'The verification of the account was unsuccessful',

  // Accounts page
  'accountsPage.header.currentBalance': 'Current Balance',
  'accountsPage.header.totalBalance': 'Total balance',
  'accountsPage.generalInformation' : 'General Information',
  'accountsPage.giroNumber' : 'Account number',
  'accountsPage.swift' : 'SWIFT/BIC',
  'accountsPage.lastUpdate': 'Last updated',
  'accountsPage.addTransaction': 'Add Transaction',
  'accountsPage.uploadTransaction': 'Upload Transaction',
  'accountsPage.updateAt': 'Updated at',
  'accountsPage.name': 'Name',
  'accountsPage.nameRequired': 'Name is required',
  'accountsPage.nature': 'Nature',
  'accountsPage.natureRequired': 'Nature is required',
  'accountsPage.source': 'Input method',
  'accountsPage.sourceRequired': 'Input method is required',
  'accountsPage.currency': 'Currency',
  'accountsPage.currencyRequired': 'Currency is required',
  'accountsPage.initialBalance': 'Initial Balance',
  'accountsPage.initialDate': 'Choose date of the Initial Balance',
  'accountsPage.accountDetail': 'Account Details',
  'accountsPage.iban': 'IBAN',
  'accountsPage.accountNumber': 'Account Number',
  'accountsPage.bic': 'BIC',
  'accountsPage.ibanOrAccountNumberRequired': 'IBAN or Account Number is required',
  'accountPage.message.updateSuccess' : 'The account data was successfully updated',
  'accountPage.message.updateError' : 'The update of the account data was unsuccessful',
  'accountPage.message.createSuccess' : 'Your account is successfully created',
  'accountPage.message.createError' : 'The creation of the account was unsuccessful',
  'accountPage.nature.ACCOUNT': 'Current account',
  'accountPage.nature.CASH': 'Cash',
  'accountPage.nature.CREDIT': 'Credit',
  'accountPage.nature.CREDIT_CARD': 'Credit Card',
  'accountPage.nature.BUILDING_SOCIETY': 'Building Society',
  'accountPage.nature.INVESTMENT': 'Investment',
  'accountPage.nature.ASSET': 'Asset',
  'accountPage.source.MANUAL': 'Manual',
  'accountPage.source.FILE': 'File',
  'accountPage.source.TRANSFER': 'Transfer',
  'accountsPage.invalidPattern': 'Invalid number format',
  'accountPage.remove.success' : 'The account was successfully deleted',
  'accountPage.remove.fail' : 'An error occurred during the deletion of the account',
  'accountPage.removeConfirmation.message' : 'Do you really want to delete this account?',
  'accountPage.ibanAccountNumber.waningMessage': 'Services like automatic labeling perform better if you give your account number or IBAN.',
  'accountsPage.generalInformationNotice': 'These settings can not be changed later. Select your choice carefully!',
  'accountsPage.accountDetailNotice': 'You can enter these data later, but once you have saved them you can not modify them.',

  // profile detail page
  'profilePage.name' : 'Name',
  'profilePage.nameRequired' : 'Name is Required',
  'profilePage.currency' : 'Currency',
  'profilePage.currencyRequired' : 'Currency is Required',
  'profilePage.message.getFail' : 'Getting profile information was unsuccessful',
  'profilePage.message.updateSuccess' : 'Your profile was successfully updated',
  'profilePage.message.updateFail' : 'The update of your profile was unsuccessful',
  'profilePage.profileImage' : 'Profile picture',
  'profilePage.profileImageSelect' : 'Please select your profile picture',
  'profilePage.selectFileToUpload': 'Select file to upload',
  'profilePage.cropImage': 'Crop image',
  'profilePage.preview': 'Preview',

  // transaction page
  'transactionPage.note' : 'Note',
  'transactionPage.comment' : 'Description',
  'transactionPage.selectLabelOfCategory': 'Select label of category',
  'transactionPage.direction': 'Direction',
  'transactionPage.nameOfPartner': 'Name of partner',
  'transactionPage.amount': 'Amount',
  'transactionPage.currency': 'Currency',
  'transactionPage.dateOfTransaction': 'Date of transaction',
  'transactionPage.regularFlag': 'Regularity',
  'transactionPage.description': 'Description',
  'transactionPage.ibanOfPartner': 'IBAN of partner',
  'transactionPage.accountBicOfPartner': 'BIC of the bank of the partner',
  'transactionPage.accountNumberOfThePartner': 'Account number of the partner',
  'transactionPage.cardNumber': 'Card Number',
  'transactionPage.originalAmount': 'Original Amount',
  'transactionPage.originalCurrency': 'Original Currency',
  'transactionPage.dateOfBooking': 'Date of Booking',
  'currency.required': 'Currency is required',
  'amount.required': 'Amount is required',
  'amount.min': 'Negative amount is not allowed',
  'nameOfPartner.required': 'Name of partner is required',
  'dateOfTransaction.required': 'Date of transaction is required',
  'transactionPage.generalInformation': 'General Information',
  'transactionPage.accountInformation': 'Account Information',
  'transactionPage.message.createSuccess': 'The transaction was created successfully',
  'transactionPage.message.createFail': 'The creation of the transaction failed',
  'transactionPage.message.getAccountFail': 'Getting account information failed',
  'transactionPage.message.getLabelFail': 'Getting label information failed',
  'transactionPage.message.getTransactionFail': 'Getting transaction information failed',
  'transactionPage.message.updateSuccess': 'The transaction was updated successfully',
  'transactionPage.message.updateFail': 'The update of the transaction failed',
  'transactionPage.message.getBalanceFail': 'Getting balance information failed',
  'transactionPage.message.getDefaultLabelCategory': 'Getting default label category information failed',
  'transactionPage.message.getListOfTransactionFail': 'Getting the list of transactions failed',
  'transactionPage.regularFlag.NO': 'No',
  'transactionPage.regularFlag.WEEKLY': 'Weekly',
  'transactionPage.regularFlag.MONTHLY': 'Monthly',
  'transactionPage.regularFlag.EVERY_SECOND_MONTH': 'Every second month',
  'transactionPage.regularFlag.QUARTERLY': 'Quarterly',
  'transactionPage.regularFlag.EVERY_HALF_YEAR': 'Every half year',
  'transactionPage.regularFlag.YEARLY': 'Yearly',
  'transactionPage.regularFlag.OTHER': 'Other',
  'transactionPage.addNewTransaction': 'Add a new transaction',
  'transactionPage.updateTransaction': 'Update the transaction',
  'transactionPage.removeTransaction.success': 'The transaction was deleted successfully',
  'transactionPage.removeTransaction.fail': 'The deletion of the transaction failed',
  'transactionPage.removeConfirmation.message': 'Are you sure, you want to delete this transaction?',

  // UPLOAD-CSV SETTING PAGE
  'CSV-LOADER.FILE': 'File',
  'CSV-LOADER.FIRST-STEP.TITLE': 'Define a new file structure',
  'CSV-LOADER.FIRST-STEP.SELECT-CSV': 'Select a CSV file',
  'CSV-LOADER.FIRST-STEP.PDF': 'PDF file only',
  'CSV-LOADER.FIRST-STEP.PLEASE-SELECT': 'Please select a file',
  'File.required': 'File is required',
  'CSV-LOADER.SECOND-STEP.TITLE': 'Setup loader',
  'CSV-LOADER.SECOND-STEP.PREVIEW': 'Preview',
  'CSV-LOADER.SECOND-STEP.CONFIGURATION': 'Configuration',
  'CSV-LOADER.SECOND-STEP.DELIMITER': 'Delimiter',
  'CSV-LOADER.SECOND-STEP.QUOTE-CHARACTER': 'Quote Character',
  'CSV-LOADER.SECOND-STEP.CHARACTER-SET': 'Character Set',
  'CSV-LOADER.SECOND-STEP.HEADER-ROW': 'Select Number of header rows',
  'numberOfHeaderLines.min.0': 'Number of header cannot be less than 0',
  'CSV-LOADER.SECOND-STEP.TRY-PARSE': 'Try to parse',
  'CSV-LOADER.THIRD-STEP.TITLE': 'Preview of parsed data',
  'CSV-LOADER.THIRD-STEP.SELECT_COLUMN_INSTRUCTIONS': 'Please select at least the following columns:',
  'dateFormat.required': 'Date Format is required',
  'numberFormat.required': 'Number Format is required',
  'CSV-LOADER.FOURTH-STEP.STAGE-DATA': 'Stage Data',
  'CSV-LOADER.SECOND-STEP.SELECT-REQUIRED-COLUMNS': 'Please select required columns:',

  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SEMICOLON': 'Semicolon',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COMMA': 'Comma',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COLON': 'Colon',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.PIPE': 'Pipe',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.HASHMARK': 'Hashmark',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SPACE': 'Space',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.TAB': 'Tab',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.NONE': 'None',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.DOUBLE-QUOTE': 'Double Quote',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.SINGLE-QUOTE': 'Single Quote',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.BACKSLASH' : 'Backslash',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.DOUBLE-QUOTE' : 'Double Quote',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.SINGLE-QUOTE' : 'Single Quote',
  'FIELDS.TRANSACTION.IGNORE': 'Ignore this column',
  'FIELDS.TRANSACTION.amount': 'Amount',
  'FIELDS.TRANSACTION.currency': 'Currency',
  'FIELDS.TRANSACTION.dateOfTransaction': 'Date of Transaction',
  'FIELDS.TRANSACTION.dateOfBooking': 'Date of Booking',
  'FIELDS.TRANSACTION.amountIn': 'Amount In',
  'FIELDS.TRANSACTION.amountOut': 'Amount Out',
  'FIELDS.TRANSACTION.directionFlag': 'Direction',
  'FIELDS.TRANSACTION.namePartner': 'Name of the Partner',
  'FIELDS.TRANSACTION.note': 'Note',
  'FIELDS.TRANSACTION.ibanPartner': 'IBAN of the Partner',
  'FIELDS.TRANSACTION.accountBicPartner': 'BIC of the Partner',
  'FIELDS.TRANSACTION.accountNumberPartner': 'Account Number Partner',
  'FIELDS.TRANSACTION.cardNo': 'Card Number',
  'FIELDS.TRANSACTION.amountOrig': 'Original Amount',
  'FIELDS.TRANSACTION.currencyOrig': 'Original Currency',
  'FIELDS.TRANSACTION.typeOfTransaction': 'Type of Transaction',
  'FIELDS.TRANSACTION.description': 'Description',
  'FIELDS.TRANSACTION.externalId': 'External Id',
  'CSV-LOADER.MAP-COLUMNS.INCOME.LABEL' : 'Income Mark#',
  'CSV-LOADER.MAP-COLUMNS.EXPENSE.LABEL' : 'Expense Mark#',
  'CSV-LOADER.MAP-COLUMNS.USE-SIGN.LABEL': 'Use the sign of amount#',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.CUSTOM.LABEL': 'Custom format#',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.LABEL': 'Please select date format',
  'CSV-LOADER.MAP-COLUMNS.NUMBER-FORMAT.LABEL' : 'Please select number format',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.SUGGEST-BUTTON': 'Suggest formats',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SEPARATOR.LABEL': 'Decimal separator',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SUGGEST-BUTTON': 'Guess decimal separator',
  'CSV-LOADER.MAP-COLUMNS.ERROR.NO-DATE-COLUMN': 'Please choose "Date of Transaction" column',
  'CSV-LOADER.SAVE.TITLE': 'Upload data to the account',
  'CSV-LOADER.SAVE.RESULT': 'Upload Result',
  'CSV-LOADER.SAVE.RESULT.SUCCESS': 'You have uploaded transactions successfully.',
  'CSV-LOADER.SAVE.RESULT.FAIL': 'The uploaded of the transactions failed.',
  'CSV-LOADER.SAVE.INVALID-AMOUNT': 'Invalid amount',
  'CSV-LOADER.SAVE.SAVE-SETTING-FAIL': 'The saving of settings failed',
  'CSV-LOADER.SAVE.SAVE-STAGE-DATA-FAIL': 'The saving of staged data failed',
  'CSV-LOADER.SAVE.CANCEL.SUCCESS': 'You have canceled to upload transactions',
  'CSV-LOADER.OVERLAPPING.MODAL.TITLE': 'Overlapping Transactions',
  'CSV-LOADER.OVERLAPPING.MODAL.SUBTITLE': 'An overlap detected between the time intervals of the previously recorded transactions and the current set of transactions.',
  'CSV-LOADER.OVERLAPPING.MODAL.SELECT': 'Please select your actions:',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Append': 'Keep the already loaded transactions and append the difference.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Substitute': 'Substitute the formerly loaded transactions with the currently loaded new ones.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Merge': 'Merge the former and the current uploads, keep the transactions of both.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Cancel': 'Cancel the upload.',

  // first-time-login
  'firstTimeLoginPage.title' : 'Welcome to Continuity',
  'firstTimeLoginPage.subTitle': 'Please create your first account',

  // menu items
  'menu.item.Overview': 'Overview',
  'menu.item.AccountList': 'Accounts',
  'menu.item.Profile': 'Profile',
  'menu.item.Analytics': 'Analytics',
  'menu.item.signOut': 'Sign Out',
  'menu.item.deleteMyAccount': 'Delete my account',
  'menu.item.Cash-flow': 'Cash-flow',
  'menu.item.inviteNewUser': 'Invite new user',
  'menu.item.help': 'Help',

  // enum icons
  'enum.name.ACCOMODATION' : 'Accommodation',
  'enum.name.TRANSPORT' : 'Transport',
  'enum.name.MEALS' : 'Meals',
  'enum.name.CLOTHING' : 'Clothing',
  'enum.name.ALCOHOL_TOBACCO' : 'Alcohol & Tobacco',
  'enum.name.BANK' : 'Bank',
  'enum.name.EDUCATION' : 'Education',
  'enum.name.ENTERTAINMENT' : 'Entertainment',
  'enum.name.GIFT' : 'Gift',
  'enum.name.HOBBY' : 'Hobby',
  'enum.name.SPORT' : 'Sport',
  'enum.name.HOLIDAY' : 'Holiday',
  'enum.name.HYGIENE_HOUSEHOLD' : 'Hygiene & Household',
  'enum.name.TELECOMMUNICATION' : 'Telecommunication',
  'enum.name.HEALTH_CARE' : 'Health care',
  'enum.name.OTHER' : 'Other',
  'enum.name.SPOUSE' : 'Spouse',
  'enum.name.MYSELF' : 'Myself',
  'enum.name.MOM' : 'Mom',
  'enum.name.DAD' : 'Dad',
  'enum.name.FAMILY' : 'Family',
  'enum.name.FRIENDS' : 'Friends',
  'enum.name.IN-LAWS' : 'Wide family',
  'enum.name.CHILD1' : 'Child1',
  'enum.name.CHILD2' : 'Child2',
  'enum.name.CHILD3' : 'Child3',
  'enum.name.CHILD4' : 'Child4',
  'enum.name.CHEMICALS' : 'Chemicals',
  'enum.name.ENERGY' : 'Energy',
  'enum.name.EQUIPMENT' : 'Equipment',
  'enum.name.FIXTURE' : 'Fixture',
  'enum.name.FOOD' : 'Food',
  'enum.name.GADGET' : 'Gadget',
  'enum.name.INSURANCE' : 'Insurance',
  'enum.name.MAINTENANCE' : 'Maintenance',
  'enum.name.PENALTIES' : 'Penalties',
  'enum.name.RENTAL' : 'Rental',
  'enum.name.TAX' : 'Tax',
  'enum.name.TICKET_SERVICE' : 'Ticket / Service',
  'enum.name.EMPLOYER' : 'Employer',
  'enum.name.CLIENT' : 'Client',
  'enum.name.EARNING_FROM_INVESTMENT' : 'Earning from investment',
  'enum.name. RENTAL' : 'Rental',
  'enum.name.SALE_OF_ASSET' : 'Sale of asset',
  'enum.name.PET' : 'Pet',
  'enum.name.STATE' : 'State',
  'enum.name.TRANSFER' : 'Transfer',
  'enum.name.INCOME_LABELED_AS_TRANSFER' : 'Counterpart missing',
  'enum.name.EXPENSE_LABELED_AS_TRANSFER' : 'Counterpart missing',
  'enum.name.SUPPORT' : 'Support',
  'enum.name.SALARY' : 'Salary',
  'enum.category.TRANSFER' : 'Transfer',
  'enum.category.TITLE': 'Title',
  'enum.category.PAYING': 'Paying',
  'enum.category.PURPOSE': 'Purpose',
  'enum.category.BENEFICIARY': 'Beneficiary',
  'enum.category.TOOL_SERVICE': 'Tool & Service',
  'enum.category.THIS_MONTH': 'This month',
  'enum.category.LAST_MONTH': 'Last month',
  'enum.category.THIS_YEAR': 'This year',
  'enum.category.LAST_YEAR': 'Last year',
  'enum.category.THIS_WEEK': 'This week',
  'enum.category.LAST_WEEK': 'Last week',
  'enum.category.TODAY': 'Today',
  'enum.category.CUSTOM': 'Custom',

  // direction flag
  'direction.flag.INCOME': 'Income',
  'direction.flag.EXPENSE': 'Expense',
  'direction.flag.OUT_GOING_TRANSFER': 'Outgoing transfer',
  'direction.flag.INCOMING_TRANSFER': 'Incoming transfer',

  // analytics page
  'analyticsPage.incomeChart': 'Income Chart',
  'analyticsPage.expenseChart': 'Expense Chart',
  'analyticsPage.noIncomeData': 'No income data available',
  'analyticsPage.noExpenseData': 'No expense data available',
  'analyticsPage.getLabelFail': 'Get list label by category unsuccessfully',
  'analyticsPage.getChartDataFail': 'Get chart data unsuccessfully',
  'analyticsPage.totalAmount': 'Total amount',
  'analyticsPage.selectIncomeCategory': 'Select Income Category',
  'analyticsPage.selectExpenseCategory': 'Select Expense Category',
  'analyticsPage.selectTimePeriod': 'Select Time Period',
  'analyticsPage.from': 'From',
  'analyticsPage.to': 'To',

  // cash-flow page
  'cash-flow.selectYourAccount': 'Select your account',
  'cash-flow.allAccountOfYourProfile': 'All accounts of your profile',
  'cash-flow.chartHeader': 'Cash-flow of your account',
  'cash-flow.subtextOfAnAccount': 'account: ',
  'cash-flow.subtextOfAllAccount': 'All accounts of your profile',
  'cash-flow.warningMessage': 'The cash-flow calculation is available only for one year in advance.',

  // invite-users page
  'inviteUserPage.email': 'Email',
  'inviteUserPage.message': 'Message',
  'inviteUserPage.emailRequired': 'Email is required',
  'inviteUserPage.emailInvalid': 'Email must be a valid email address',
  'inviteUserPage.messageRequired': 'The message is required',
  'inviteUsersPage.message.sendSuccess': 'Successful invitation',
  'inviteUsersPage.message.sendFail': 'The invitation failed',
  'inviteUsersPage.message.defaultMessage': 'Hi, <br/> I use Continuity to control my financials and to plan my cash-flow. <br/> I suggest you to try it.',
  'inviteUsersPage.email.subject': 'invited you to use Continuity',
  'inviteUsersPage.email.button': 'Register to Continuity',

  // error message
  'error.INITIAL_BALANCE_IS_LATER' : 'Warning! The date of the transaction is before the date of the initial balance. Modify the date of your initial balance!',
  'error.FAILED' : 'The calculation of balances failed',

  // tool tip
  // add account page
  'toolTip.addAccount.name': 'Give a name to your account. It can be different from its name at your bank. Just a name you will remember.',
  'toolTip.addAccount.nature': 'The data processing is different by nature of the account.',
  'toolTip.addAccount.source': 'You can upload your transactions from a file downloaded from your netbank. It is recommended to enter the cash transaction data as soon as possible in the mobile version of Continuity.',
  'toolTip.addAccount.currency': 'If you have more than one currency then you need an account for each!',
  'toolTip.addAccount.dateOfInitialBalance': 'Select a date of which you know the end-of-day balance!',
  'toolTip.addAccount.initialBalance': 'Enter the end-of-day balance. It is optional, but needed for the correct balance calculation.',
  'toolTip.addAccount.iban': 'The international bank account number. Starts with the two letter country code. Optional, if you fill it, the Continuity will identify the transfers between your accounts easier.',
  'toolTip.addAccount.accountNumber': 'Domestic bank account number. Optional, if you fill it, the Continuity will identify the transfers between your accounts easier.',
  'toolTip.addAccount.bic': 'The international identifier of your bank. Also known as Swift code. Optional, if you fill it, the Continuity will identify the transfers between your accounts easier.',
  'toolTip.addAccount.saveBtn': 'You can modify the account data after saving, but when the account already has a transaction then only the name and the initial balance can be modified.',
  'toolTip.addAccount.resetBtn': 'Delete the previously entered data.',

  // edit account page
  'toolTip.editAccount.name': 'The name of your account - you can change it whenever you want.',
  'toolTip.editAccount.nature': 'If the account has a transaction then the nature of the account is fixed.',
  'toolTip.editAccount.source': 'If the account has a transaction then the source of the account is fixed.',
  'toolTip.editAccount.currency': 'If the account has a transaction then the currency of the account is fixed.',
  'toolTip.editAccount.dateOfInitialBalance': 'Select a date of which you know the end-of-day balance!',
  'toolTip.editAccount.initialBalance': 'Enter the end-of-day balance. It is optional, but needed for the correct balance calculation.',
  'toolTip.editAccount.iban': 'If the account has a transaction then the IBAN of the account is fixed.',
  'toolTip.editAccount.accountNumber': 'If the account has a transaction then the account number of the account is fixed.',
  'toolTip.editAccount.bic': 'If the account has a transaction then the BIC of the account is fixed.',
  'toolTip.editAccount.saveBtn': 'Update the account with the currently entered data.',
  'toolTip.editAccount.cancelBtn': 'Restore the previous account data.',
  'toolTip.editAccount.deleteBtn': 'Delete the account with all transactions and settings of it.',

  // add transaction page
  'toolTip.addTransaction.direction': 'The direction of the transaction sets its sign. If the transaction is a correction entry then you can change the sign of it later by reopening the transaction.',
  'toolTip.addTransaction.dateOfTransaction': 'Don\'t forget to set, if the transaction is in the past!',
  'toolTip.addTransaction.amount': 'Enter the amount without sign!',
  'toolTip.addTransaction.namePartner': 'The company or the person whom you have paid or who paid you.',
  'toolTip.addTransaction.regularFlag': 'The Continuity calculates a one year cash-flow plan based on the regular transactions. If the similar transaction was already marked as regular earlier, then you don\'t mark them again, because it can cause duplicate values in the plan.',
  'toolTip.addTransaction.description': 'Information about the transaction which is important for you.',
  'toolTip.addTransaction.note': 'Your note.',
  'toolTip.addTransaction.saveBtn': 'After you have saved the transaction you can correct it in case of a mistake.',
  'toolTip.addTransaction.cancelBtn': 'Delete the entered data.',

  // the following seven fields do not appear on the data entry form
  'toolTip.addTransaction.ibanPartner': 'The international bank account number of your partner. Starts with the two letter country code.',
  'toolTip.addTransaction.accountBicPartner': 'The international identifier of the bank of your partner. Also known as Swift code.',
  'toolTip.addTransaction.accountNumberPartner': 'The domestic bank account number of the partner.',
  'toolTip.addTransaction.cardNo': 'The number of the payment card used for the transaction.',
  'toolTip.addTransaction.amountOrig': 'The original amount if the currency of the transaction is different from the currency of the account.',
  'toolTip.addTransaction.currency': 'The currency of the transaction if it is different from the currency of the account.',
  'toolTip.addTransaction.dateOfBooking': 'The date when your bank or financial service provider booked the transaction.',

  // edit transaction page
  'toolTip.editTransaction.directionFlag': 'You can mark a positive transaction as an expense in case of a correction entry. For example a refund from an overpaid bill. (This will decrease your costs instead of increasing your incomes.)',
  'toolTip.editTransaction.dateOfTransaction': 'When the payment was done. If loaded from file, then you can not modify manually.',
  'toolTip.editTransaction.amount': 'The amount in the currency of the account. If loaded from file, then you can not modify manually.',
  'toolTip.editTransaction.namePartner': 'The company or the person whom you have paid or who paid you. If loaded from file, then you can not modify manually.',
  'toolTip.editTransaction.regularFlag': 'The Continuity calculates a one year cash-flow plan based on the regular transactions. Please mark only the last instance of the similar transactions.',
  'toolTip.editTransaction.description': 'If loaded from file, then you can not modify manually.',
  'toolTip.editTransaction.note': 'If loaded from file, then you can not modify manually.',
  'toolTip.editTransaction.ibanPartner': 'Loaded from file, you can not modify manually.',
  'toolTip.editTransaction.accountBicPartner': 'Loaded from file, you can not modify manually.',
  'toolTip.editTransaction.accountNumberPartner': 'Loaded from file, you can not modify manually.',
  'toolTip.editTransaction.cardNo': 'The number of the payment card used for the transaction. Loaded from file, you can not modify manually.',
  'toolTip.editTransaction.amountOrig': 'The original amount if the currency of the transaction is different from the currency of the account. Loaded from file, you can not modify manually.',
  'toolTip.editTransaction.currencyOrig': 'The currency of the transaction if it is different from the currency of the account. Loaded from file, you can not modify manually.',
  'toolTip.editTransaction.dateOfBooking': 'The date when your bank or financial service provider booked the transaction. Loaded from file, you can not modify manually.',
  'toolTip.editTransaction.saveBtn': 'Save changes.',
  'toolTip.editTransaction.cancelBtn': 'Restore the previous data.',
  'toolTip.editTransaction.removeBtn': 'You can delete the transaction if it is a duplication.',

  // account upload csv page
  'toolTip.accountUpload.fileName': 'The following settings must be done only once for a file type. Continuity will remember the settings next time when you upload a file to this account.',
  'toolTip.accountUpload.delimiter': 'Continuity detects the separator characters. Change it only if you are sure.',
  'toolTip.accountUpload.quote': 'Continuity detects the quotation marks around the data. Change it only if you are sure.',
  'toolTip.accountUpload.charset': 'Continuity detects the character set of the file. Change it only if you are sure.',
  'toolTip.accountUpload.numberOfHeaderLines': 'Set the number of the lines at the beginning of the file, which do not contain data.',
  'toolTip.accountUpload.selectColumn': 'Helps the automatic categorisation, if you upload not only the obligatory fields but the note and the name-, account number- and IBAN of the partner.',
  'toolTip.accountUpload.dateFormat': 'Observe the order and the spelling of the year, month and day, and take care of the separator characters. Select the date format!',
  'toolTip.accountUpload.numberFormat': 'Observe the decimal and thousand separator characters in the amount field. Select the number format!',
  'toolTip.accountUpload.backBtn': 'Back to select a file.',
  'toolTip.accountUpload.nextBtn': 'Upload the selected file.',
  'toolTip.accountUpload.correctTheSettingBtn': 'If you are not satisfied with the preview, you can step back here.',
  'toolTip.accountUpload.saveAndFinishBtn': 'If you are satisfied with the preview then finish the upload.',

  // profile page
  'toolTip.profileDetail.name': 'Give a name to your profile!',
  'toolTip.profileDetail.currency': 'Choose the currency of your profile! If you have more than one currencies the total balance will be displayed in this currency in the overview.',
  'toolTip.profileDetail.attachFile': 'With a picture you can personalize your profile.',
  'toolTip.profileDetail.removeBtn': 'Here you can initiate the deletion of your profile. Once your profile is deleted, you will not be able to re-register with your previously used email address.',

  // sent new user page
  'toolTip.inviteUsers.email': 'The email address of the invitee. You will receive your reward only if the invitee uses Continuity with this email.',
  'toolTip.inviteUsers.message': 'Make the invitation personal.',

  // transaction filter modal page
  'transactionFilterModal.title': 'List transactions of the label: ',
  'transactionFilterModal.accountName': 'Account Name',
  'transactionFilterModal.dateOfTransaction': 'Date of transaction',
  'transactionFilterModal.amount': 'Amount',
  'transactionFilterModal.descAndNote': 'Description/Note',
  'transactionFilterModal.partnerName': 'Name of the partner',

  // delete my user account modal
  'deleteUserAccount.confirmMessage1': 'Are you sure you want to delete your account?',
  'deleteUserAccount.confirmMessage2': 'Your data can not be restored after the deletion, and you can not sign in later with the same credentials.\n Are you sure?',
  'deleteUserAccount.deleteSuccessfully': 'Your user account was successfully deleted.',
  'deleteUserAccount.deleteUnsuccessfully': 'Deleting your account was unsuccessful. Please contact our customer support.',

  // help popup
  'helpPopup.step0.title': 'Overview',
  'helpPopup.step0.intro': 'The \"Total Balance\" is the sum of the balances of your accounts. It is diplayed in the currency of your profile. Here you see how much money you have. The balances of your accounts in a currency different from your profile will be converted to the currency of the profile at the last exchange rate. Below you can see the current balance of all your accounts in the currency of the account. The current balances only show the facts, not the plans. In that way only the result of the transactions entered manually or uploaded from a file are included in the balances, the planned transactions are not included. By the way you can  change the currency of your profile under the \"Profile\" menu.',
  'helpPopup.step1.title': 'Accounts Page',
  'helpPopup.step1.intro': 'Here you can add new accounts to your profile and add or edit information to your existing accounts. But what is an account? This term means anything where you can keep money or other assets. So, a building society deposit, an investment account, and even your wallet are \"accounts\". And if there are more currencies in your wallet, then it is recommended to add it as a separate account, because an account can only have one currency. The specified properties of your accounts are considered during the calculations, e.g. when looking for the counter transaction of a transfer between your accounts. Therefore, the account information cannot be changed afterwards. The name of the account and the opening balance information are exceptions. If you entered any information incorrectly, you should delete that account and re-create it. Hopefully, you’ll notice such an error soon and you won’t lose a lot of data when deleting and recreating the account.',
  'helpPopup.step2.title': 'Profile Page',
  'helpPopup.step2.intro': 'Here you can enter or change your profile\'s name, currency, language and avatar. If can also delete your profile if you want, but think twice! We will miss you and you will not be able to re-register with the same email address later.',
  'helpPopup.step3.title': 'Analytics Page',
  'helpPopup.step3.intro': 'It shows you how much you have spent on certain purposes and shows the sources of your revenues. If you’ve been using the system for a while, you should analyze these charts. If you have too much revenue or cost in the \"other\" category, you should take a little more care of categorization.',
  'helpPopup.step3.imageUrl': 'https://live.staticflickr.com/65535/51930070200_95da6a62ff_c.jpg',
  'helpPopup.step4.title': 'Cash-flow Page',
  'helpPopup.step4.intro': 'A cash flow plan is created for you based on the transactions you mark as regular or perceived as regular by the system. You should check this orderly, both on an account basis and overall. If the plan ever shows a negative balance on an account or overall, you can still correct it in time. For example, you can make a transfer to the account where your money will run out, or you can delay a spending, or you can request an advance on one of your future earnings.',
  'helpPopup.step4.imageUrl': 'https://live.staticflickr.com/65535/51928480722_346507237f_b.jpg',

  // first time login initial help
  'firstTimeLogin.popup.name': 'Enter the name of your first account! You can choose a custom name. The only point is that you should recognize the account by its name. This name will be used in the system hereafter. It is recommended to add your frequently used accounts first.',
  'firstTimeLogin.popup.nature': 'Select the nature of the account! Generally everyone has at least one wallet for cash and a current account at a bank. Start with these!',
  'firstTimeLogin.popup.source': 'Select the input method for the account. You must enter cash transactions manually. You can also upload banking transactions using a file downloaded from your netbank.',
  'firstTimeLogin.popup.currency': 'Select the currency of the account. The currency of your accounts can be different, but an account can only have one currency, which you can\'t change later. You must add a new account for a new currency.',
};
