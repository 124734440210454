import { Component, Input, OnInit } from '@angular/core';
import { AccountModel } from '../account.model';
import { StringUtils } from '../../shared/String.config';
import { BasePage } from '../../shared/BasePage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bank-card-info',
  templateUrl: './account-card-info.component.html',
  styleUrls: ['./account-card-info.component.css']
})
export class AccountCardInfoComponent extends BasePage implements OnInit {
  constructor(private router: Router, ) {
    super();
  }
  @Input() public accountInfo: AccountModel;
  @Input() public showCardDetail: boolean;
  ngOnInit() {}

  tooglePanel() {
    this.showCardDetail = !this.showCardDetail;
  }

  isNullValue(value) {
    return !value || value.trim() === '';
  }

  moveToPage(page: string) {
    this.router.navigate([`/accounts/${this.accountInfo.id}/transactions/${page}`]);
  }
}
