export const LANG_IT_TRANS = {
  // global
  'button.save': 'Salva',
  'button.cancel': 'Annulla',
  'button.ok': 'OK',
  'button.close': 'Chiuso',
  'button.back': 'Indietro',
  'button.next': 'Avanti',
  'button.remove': 'Rimuovi',
  'button.yes': 'Sì',
  'button.no': 'No',
  'button.edit': 'Modifica',
  'button.detail': 'Dettagli',
  'button.saveAndFinish': 'Salva e termina',
  'button.correctTheSetting': 'Correggere l\'impostazione',
  'generic.select.other': 'Altro...',
  'session.isExpired': 'La tua sessione è scaduta e devi entrare con il tuo nome utente e password di nuovo.',
  'button.select': 'Scegli',
  'button.sendInvitation': '##Send Invitation',

  // Login page
  'loginPage.title' : 'L\'accesso a Continuity',
  'loginPage.email' : 'Indirizzo e-mail',
  'loginPage.password' : 'Password',
  'loginPage.remember-me' : 'Ricordami',
  'loginPage.signIn' : 'Accedi',
  'loginPage.forgotPassword' : 'Hai dimenticato la tua password?',
  'loginPage.newToContinuity' : 'È la prima volta che usa la Comunity?',
  'loginPage.createAccount' : 'Iscriviti',
  'loginPage.or' : 'o',
  'loginPage.signInWithFacebook' : 'Accedi con Facebook',
  'loginPage.signInWithGoogle' : 'Accedi con Google',
  'loginPage.message.invalidEmailPassword': 'Indirizzo e-mail o password non validi',
  'loginPage.message.loginFail': 'Accesso non riuscito',
  'loginPage.message.emptyField': 'Bisogna inserire un indirizzo e-mail e un password',

  // Register page
  'registerPage.title': 'Crea an account',
  'registerPage.nickName': 'Nome utente',
  'registerPage.email': 'Indirizzo e-mail',
  'registerPage.password': 'Password',
  'registerPage.confirmPassword': 'Conferma la password',
  'registerPage.language': 'Lingua',
  'registerPage.signUp': 'Iscriviti',
  'registerPage.nickNameRequired': 'Inserisci il nome utente',
  'registerPage.emailRequired': 'Inserisci l\'indirizzo e-mail',
  'registerPage.emailInvalid': 'Indirizzo e-mail non valido',
  'registerPage.passwordRequired': 'Inserisci la password',
  'registerPage.passwordInvalid': 'Usi una combinazione di almeno 6 caratteri',
  'registerPage.confirmPasswordRequired': 'È necessario confermare la password',
  'registerPage.confirmPasswordNotMatched': 'Le password non sono uguali',
  'registerPage.languageRequired': 'Scegli la lingua',
  'registerPage.message.success': 'Registrazione riuscito. Per favore, controlla l\'indirizzo e-mail e conferma la registrazione!',
  'registerPage.message.fail': 'Registrazione fallito.',
  'registerPage.agreed.fail': 'Puoi utilizzare Continuity solo se hai accettato i nostri Termini di servizio e la nostra Informativa sulla privacy.',
  'registerPage.readMessage': 'Ho letto e accetto la',
  'registerPage.termOfService': 'Termini di servizio',
  'registerPage.privacyPolicy': 'Politica sulla riservatezza',

  // active user page
  'activeAccountPage.verifySuccess': 'È riuscito confermare la tua registrazione.',
  'activeAccountPage.verifyFail': 'Non è riuscito confermare la tua registrazione.',

  // Accounts page
  'accountsPage.header.currentBalance': 'Bilancio corrente',
  'accountsPage.header.totalBalance': 'Totale di bilancio',
  'accountsPage.generalInformation' : 'Informazioni generali',
  'accountsPage.giroNumber' : 'Numero di conto bancario',
  'accountsPage.swift' : 'Codice SWIFT/BIC',
  'accountsPage.lastUpdate': 'L\'ultimo aggiornamento',
  'accountsPage.addTransaction': 'Aggiungere una transazione',
  'accountsPage.uploadTransaction': 'Caricare le transazioni',
  'accountsPage.updateAt': 'Modificato',
  'accountsPage.name': 'Nome',
  'accountsPage.nameRequired': 'Inserisci il nome',
  'accountsPage.nature': 'Portafoglio o conto?',
  'accountsPage.natureRequired': 'È obbligatorio inserire: portafoglio o conto?',
  'accountsPage.source': 'Fonte dei dati',
  'accountsPage.sourceRequired': 'Scegli la fonte dei dati',
  'accountsPage.currency': 'Valuta',
  'accountsPage.currencyRequired': 'Scegli la valuta',
  'accountsPage.initialBalance': 'Saldo iniziale',
  'accountsPage.initialDate': 'Scegli la data del saldo iniziale',
  'accountsPage.accountDetail': 'Informazioni dettagliate sul conto',
  'accountsPage.iban': 'IBAN (conto bancario internazionale)',
  'accountsPage.accountNumber': 'Numero di conto bancario',
  'accountsPage.bic': 'Codice BIC',
  'accountsPage.ibanOrAccountNumberRequired': 'È obbligatorio inserire almeno IBAN o numero di conto bancario.',
  'accountPage.message.updateSuccess' : 'È riuscito a modificare le informazioni sul conto',
  'accountPage.message.updateError' : 'Non è riuscito a modificare le informazioni sul conto',
  'accountPage.message.createSuccess' : 'È riuscito ad aggiungere il conto',
  'accountPage.message.createError' : 'Non è riuscito ad aggiungere il conto',
  'accountPage.nature.ACCOUNT': 'Conto',
  'accountPage.nature.CASH': 'Contanti',
  'accountPage.nature.CREDIT': 'Credito',
  'accountPage.nature.CREDIT_CARD': 'Carta di credito',
  'accountPage.nature.BUILDING_SOCIETY': 'Società edile', //#
  'accountPage.nature.INVESTMENT': 'Investimento', //#
  'accountPage.nature.ASSET': 'Bene', //#
  'accountPage.source.MANUAL': 'Manuale',
  'accountPage.source.FILE': 'File',
  'accountPage.source.TRANSFER': 'Trasferimento', //#
  'accountsPage.invalidPattern': 'Formato numero non valido',
  'accountPage.remove.success' : 'L\'account è stato eliminato con successo',
  'accountPage.remove.fail' : 'Si è verificato un errore durante l\'eliminazione dell\'account',
  'accountPage.removeConfirmation.message' : 'Vuoi davvero eliminare questo account?',
  'accountPage.ibanAccountNumber.waningMessage': 'Servizi come l\'etichettatura automatica funzionano meglio se fornisci il tuo numero di conto o IBAN.',
  'accountsPage.generalInformationNotice': 'Queste impostazioni non possono essere modificate in seguito. Scegli con cura la tua scelta!', //#
  'accountsPage.accountDetailNotice': 'Puoi inserire questi dati in un secondo momento, ma una volta salvati non puoi modificarli.', //#

  // profile detail page
  'profilePage.name' : 'Nome',
  'profilePage.nameRequired' : 'Inserisci il nome',
  'profilePage.currency' : 'Valuta',
  'profilePage.currencyRequired' : 'Inserisci la valuta',
  'profilePage.message.getFail' : 'Non è riuscito a caricare le informazioni del profilo.',
  'profilePage.message.updateSuccess' : 'È riuscito a modificare le informazioni del profilo',
  'profilePage.message.updateFail' : 'Non è riuscito a modificare le informazioni del profilo',
  'profilePage.profileImage' : 'Immagine del profilo',
  'profilePage.profileImageSelect' : 'Scegli l\'immagine del profilo',
  'profilePage.selectFileToUpload': 'Seleziona il file da caricare',
  'profilePage.cropImage': 'Ritaglia l\'immagine',
  'profilePage.preview': 'Anteprima',

  // transaction page
  'transactionPage.note' : 'Nota',
  'transactionPage.comment' : 'Commento',
  'transactionPage.selectLabelOfCategory': 'Scegli l\'etichetta!',
  'transactionPage.direction': 'Direzione della transazione',
  'transactionPage.nameOfPartner': 'Nome del partner',
  'transactionPage.amount': 'Importo',
  'transactionPage.currency': 'Valuta',
  'transactionPage.dateOfTransaction': 'Data della transazione',
  'transactionPage.regularFlag': 'Regolarità',
  'transactionPage.description': 'Descrizione',
  'transactionPage.ibanOfPartner': 'Conto bancario internazionale (IBAN) del partner',
  'transactionPage.accountBicOfPartner': 'Codice BIC della banca del partner.',
  'transactionPage.accountNumberOfThePartner': 'Conto bancario del partner',
  'transactionPage.cardNumber': 'Numero della carta di credito',
  'transactionPage.originalAmount': 'Importo originario',
  'transactionPage.originalCurrency': 'Valuta originaria',
  'transactionPage.dateOfBooking': 'Data della prenotazione',
  'currency.required': 'Inserisci la valuta',
  'amount.required': 'È obbligatorio scegliere la colonna dell\'importo.',
  'amount.min': 'Importo negativo non autorizzato',
  'nameOfPartner.required': 'Inserisci il nome del partner',
  'dateOfTransaction.required': 'Scegli la data della transazione',
  'transactionPage.generalInformation': 'Informazioni generali',
  'transactionPage.accountInformation': 'Informazioni del conto',
  'transactionPage.message.createSuccess': 'È riuscito a registrare la transazione',
  'transactionPage.message.createFail': 'Non è riuscito a registrare la transazione',
  'transactionPage.message.getAccountFail': 'Non è riuscito a caricare le informazioni sul conto.',
  'transactionPage.message.getLabelFail': 'Non è riuscito a caricare le informazioni sull\'etichetta',
  'transactionPage.message.getTransactionFail': 'Non è riuscito a caricare le informazioni sulla transazione',
  'transactionPage.message.updateSuccess': 'È riuscito a modificare le informazioni sulla transazione.',
  'transactionPage.message.updateFail': 'Non è riuscito a modificare le informazioni sulla transazione.',
  'transactionPage.message.getBalanceFail': 'Non è riuscito ad ottenere il saldo',
  'transactionPage.message.getDefaultLabelCategory': 'Non è riuscito a caricare le categorie predefinite.',
  'transactionPage.message.getListOfTransactionFail': 'Non è riuscito a caricare le transazioni.',
  'transactionPage.regularFlag.NO': 'No',
  'transactionPage.regularFlag.WEEKLY': 'Settimanalmente',
  'transactionPage.regularFlag.MONTHLY': 'Mensilmente',
  'transactionPage.regularFlag.EVERY_SECOND_MONTH': 'Ogni due mesi',
  'transactionPage.regularFlag.QUARTERLY': 'Trimestrale',
  'transactionPage.regularFlag.EVERY_HALF_YEAR': 'Ogni sei mesi',
  'transactionPage.regularFlag.YEARLY': 'Annuale',
  'transactionPage.regularFlag.OTHER': 'Altro',
  'transactionPage.addNewTransaction': 'Aggiungere una nuova transazione',
  'transactionPage.updateTransaction': 'Aggiornare la transazione',
  'transactionPage.removeTransaction.success': 'La transazione è stato eliminato con successo',
  'transactionPage.removeTransaction.fail': 'Non è riuscito ad eliminare la transazione',
  'transactionPage.removeConfirmation.message': 'Vuoi davvero eliminare questa transazione?',

  // UPLOAD-CSV SETTING PAGE
  'CSV-LOADER.FILE': 'File',
  'CSV-LOADER.FIRST-STEP.TITLE': 'Definisci una nuova struttura di file',
  'CSV-LOADER.FIRST-STEP.SELECT-CSV': 'Scegli un file CSV',
  'CSV-LOADER.FIRST-STEP.PDF': 'Solo file PDF',
  'CSV-LOADER.FIRST-STEP.PLEASE-SELECT': 'Scegliere il file',
  'File.required': 'Scegli un file!',
  'CSV-LOADER.SECOND-STEP.TITLE': 'Impostazioni di caricare',
  'CSV-LOADER.SECOND-STEP.PREVIEW': 'Anteprima convertita',
  'CSV-LOADER.SECOND-STEP.CONFIGURATION': 'Impostazioni',
  'CSV-LOADER.SECOND-STEP.DELIMITER': 'Delimitatore',
  'CSV-LOADER.SECOND-STEP.QUOTE-CHARACTER': 'Tipo di virgolette',
  'CSV-LOADER.SECOND-STEP.CHARACTER-SET': 'Codifica dei caratteri',
  'CSV-LOADER.SECOND-STEP.HEADER-ROW': 'Numero di righe di intestazione',
  'numberOfHeaderLines.min.0': 'Il numero di righe di intestazione non può essere negativo',
  'CSV-LOADER.SECOND-STEP.TRY-PARSE': 'Prova a interpretare il file',
  'CSV-LOADER.THIRD-STEP.TITLE': 'Visualizza l\'interpretazione dei dati',
  'CSV-LOADER.THIRD-STEP.SELECT_COLUMN_INSTRUCTIONS': 'Seleziona almeno le seguenti colonne:',
  'dateFormat.required': 'Scegli il formato della data',
  'numberFormat.required': 'Scegli il formato del numero',
  'CSV-LOADER.FOURTH-STEP.STAGE-DATA': 'Precarico',
  'CSV-LOADER.SECOND-STEP.SELECT-REQUIRED-COLUMNS': 'Seleziona le colonne obbligatorie:',

  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SEMICOLON': 'Punto e virgola',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COMMA': 'Virgola',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COLON': 'Colon',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.PIPE': 'Barra verticale',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.HASHMARK': 'Simbolo di cancelletto',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SPACE': 'Spazio',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.TAB': 'Tabulatore',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.NONE': 'Nessuno',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.DOUBLE-QUOTE': 'Virgolette',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.SINGLE-QUOTE': 'Apostrofo',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.BACKSLASH' : 'Backslash',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.DOUBLE-QUOTE' : 'Virgolette',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.SINGLE-QUOTE' : 'Apostrofo',
  'FIELDS.TRANSACTION.IGNORE': 'Ignoralo',
  'FIELDS.TRANSACTION.amount': 'Importo',
  'FIELDS.TRANSACTION.currency': 'Valuta',
  'FIELDS.TRANSACTION.dateOfTransaction': 'Data della transazione',
  'FIELDS.TRANSACTION.dateOfBooking': 'Data della prenotazione',
  'FIELDS.TRANSACTION.amountIn': 'Importo accreditato',
  'FIELDS.TRANSACTION.amountOut': 'Importo dedotto',
  'FIELDS.TRANSACTION.directionFlag': 'Direzione della transazione',
  'FIELDS.TRANSACTION.namePartner': 'Nome del partner',
  'FIELDS.TRANSACTION.note': 'Nota',
  'FIELDS.TRANSACTION.ibanPartner': 'Conto bancario internazionale (IBAN) del partner',
  'FIELDS.TRANSACTION.accountBicPartner': 'Codice BIC della banca del partner.',
  'FIELDS.TRANSACTION.accountNumberPartner': 'Conto bancario del partner',
  'FIELDS.TRANSACTION.cardNo': 'Numero della carta di credito',
  'FIELDS.TRANSACTION.amountOrig': 'Importo originario',
  'FIELDS.TRANSACTION.currencyOrig': 'Valuta originaria',
  'FIELDS.TRANSACTION.typeOfTransaction': 'Tipo di transazione',
  'FIELDS.TRANSACTION.description': 'Descrizione',
  'FIELDS.TRANSACTION.externalId': 'ID bancario',
  'CSV-LOADER.MAP-COLUMNS.INCOME.LABEL' : 'Segno dei ricavi',
  'CSV-LOADER.MAP-COLUMNS.EXPENSE.LABEL' : 'Segno dei costi',
  'CSV-LOADER.MAP-COLUMNS.USE-SIGN.LABEL': 'Usa il segno dell\'importo',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.CUSTOM.LABEL': 'Formato personalizzato',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.LABEL': 'Scegli il formato della data',
  'CSV-LOADER.MAP-COLUMNS.NUMBER-FORMAT.LABEL' : 'Scegli il formato dell\'importo',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.SUGGEST-BUTTON': 'Suggerisca un formato',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SEPARATOR.LABEL': 'Separatore decimale',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SUGGEST-BUTTON': 'Suggerimento per il separatore decimale',
  'CSV-LOADER.MAP-COLUMNS.ERROR.NO-DATE-COLUMN': 'Per favore scegli la colonna "Data della transazione"',
  'CSV-LOADER.SAVE.TITLE': 'Caricare i dati',
  'CSV-LOADER.SAVE.RESULT': 'Caricare il risultato',
  'CSV-LOADER.SAVE.RESULT.SUCCESS': 'Il caricamento delle transazioni è riuscito.',
  'CSV-LOADER.SAVE.RESULT.FAIL': 'Il caricamento delle transazioni non è riuscito.',
  'CSV-LOADER.SAVE.INVALID-AMOUNT': 'Importo non valido',
  'CSV-LOADER.SAVE.SAVE-SETTING-FAIL': 'Non è riuscito a salvare le impostazioni.',
  'CSV-LOADER.SAVE.SAVE-STAGE-DATA-FAIL': 'Non è riuscito a caricare i dati',
  'CSV-LOADER.SAVE.CANCEL.SUCCESS': 'Il caricamento delle transazioni è stato interrotto.',
  'CSV-LOADER.OVERLAPPING.MODAL.TITLE': 'Transazioni sovrapposte',
  'CSV-LOADER.OVERLAPPING.MODAL.SUBTITLE': 'Ci sono sovrapposizioni tra le date delle transazioni precedentemente caricati e le date delle transazioni che vorrebbe a caricare ora.',
  'CSV-LOADER.OVERLAPPING.MODAL.SELECT': 'Scegli cosa vuoi fare:',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Append': 'Tengo le transazioni già caricati ed aggiungo il periodo dal file corrente che non era stato precedentemente incluso.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Substitute': 'Sovrascrivo le transazioni precedentemente caricati con le transazioni che sono nel file corrente.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Merge': 'Aggiungo tutte le transazioni del questo file alla transazioni del periodo precedentemente caricato.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Cancel': 'Interrompo il caricamento.',

  // first-time-login
  'firstTimeLoginPage.title' : 'Benvenuto su Continuity!',
  'firstTimeLoginPage.subTitle': 'Scegli la valuta e crei un nuovo conto',

  // menu items
  'menu.item.Overview': 'Panoramica',
  'menu.item.AccountList': 'Conti/Portafogli',
  'menu.item.Profile': 'Il mio profilo',
  'menu.item.Analytics': 'Analitica',
  'menu.item.signOut': 'Esci',
  'menu.item.deleteMyAccount': 'Cancella il mio account',
  'menu.item.Cash-flow': 'Flusso monetario',
  'menu.item.inviteNewUser': '##Invite new user',
  'menu.item.help': 'Aiuto', //#

  // enum icons
  'enum.name.ACCOMODATION' : 'Alloggio',
  'enum.name.TRANSPORT' : 'Trasporto',
  'enum.name.MEALS' : 'Pasto',
  'enum.name.CLOTHING' : 'Abbigliamento',
  'enum.name.ALCOHOL_TOBACCO' : 'Alcolici & Tabacco',
  'enum.name.BANK' : 'Banca',
  'enum.name.EDUCATION' : 'Istruzione',
  'enum.name.ENTERTAINMENT' : 'Divertimento',
  'enum.name.GIFT' : 'Regalo',
  'enum.name.HOBBY' : 'Hobby',
  'enum.name.SPORT' : 'Sport',
  'enum.name.HOLIDAY' : 'Vacanza',
  'enum.name.HYGIENE_HOUSEHOLD' : 'Igiene & Casa',
  'enum.name.TELECOMMUNICATION' : 'Telecomunicazione',
  'enum.name.HEALTH_CARE' : 'Sanità',
  'enum.name.OTHER' : 'Altro',
  'enum.name.SPOUSE' : 'Sposo/Sposa',
  'enum.name.MYSELF' : 'Io',
  'enum.name.MOM' : 'Madre',
  'enum.name.DAD' : 'Padre',
  'enum.name.FAMILY' : 'Famiglia',
  'enum.name.FRIENDS' : 'Amici',
  'enum.name.IN-LAWS' : 'Parenti',
  'enum.name.CHILD1' : 'Figlio/Figlia 1',
  'enum.name.CHILD2' : 'Figlio/Figlia 2',
  'enum.name.CHILD3' : 'Figlio/Figlia 3',
  'enum.name.CHILD4' : 'Figlio/Figlia 4',
  'enum.name.CHEMICALS' : 'Chimici',
  'enum.name.ENERGY' : 'Energia',
  'enum.name.EQUIPMENT' : 'Attrezzatura',
  'enum.name.FIXTURE' : 'Pezzo',
  'enum.name.FOOD' : 'Cibo',
  'enum.name.GADGET' : 'Aggeggio',
  'enum.name.INSURANCE' : 'Assicurazione',
  'enum.name.MAINTENANCE' : 'Manutenzione',
  'enum.name.PENALTIES' : 'Pena',
  'enum.name.RENTAL' : 'Noleggio',
  'enum.name.TAX' : 'Tassa',
  'enum.name.TICKET_SERVICE' : 'Servizio/Biglietto',
  'enum.name.EMPLOYER' : 'Datore di lavoro',
  'enum.name.CLIENT' : 'Cliente',
  'enum.name.EARNING_FROM_INVESTMENT' : 'Ritorno sugli investimenti',
  'enum.name. RENTAL' : 'Noleggio',
  'enum.name.SALE_OF_ASSET' : 'Vendita di strumento',
  'enum.name.PET' : 'Animale domestico',
  'enum.name.STATE' : 'Stato',
  'enum.name.TRANSFER' : 'Trasferimento tra i conti',
  'enum.name.INCOME_LABELED_AS_TRANSFER' : 'Controparte mancante',
  'enum.name.EXPENSE_LABELED_AS_TRANSFER' : 'Controparte mancante',
  'enum.name.SUPPORT' : 'Sussidio',
  'enum.name.SALARY' : 'Stipendio',
  'enum.category.TRANSFER' : 'Trasferimento',
  'enum.category.TITLE': 'Titolo',
  'enum.category.PAYING': 'Pagamento',
  'enum.category.PURPOSE': 'Scopo',
  'enum.category.BENEFICIARY': 'Beneficiario',
  'enum.category.TOOL_SERVICE': 'Strumento & Servizio',
  'enum.category.THIS_MONTH': 'Questo mese',
  'enum.category.LAST_MONTH': 'Lo scorso mese',
  'enum.category.THIS_YEAR': 'Quest\'anno',
  'enum.category.LAST_YEAR': 'L\'anno scorso',
  'enum.category.THIS_WEEK': 'Questa settimana',
  'enum.category.LAST_WEEK': 'La settimana scorsa',
  'enum.category.TODAY': 'Oggi',
  'enum.category.CUSTOM': 'Personalizzata',

  // direction flag
  'direction.flag.INCOME': 'Reddito',
  'direction.flag.EXPENSE': 'Spesa',
  'direction.flag.OUT_GOING_TRANSFER': 'Trasferimento in uscita',
  'direction.flag.INCOMING_TRANSFER': 'Trasferimento in entrata',

  // analytics page
  'analyticsPage.incomeChart': 'Grafico del reddito',
  'analyticsPage.expenseChart': 'Grafico della spesa',
  'analyticsPage.noIncomeData': 'Non ci sono dati disponibili sul reddito',
  'analyticsPage.noExpenseData': 'Non ci sono dati disponibili sulla spesa',
  'analyticsPage.getLabelFail': 'Ottenere l\'elenco delle etichette per categoria senza successo',
  'analyticsPage.getChartDataFail': 'Ottenere i dati del grafico senza successo',
  'analyticsPage.totalAmount': 'Importo totale',
  'analyticsPage.selectIncomeCategory': 'Seleziona Categoria di reddito',
  'analyticsPage.selectExpenseCategory': 'Seleziona la categoria di spesa',
  'analyticsPage.selectTimePeriod': 'Seleziona Periodo di tempo',
  'analyticsPage.from': 'A partire dal',
  'analyticsPage.to': 'Per',

  // cash-flow page
  'cash-flow.selectYourAccount': 'Seleziona il tuo account',
  'cash-flow.allAccountOfYourProfile': 'Tutti gli account del tuo profilo',
  'cash-flow.chartHeader': 'Flusso di cassa del tuo account',
  'cash-flow.subtextOfAnAccount': 'il conto: ',
  'cash-flow.subtextOfAllAccount': 'Tutti gli account del tuo profilo',
  'cash-flow.warningMessage': 'Il calcolo del flusso di cassa è disponibile solo con un anno di anticipo.',

  // invite-users page
  'inviteUserPage.email': 'E-mail', //#
  'inviteUserPage.message': 'Messaggio', //#
  'inviteUserPage.emailRequired': 'L\'e-mail è richiesta', //#
  'inviteUserPage.emailInvalid': 'L\'e-mail deve essere un indirizzo e-mail valido', //#
  'inviteUserPage.messageRequired': 'Il messaggio è obbligatorio', //#
  'inviteUsersPage.message.sendSuccess': 'Invito riuscito', //#
  'inviteUsersPage.message.sendFail': 'L\'invito è fallito', //#
  'inviteUsersPage.message.defaultMessage': 'Salve, \nUtilizzo Continuity per controllare i miei dati finanziari e per pianificare il mio flusso di cassa. \nTi suggerisco di provarlo.', //#
  'inviteUsersPage.email.subject': 'ti ha invitato a usare Continuity', //#
  'inviteUsersPage.email.button': 'Registrati a Continuity', //#

  // error message
  'error.INITIAL_BALANCE_IS_LATER' : 'Avvertimento! La data della transazione è anteriore alla data del saldo iniziale. Modifica la data del tuo saldo iniziale!',
  'error.FAILED' : 'Il calcolo dei saldi non è riuscito',

  // tool tip
  // add account page
  'toolTip.addAccount.name': 'Assegna un nome al tuo account, non deve corrispondere al nome assegnato dall\'istituto finanziario. Il punto è che tu riconosca quel nome.',
  'toolTip.addAccount.nature': 'Il sistema gestisce ogni tipo in modo diverso.',
  'toolTip.addAccount.source': 'Puoi caricare regolarmente i tuoi dati bancari da un file scaricato dalla tua netbank. È una buona idea annotare gli acquisti in contanti il ​​prima possibile alla versione mobile di Continuity.',
  'toolTip.addAccount.currency': 'Se hai diverse valute con te, aggiungi un portafoglio separato per ogni valuta!',
  'toolTip.addAccount.dateOfInitialBalance': 'Scegli una data di cui conosci il saldo di fine giornata!',
  'toolTip.addAccount.initialBalance': 'Inserisci il saldo di fine giornata per la data. Non è necessario. Se non lo inserisci, Continuity non sarà in grado di calcolare il saldo del tuo conto corrente.',
  'toolTip.addAccount.iban': 'Numero di conto bancario internazionale che inizia con un prefisso internazionale e due cifre di controllo. Se lo specifichi, Continuity renderà più facile identificare i trasferimenti tra i tuoi account. Non è necessario.',
  'toolTip.addAccount.accountNumber': 'Numero di conto bancario nazionale. 2 volte o 3 volte 8 cifre. Se lo specifichi, Continuity renderà più facile identificare i trasferimenti tra i tuoi account. Non è necessario.',
  'toolTip.addAccount.bic': 'ID internazionale della tua banca. Noto anche come codice Swift. Se lo specifichi, Continuity renderà più facile identificare i trasferimenti tra i tuoi account. Non è necessario.',
  'toolTip.addAccount.saveBtn': 'Dopo il salvataggio, puoi ancora modificare i dettagli dell\'account, ma se hai già registrato una transazione per l\'account, i dati non possono più essere modificati tranne il nome e il saldo.',
  'toolTip.addAccount.resetBtn': 'Elimina i dati inseriti finora.',

  // edit account page
  'toolTip.editAccount.name': 'Puoi modificare il nome del tuo account in qualsiasi momento.',
  'toolTip.editAccount.nature': 'Se hai già registrato una transazione sul conto, il tipo di conto non può più essere modificato.',
  'toolTip.editAccount.source': 'Se hai già registrato una transazione sul conto, l\'origine dati del conto non può più essere modificata.',
  'toolTip.editAccount.currency': 'Se hai già registrato una transazione sul conto, la valuta del conto non può più essere modificata.',
  'toolTip.editAccount.dateOfInitialBalance': 'Scegli una data per la quale conosci il saldo di fine giornata.',
  'toolTip.editAccount.initialBalance': 'Inserisci il saldo di fine giornata per la data. Non è necessario. Se non lo inserisci, Continuity non sarà in grado di calcolare il saldo del tuo conto corrente.',
  'toolTip.editAccount.iban': 'Se hai già registrato una transazione sul conto, l\'IBAN non può più essere modificato.',
  'toolTip.editAccount.accountNumber': 'Se hai già registrato una transazione sul conto, il numero di conto non può più essere modificato.',
  'toolTip.editAccount.bic': 'Se hai già registrato una transazione sul conto, il codice BIC non può più essere modificato.',
  'toolTip.editAccount.saveBtn': 'Modifica le informazioni sull\'account con le informazioni appena inserite.',
  'toolTip.editAccount.cancelBtn': 'Ripristina i dati precedenti.',
  'toolTip.editAccount.deleteBtn': 'Elimina l\'account con tutte le transazioni e le impostazioni associate.',

  // add transaction page
  'toolTip.addTransaction.direction': 'La direzione della transazione determina automaticamente anche il suo segno. Se vuoi registrare un elemento di correzione, dopo aver salvato, apri la transazione per la modifica, lì puoi cambiare il segno.',
  'toolTip.addTransaction.dateOfTransaction': 'Non dimenticare di configurarlo se la transazione non è avvenuta oggi!',
  'toolTip.addTransaction.amount': 'Inserisci l\'importo senza segno!',
  'toolTip.addTransaction.namePartner': 'L\'azienda o la persona per cui hai pagato o che ti ha pagato.',
  'toolTip.addTransaction.regularFlag': 'Sulla base delle transazioni contrassegnate come regolari, Continuity predispone un piano di flussi di cassa con un anno di anticipo. Se in precedenza hai contrassegnato una transazione di questo tipo come regolare, non contrassegnarla di nuovo, poiché verrà inclusa nel piano due volte.',
  'toolTip.addTransaction.description': 'Informazioni sulla transazione che ritieni importanti.',
  'toolTip.addTransaction.note': 'Il tuo commento.',
  'toolTip.addTransaction.saveBtn': 'Dopo il salvataggio, la transazione può essere aperta per la modifica se uno dei dati è stato inserito in modo errato.',
  'toolTip.addTransaction.cancelBtn': 'Elimina i dati inseriti finora.',

  // the following seven fields do not appear on the data entry form
  'toolTip.addTransaction.ibanPartner': 'Il numero di conto bancario internazionale del tuo partner. Inizia con il codice del paese a due lettere.',
  'toolTip.addTransaction.accountBicPartner': 'L\'identificativo internazionale della banca del tuo partner. Noto anche come codice Swift.',
  'toolTip.addTransaction.accountNumberPartner': 'Il numero di conto bancario nazionale del partner.',
  'toolTip.addTransaction.cardNo': 'Il numero della carta di pagamento utilizzata per la transazione.',
  'toolTip.addTransaction.amountOrig': 'L\'importo originale se la valuta della transazione è diversa dalla valuta del conto.',
  'toolTip.addTransaction.currency': 'La valuta della transazione, se diversa dalla valuta del conto.',
  'toolTip.addTransaction.dateOfBooking': 'La data in cui la tua banca o il tuo fornitore di servizi finanziari ha prenotato la transazione.',

  // edit transaction page
  'toolTip.editTransaction.directionFlag': 'Puoi anche contrassegnare una transazione con un segno positivo come spesa se, ad esempio, ricevi un rimborso da un fornitore di servizi per un pagamento in eccesso. Quindi riduce le tue spese, non le tue entrate.',
  'toolTip.editTransaction.dateOfTransaction': 'Quando è stato effettuato il pagamento. Se un file è l\'origine dei dati, non può essere modificato.',
  'toolTip.editTransaction.amount': 'L\'importo pagato è nella valuta della fattura. Se un file è l\'origine dei dati, non può essere modificato.',
  'toolTip.editTransaction.namePartner': 'L\'azienda o la persona per cui hai pagato o che ti ha pagato. Se un file è l\'origine dei dati, non può essere modificato.',
  'toolTip.editTransaction.regularFlag': 'Sulla base delle transazioni contrassegnate come regolari, Continuity predispone un piano di flussi di cassa con un anno di anticipo. Contrassegna un tipo di transazione solo una volta come regolare! Si consiglia di contrassegnare l\'ultima occorrenza.',
  'toolTip.editTransaction.description': 'Se un file è l\'origine dei dati, non può essere modificato.',
  'toolTip.editTransaction.note': 'Se un file è l\'origine dei dati, non può essere modificato.',
  'toolTip.editTransaction.ibanPartner': 'Dati provenienti da un file, non possono essere modificati.',
  'toolTip.editTransaction.accountBicPartner': 'Dati provenienti da un file, non possono essere modificati.',
  'toolTip.editTransaction.accountNumberPartner': 'Dati provenienti da un file, non possono essere modificati.',
  'toolTip.editTransaction.cardNo': 'Il numero di carta utilizzato per la transazione. Dati provenienti da un file, non possono essere modificati.',
  'toolTip.editTransaction.amountOrig': 'Se la transazione non è stata effettuata nella valuta del conto, è l\'importo originale. Dati provenienti da un file, non possono essere modificati.',
  'toolTip.editTransaction.currencyOrig': 'Se la transazione non era nella valuta del conto, è la valuta originale. Dati provenienti da un file, non possono essere modificati.',
  'toolTip.editTransaction.dateOfBooking': 'Quando il tuo istituto finanziario ha registrato la transazione. Dati provenienti da un file, non possono essere modificati.',
  'toolTip.editTransaction.saveBtn': 'Salvare le modifiche.',
  'toolTip.editTransaction.cancelBtn': 'Ripristina i dati precedenti.',
  'toolTip.editTransaction.removeBtn': 'Puoi eliminare la transazione se l\'hai caricata o registrata accidentalmente più volte.',

  // account upload csv page
  'toolTip.accountUpload.fileName': 'Le seguenti impostazioni devono essere eseguite solo una volta per un tipo di file. Continuity ricorderà le impostazioni la prossima volta che caricherai un file su questo account.',
  'toolTip.accountUpload.delimiter': 'Il sistema determina automaticamente il carattere da cui sono separati i dati. Modificalo solo se ne sei sicuro.',
  'toolTip.accountUpload.quote': 'Il sistema determina automaticamente se i dati sono racchiusi tra virgolette. Modificalo solo se ne sei sicuro.',
  'toolTip.accountUpload.charset': 'Il sistema determina automaticamente il set di caratteri utilizzato nel file. Modificalo solo se ne sei sicuro.',
  'toolTip.accountUpload.numberOfHeaderLines': 'Imposta il numero di righe nell\'intestazione all\'inizio del file, seguito dai dati effettivi.',
  'toolTip.accountUpload.selectColumn': 'La categorizzazione automatica è supportata dalla compilazione del nome, del numero di conto o dell\'IBAN del partner, nonché del campo dei commenti oltre ai campi obbligatori.',
  'toolTip.accountUpload.dateFormat': 'Presta attenzione all\'ordine dell\'anno e del giorno, all\'ortografia e ai caratteri separatori. Scegli un formato data basato su questi!',
  'toolTip.accountUpload.numberFormat': 'Nota se i numeri contengono una virgola decimale o un punto decimale. È presente un carattere separatore dopo le migliaia e, in tal caso, qual è? Scegli un formato numerico basato su questi!',
  'toolTip.accountUpload.backBtn': 'Torna indietro per selezionare il file.',
  'toolTip.accountUpload.nextBtn': 'Caricamento del file selezionato.',
  'toolTip.accountUpload.correctTheSettingBtn': 'Se vedi in base al precaricamento che devi modificare le impostazioni, puoi utilizzare questo pulsante per tornare indietro.',
  'toolTip.accountUpload.saveAndFinishBtn': 'Se sei soddisfatto del precarico, completa l\'operazione.',

  // profile page
  'toolTip.profileDetail.name': 'Dai un nome al tuo profilo!', //#
  'toolTip.profileDetail.currency': 'Scegli la valuta del tuo profilo! Se hai più di una valuta, il saldo totale verrà visualizzato in questa valuta nella panoramica.', //#
  'toolTip.profileDetail.attachFile': 'Con una foto puoi personalizzare il tuo profilo.', //#
  'toolTip.profileDetail.removeBtn': 'Qui puoi avviare la cancellazione del tuo profilo. Una volta eliminato il tuo profilo, non potrai registrarti nuovamente con l\'indirizzo email utilizzato in precedenza.', //#

  // sent new user page
  'toolTip.inviteUsers.email': 'L\'indirizzo email dell\'invitato. Riceverai il tuo premio solo se l\'invitato utilizza Continuity con questa email.', //#
  'toolTip.inviteUsers.message': 'Rendi personale l\'invito.', //#

  // transaction filter modal page
  'transactionFilterModal.title': 'Elenca le transazioni dell\'etichetta:',
  'transactionFilterModal.accountName': 'Nome utente',
  'transactionFilterModal.dateOfTransaction': 'Data della transazione',
  'transactionFilterModal.amount': 'Importo',
  'transactionFilterModal.descAndNote': 'Descrizione / Nota',
  'transactionFilterModal.partnerName': 'Nome del partner',

  // delete my user account modal
  'deleteUserAccount.confirmMessage1': 'Sei sicuro di voler eliminare il tuo account?',
  'deleteUserAccount.confirmMessage2': 'I tuoi dati potrebbero non essere ripristinati dopo l\'eliminazione e potresti non accedere in seguito con le stesse credenziali. \n Sei sicuro?',
  'deleteUserAccount.deleteSuccessfully': 'Il tuo account utente è stato eliminato con successo.',
  'deleteUserAccount.deleteUnsuccessfully': 'L\'eliminazione del tuo account non è riuscita. Contatta la nostra assistenza clienti.',

  // help popup
  'helpPopup.step0.title': 'Panoramica',
  'helpPopup.step0.intro': 'Il \"Saldo totale\" è la somma dei saldi dei tuoi conti. Viene visualizzato nella valuta del tuo profilo. Qui puoi vedere quanti soldi hai. I saldi dei tuoi conti in una valuta diversa dal tuo profilo verranno convertiti nella valuta del profilo all\'ultimo tasso di cambio. Di seguito puoi vedere il saldo attuale di tutti i tuoi conti nella valuta del conto. I saldi attuali mostrano solo i fatti, non i piani. In questo modo vengono inclusi nei saldi solo il risultato delle transazioni inserite manualmente o caricate da un file, non vengono incluse le transazioni pianificate. A proposito, puoi modificare la valuta del tuo profilo nel menu \"Profilo\".', //#
  'helpPopup.step1.title': 'Conti/Portafogli',
  'helpPopup.step1.intro': 'Qui puoi aggiungere nuovi account al tuo profilo e aggiungere o modificare informazioni ai tuoi account esistenti. Ma cos\'è un account? Questo termine significa qualsiasi cosa in cui puoi tenere denaro o altri beni. Quindi, un deposito di una società di costruzioni, un conto di investimento e persino il tuo portafoglio sono \"conti\". E se ci sono più valute nel tuo portafoglio, ti consigliamo di aggiungerlo come account separato, perché un account può avere solo una valuta. Le proprietà specificate dei tuoi account vengono considerate durante i calcoli, ad es. quando cerchi la contro transazione di un trasferimento tra i tuoi conti. Pertanto, le informazioni sull\'account non possono essere modificate in seguito. Fanno eccezione il nome del conto e le informazioni sul saldo di apertura. Se hai inserito delle informazioni in modo errato, dovresti eliminare quell\'account e ricrearlo. Si spera che noterai presto un tale errore e non perderai molti dati durante l\'eliminazione e la ricreazione dell\'account.', //#
  'helpPopup.step2.title': 'Il mio profilo',
  'helpPopup.step2.intro': 'You can edit your name, default currency, and avatar on that page. You also able to delete your profile from the system, but please becareful', //#
  'helpPopup.step3.title': 'Analitica',
  'helpPopup.step3.intro': 'Ti mostra quanto hai speso per determinati scopi e mostra le fonti delle tue entrate. Se utilizzi il sistema da un po\', dovresti analizzare questi grafici. Se hai troppe entrate o costi nella categoria \"altro\", dovresti prestare un po\' più di attenzione alla categorizzazione.', //#
  'helpPopup.step3.imageUrl': 'https://live.staticflickr.com/65535/51930070200_95da6a62ff_c.jpg',
  'helpPopup.step4.title': 'Flusso monetario',
  'helpPopup.step4.intro': 'Un piano di flusso di cassa viene creato per te in base alle transazioni che contrassegni come regolari o percepite come regolari dal sistema. Dovresti controllare questo ordine, sia a livello di account che in generale. Se il piano mostra un saldo negativo su un account o nel complesso, puoi comunque correggerlo in tempo. Ad esempio, puoi effettuare un trasferimento sul conto in cui i tuoi soldi finiranno, oppure puoi ritardare una spesa, oppure puoi richiedere un anticipo su uno dei tuoi guadagni futuri.', //#
  'helpPopup.step4.imageUrl': 'https://live.staticflickr.com/65535/51928480722_346507237f_b.jpg',

  // first time login initial help
  'firstTimeLogin.popup.name': 'Inserisci il nome del tuo primo account! Puoi scegliere un nome personalizzato. L\'unico punto è che dovresti riconoscere l\'account dal suo nome. Questo nome verrà utilizzato nel sistema in seguito. Si consiglia di aggiungere prima gli account utilizzati di frequente.', //#
  'firstTimeLogin.popup.nature': 'Seleziona la natura dell\'account! Generalmente tutti hanno almeno un portafoglio per contanti e un conto corrente presso una banca. Inizia con questi!', //#
  'firstTimeLogin.popup.source': 'Seleziona il metodo di immissione per l\'account. È necessario inserire manualmente le transazioni in contanti. Puoi anche caricare transazioni bancarie utilizzando un file scaricato dalla tua netbank.', //#
  'firstTimeLogin.popup.currency': 'Seleziona la valuta del conto. La valuta dei tuoi account può essere diversa, ma un account può avere solo una valuta, che non puoi modificare in seguito. Devi aggiungere un nuovo account per una nuova valuta.', //#
};
