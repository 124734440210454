export const HELP_POPUP_STEP = {
  OVERVIEW: 0,
  ACCOUNTS: 1,
  PROFILE: 2,
  ANALYTICS: 3,
  CASH_FLOW: 4,
};

export const getHelpPopupStepFromRoute = (route: string) => {
  if (route.includes('accounts') || route.includes('invite-new-user') || route.includes('first-time-login')) {
    return HELP_POPUP_STEP.OVERVIEW;
  } else if (route.includes('user-account-list') || route.includes('create-account')) {
    return HELP_POPUP_STEP.ACCOUNTS;
  } else if (route.includes('profile-detail')) {
    return HELP_POPUP_STEP.PROFILE;
  } else if (route.includes('analytics')) {
    return HELP_POPUP_STEP.ANALYTICS;
  } else if (route.includes('cash-flow')) {
    return HELP_POPUP_STEP.CASH_FLOW;
  } else {
    return HELP_POPUP_STEP.OVERVIEW;
  }

};
