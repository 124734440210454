import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './authentication/login/login.component';
import { UserService } from './service/user.service';
import { HttpService } from './shared/http.service';
import { TokenService } from './authentication/token.service';
import { AuthenticationService } from './authentication/authentication.service';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountCardInfoComponent } from './accounts/account-card-info/account-card-info.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { TranslateService, TRANSLATION_PROVIDERS } from './translate';
import { TranslatePipe } from './translate/translate.pipe';
import { RegisterComponent } from './authentication/register/register.component';
import { CommonService } from './service/common.service';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ActiveAccountComponent } from './authentication/active-account/active-account.component';
import { ToastServices } from './shared/toast.service';
import { ToastrModule } from 'ngx-toastr';
import { AddAccountComponent } from './accounts/add-account/add-account.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { AccountService } from './service/account.service';
import { AccountDetailComponent } from './accounts/account-detail/account-detail.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './service/loader.service';
import { ProfileDetailComponent } from './users/profile-detail/profile-detail.component';
import { ProfileService } from './service/profile.service';
import { TransactionsComponent } from './transactions/transactions.component';
import { TransactionService } from './service/transaction.service';
import { TransactionItemComponent } from './transactions/transaction-item/transaction-item.component';
import { MyHammerConfig } from './shared/my-hummer-config';
import { TransactionLabelModalComponent } from './transactions/transaction-label-modal/transaction-label-modal.component';
import { TransactionDetailComponent } from './transactions/transaction-detail/transaction-detail.component';
import { AddTransactionComponent } from './transactions/add-transaction/add-transaction.component';
import { TransactionLabelSelectionComponent } from './transactions/transaction-label-selection/transaction-label-selection.component';
import { AccountUploadSettingComponent } from './accounts/account-upload-setting/account-upload-setting.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { CsvLoaderService } from './service/csv-loader.service';
import { FirstTimeLoginComponent } from './users/first-time-login/first-time-login.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { TransactionOverlappingModalComponent } from './accounts/account-upload-setting/transaction-overlapping-modal/transaction-overlapping-modal.component';
import { UserAccountListComponent } from './users/user-account-list/user-account-list.component';
import { ConfirmPopupComponent } from './shared/confirm-popup/confirm-popup.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropComponent } from './shared/image-crop/image-crop.component';
import { DataSharingService } from './shared/data-service-change';
import { CashFlowComponent } from './cash-flow/cash-flow.component';
import { TransactionFilterModalComponent } from './analytics/transaction-filter-modal/transaction-filter-modal.component';
import { AppCalendarHeaderComponent } from './transactions/app-calendar-header';
import { InviteUsersComponent } from './invite-users/invite-users.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    AccountsComponent,
    AccountCardInfoComponent,
    TranslatePipe,
    RegisterComponent,
    ActiveAccountComponent,
    AddAccountComponent,
    BreadcrumbComponent,
    AccountDetailComponent,
    PageNotFoundComponent,
    LoaderComponent,
    ProfileDetailComponent,
    TransactionsComponent,
    TransactionItemComponent,
    TransactionLabelModalComponent,
    TransactionOverlappingModalComponent,
    TransactionDetailComponent,
    AddTransactionComponent,
    TransactionLabelSelectionComponent,
    AccountUploadSettingComponent,
    FirstTimeLoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserAccountListComponent,
    TransactionFilterModalComponent,
    ConfirmPopupComponent,
    AnalyticsComponent,
    ImageCropComponent,
    CashFlowComponent,
    AppCalendarHeaderComponent,
    InviteUsersComponent,
  ],
  entryComponents: [
    TransactionLabelModalComponent,
    TransactionOverlappingModalComponent,
    ConfirmPopupComponent,
    TransactionFilterModalComponent,
    AppCalendarHeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    RecaptchaModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MaterialFileInputModule,
    FlexLayoutModule,
    ImageCropperModule,
    ToastrModule.forRoot(ToastServices.toastrConfig),
    NgxEchartsModule,
    HttpClientModule,
    SharedModule,
    AngularEditorModule,
    RouterModule.forRoot(AppRoutes, {useHash: true}),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LePCucUAAAAAEd4OHMSdavJkxsc9XEeJlJ6o4AJ' } as RecaptchaSettings,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    HttpService,
    UserService,
    CommonService,
    TokenService,
    AuthenticationService,
    AuthenticationGuard,
    TRANSLATION_PROVIDERS,
    TranslateService,
    ToastServices,
    AccountService,
    LoaderService,
    ProfileService,
    TransactionService,
    CsvLoaderService,
    DataSharingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
