import { Component, OnInit } from '@angular/core';
import { BasePage } from '../shared/BasePage';
import { TransactionService } from '../service/transaction.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { ToastServices } from '../shared/toast.service';
import { TranslateService } from '../translate';
import { AccountService } from '../service/account.service';
import { UserService } from '../service/user.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material';
import * as moment from 'moment';
import { DataSharingService } from '../shared/data-service-change';
import { HELP_POPUP_STEP } from '../shared/data.utils';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM',
  },
  display: {
    dateInput: 'YYYY-MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class CashFlowComponent extends BasePage implements OnInit {

  constructor(private accountService: AccountService,
              private userService: UserService,
              private dataSharingService: DataSharingService,
              private toastService: ToastServices,
              private translateService: TranslateService, ) {
    super();
  }

  cashFlowChartOption: any;
  chartDatas = {
    months: [],
    datas: []
  };
  selectedAccounts = null;
  accountList = [];
  startTime = new FormControl(moment());
  endTime = new FormControl(moment());
  currency = '';

  ngOnInit() {
    this.initTiming();
    this.initChart();
    this.checkFirstTimeLogin(HELP_POPUP_STEP.CASH_FLOW, this.dataSharingService);
  }

  async initChart() {
    await this.getAccountList();
    this.reDrawChart();
  }

  chosenYearHandler(normalizedYear: Moment, time: FormControl) {
    const ctrlValue = time.value;
    ctrlValue.year(normalizedYear.year());
    time.setValue(ctrlValue);
  }

  async chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, time: FormControl) {
    const latestEndDate = moment().add(1, 'years');
    const ctrlValue = time.value;
    ctrlValue.month(normalizedMonth.month());
    time.setValue(ctrlValue);
    datepicker.close();
    // if endDate is more than one year from now, showing message
    if (time.value.isAfter(latestEndDate)) {
      this.toastService.showWarning(this.translateService.instant('cash-flow.warningMessage'));
      time.setValue(latestEndDate);
    }
    this.reDrawChart();
  }

  private drawChart() {
    this.cashFlowChartOption = {
      title: {
        text: this.translateService.instant('cash-flow.chartHeader'),
        subtext: this.getSubTextForChart(),
        left: 'center'
      },
      color: ['#000438'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false
        }
      },
      xAxis: {
        type: 'category',
        data: this.chartDatas.months
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: this.chartDatas.datas,
        type: 'line',
        label: {
          normal: {
            show: true,
            position: 'top'
          }
        }
      }]
    };

  }

  private getAccountList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.accountService.getProfileListAccount(this.userService.getProfileIdFromLocal()).then(result => {
        this.accountList = result;
        resolve(true);
      });
    });
  }

  private initTiming() {
    // start from (currentMonth - 2) to (currentMonth + 12)
    const fromMonth = moment().subtract(2, 'months');
    this.startTime = new FormControl(fromMonth);
    const toMonth = moment().add(1, 'years');
    this.endTime = new FormControl(toMonth);
  }

  private getCashFlowData() {
    const from = this.getDateInFormat(this.startTime.value, 'YYYY-MM');
    const to = this.getDateInFormat(this.endTime.value, 'YYYY-MM');
    return this.accountService.getCashFlowData(this.defaultProfileId, this.selectedAccounts == null ? null : this.selectedAccounts.id, from, to);
  }

  async reDrawChart() {
    const chartRawDatas = await this.getCashFlowData();
    this.chartDatas.months = chartRawDatas.months;
    this.chartDatas.datas = [];
    for (const a of chartRawDatas.listBalances) {
      const data = {
        value: a,
        label: {
          color: a <= 0 ? '#e53935' : '#000438'
        }
      };
      this.chartDatas.datas.push(data);
    }
    this.drawChart();
  }

  private getSubTextForChart() {
    if (this.selectedAccounts) {
      return this.translateService.instant('cash-flow.subtextOfAnAccount') + this.selectedAccounts.accountName
      + ` (${this.selectedAccounts.currency})`;
    } else {
      return this.translateService.instant('cash-flow.subtextOfAllAccount')
        + ` (${this.defaultCurrency})`;
    }
  }

}
