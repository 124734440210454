import { Injectable } from '@angular/core';

import { HttpService } from '../shared/http.service';
import { ConstantSetting } from '../shared/constant.config';
import { AccountLoaderDto } from '../accounts/account-dto/account-loader-dto';
import { FiTransactionDto } from '../accounts/account-dto/fi-transaction-dto';

@Injectable()
export class CsvLoaderService {

  constructor(
    private http: HttpService,
  ) {}

  getDelimiters(): Promise<any> {
    return this.http.get(ConstantSetting.GET_DELIMITERS);
  }

  getColumns(): Promise<any> {
    return this.http.get(ConstantSetting.GET_COLUMNS);
  }

  getRequiredColumns(): Promise<any> {
    return this.http.get(ConstantSetting.GET_REQUIRED_COLUMNS);
  }

  suggestDateFormats(): Promise<any> {
    return this.http.get(ConstantSetting.SUGGEST_DATE_FORMAT);
  }

  suggestedNumberFormats(): Promise<any> {
    return this.http.get(ConstantSetting.SUGGEST_NUMBER_FORMAT);
  }

  parse(csv: File, csvConfig?: AccountLoaderDto): Promise<any> {
    // Parse the header too, to help column mapping.
    const config = Object.assign({}, csvConfig);
    config.numberOfHeaderLines = 0;
    const formData: FormData = new FormData();
    formData.append('config', JSON.stringify(config));
    formData.append('csv', csv);
    return this.http.upload(ConstantSetting.PARSE, formData);
  }

  saveStage(accountId: number, transactionList: any[]): Promise<any>  {
    const url = ConstantSetting.STAGE_SAVE.replace(/\{\w+\}/ig, accountId.toString());
    return this.http.post(url, transactionList);
  }

  saveAccountUploadSetting(accountId: number, accountSetting: any): Promise<any> {
    const url = ConstantSetting.SAVE_ACCOUNT_UPLOAD_SETTING.replace(/\{\w+\}/ig, accountId.toString());
    return this.http.post(url, accountSetting);
  }

  getAccountUploadSetting(accountId: number): Promise<any> {
    const url = ConstantSetting.GET_ACCOUNT_UPLOAD_SETTING.replace(/\{\w+\}/ig, accountId.toString());
    return this.http.get(url);
  }

  saveTransaction(accountId: number, stagedData: any): Promise<any> {
    const url = ConstantSetting.CSV_SAVE_TRANSACTIONS.replace(/\{\w+\}/ig, accountId.toString());
    return this.http.post(url, stagedData);
  }

  checkStageTransactions(accountId: number, stagedData: FiTransactionDto[]): Promise<any>  {
    const url = ConstantSetting.CSV_CHECK_STAGE_TRANSACTIONS.replace(/\{\w+\}/ig, accountId.toString());
    return this.http.post(url, stagedData);
  }
}
