export const LANG_DE_TRANS = {
  // global
  'button.save': 'Speichern',
  'button.cancel': 'Abbrechen',
  'button.ok': 'OK',
  'button.close': 'Schließen',
  'button.back': 'Zurück',
  'button.next': 'Weiter',
  'button.remove': 'Löschen',
  'button.yes': 'Ja',
  'button.no': 'Nein',
  'button.edit': 'Bearbeiten',
  'button.detail': 'Detail',
  'button.saveAndFinish': 'Speichern und beenden',
  'button.correctTheSetting': 'Korrigieren Sie die Einstellung',
  'generic.select.other': 'Andere...',
  'session.isExpired': 'Ihre Sitzung# ist abgelaufen. Bitte melden Sie sich erneut an.',
  'button.select': 'Wählen',
  'button.sendInvitation': 'Einladung senden',

  // Login page
  'loginPage.title' : 'Anmelden bei Continuity',
  'loginPage.email' : 'E-Mail',
  'loginPage.password' : 'Passwort',
  'loginPage.remember-me' : 'Erinnere mir',
  'loginPage.signIn' : 'Anmelden',
  'loginPage.forgotPassword' : 'Passwort vergessen?',
  'loginPage.newToContinuity' : 'Neu bei Continuity?',
  'loginPage.createAccount' : 'Registrieren',
  'loginPage.or' : 'oder',
  'loginPage.signInWithFacebook' : 'Mit Facebook anmelden',
  'loginPage.signInWithGoogle' : 'Anmeldung mit Google',
  'loginPage.message.invalidEmailPassword': 'Ungültige E-Mail oder Passwort',
  'loginPage.message.loginFail': 'Die Anmeldung war nicht erfolgreich',
  'loginPage.message.emptyField': 'E-Mail und Passwort sind erforderlich',

  // Register page
  'registerPage.title': 'Registrierung',
  'registerPage.nickName': 'Spitzname',
  'registerPage.email': 'E-Mail',
  'registerPage.password': 'Passwort',
  'registerPage.confirmPassword': 'Passwort bestätigen',
  'registerPage.language': 'Sprache',
  'registerPage.signUp': 'Anmelden',
  'registerPage.nickNameRequired': 'Spitzname ist erforderlich',
  'registerPage.emailRequired': 'E-Mail ist nötig',
  'registerPage.emailInvalid': 'E-Mail Adresse ist nicht korrekt',
  'registerPage.passwordRequired': 'Passwort ist nötig',
  'registerPage.passwordInvalid': 'Das Passwort muss mindestens 6 Zeichen lang sein',
  'registerPage.confirmPasswordRequired': 'Die Bestätigung des Passworts ist erforderlich',
  'registerPage.confirmPasswordNotMatched': 'Die Passwörter sind unterschiedlich',
  'registerPage.languageRequired': 'Sprache ist nötige',
  'registerPage.message.success': 'Du hast dich erfolgreich registriert. Bitte überprüfen Sie Ihre E-Mails, um Ihr Konto zu bestätigen.',
  'registerPage.message.fail': 'Die Anmeldung war nicht erfolgreich',
  'registerPage.agreed.fail': 'Sie können Continuity verwenden, wenn Sie unsere Nutzungsbedingungen und unsere Datenschutz-Bestimmungen akzeptiert haben.',
  'registerPage.readMessage': 'Ich habe gelesen und stimme ihnen zu',
  'registerPage.termOfService': 'die Nutzungsbedingungen',
  'registerPage.privacyPolicy': 'die Datenschutz-Bestimmungen',

  // active user page
  'activeAccountPage.verifySuccess': 'Sie haben Ihre Registrierung erfolgreich überprüft',
  'activeAccountPage.verifyFail': 'Die Überprüfung der Registrierung war nicht erfolgreich',

  // Accounts page
  'accountsPage.header.currentBalance': 'Kontostand',
  'accountsPage.header.totalBalance': 'Gesamtsaldo',
  'accountsPage.generalInformation' : 'Allgemeine Information',
  'accountsPage.giroNumber' : 'Kontonummer',
  'accountsPage.swift' : 'SWIFT/BIC',
  'accountsPage.lastUpdate': 'Zuletzt aktualisiert',
  'accountsPage.addTransaction': 'Transaktion hinzufügen',
  'accountsPage.uploadTransaction': 'Transaktion hochladen',
  'accountsPage.updateAt': 'Aktualisiert am',
  'accountsPage.name': 'Name',
  'accountsPage.nameRequired': 'Name ist erforderlich',
  'accountsPage.nature': 'Art',
  'accountsPage.natureRequired': 'Art ist erforderlich',
  'accountsPage.source': 'Eingabemethode der Daten',
  'accountsPage.sourceRequired': 'Eingabemethode ist erforderlich',
  'accountsPage.currency': 'Währung',
  'accountsPage.currencyRequired': 'Währung ist erforderlich',
  'accountsPage.initialBalance': 'Anfangsbilanz',
  'accountsPage.initialDate': 'Wählen Sie das Datum des anfänglichen Saldos',
  'accountsPage.accountDetail': 'Kontodetails',
  'accountsPage.iban': 'IBAN',
  'accountsPage.accountNumber': 'Kontonummer',
  'accountsPage.bic': 'BIC',
  'accountsPage.ibanOrAccountNumberRequired': 'IBAN oder Kontonummer ist erforderlich',
  'accountPage.message.updateSuccess' : 'Die Kontodaten wurden erfolgreich aktualisiert',
  'accountPage.message.updateError' : 'Die Aktualisierung der Kontodaten war nicht erfolgreich',
  'accountPage.message.createSuccess' : 'Ihr Konto wurde erfolgreich erstellt',
  'accountPage.message.createError' : 'Die Erstellung des Kontos war nicht erfolgreich',
  'accountPage.nature.ACCOUNT': 'Bankkonto',
  'accountPage.nature.CASH': 'Bargeld',
  'accountPage.nature.CREDIT': 'Kredit',
  'accountPage.nature.CREDIT_CARD': 'Kreditkarte',
  'accountPage.nature.BUILDING_SOCIETY': 'Bausparkasse',
  'accountPage.nature.INVESTMENT': 'Investitions Konto',
  'accountPage.nature.ASSET': 'Vermögenswert',
  'accountPage.source.MANUAL': 'Manuell',
  'accountPage.source.FILE': 'Datei',
  'accountPage.source.TRANSFER': 'Transfer',
  'accountsPage.invalidPattern': 'Ungültiges Zahlenformat',
  'accountPage.remove.success' : 'Das Konto wurde erfolgreich gelöscht',
  'accountPage.remove.fail' : 'Beim Löschen des Kontos ist ein Fehler aufgetreten',
  'accountPage.removeConfirmation.message' : 'Möchten Sie dieses Konto wirklich löschen?',
  'accountPage.ibanAccountNumber.waningMessage': 'Dienste wie die automatische Kategorisierung funktionieren besser, wenn Sie Ihre Kontonummer oder IBAN angeben.',
  'accountsPage.generalInformationNotice': 'Diese Einstellungen können später nicht mehr geändert werden. Sorgfältig auswählen!',
  'accountsPage.accountDetailNotice': 'Sie können diese Daten später eingeben, aber nachdem Sie sie gespeichert haben, können Sie sie nicht mehr ändern.',

  // profile detail page
  'profilePage.name' : 'Name',
  'profilePage.nameRequired' : 'Name ist erforderlich',
  'profilePage.currency' : 'Währung',
  'profilePage.currencyRequired' : 'Währung ist erforderlich',
  'profilePage.message.getFail' : 'Die Anzeige der Profilinformationen war nicht erfolgreich',
  'profilePage.message.updateSuccess' : 'Dein Profil wurde erfolgreich aktualisiert',
  'profilePage.message.updateFail' : 'Die Aktualisierung Ihres Profils war nicht erfolgreich',
  'profilePage.profileImage' : 'Profilbild',
  'profilePage.profileImageSelect' : 'Bitte wählen Sie Ihr Profilbild',
  'profilePage.selectFileToUpload': 'Wählen Sie die hochzuladende Datei aus',
  'profilePage.cropImage': 'Bild zuschneiden',
  'profilePage.preview': 'Vorschau',

  // transaction page
  'transactionPage.note' : 'Notiz',
  'transactionPage.comment' : 'Kommentar',
  'transactionPage.selectLabelOfCategory': 'Wählen Sie die Kategorie',
  'transactionPage.direction': 'Richtung',
  'transactionPage.nameOfPartner': 'Name des Partners',
  'transactionPage.amount': 'Betrag',
  'transactionPage.currency': 'Währung',
  'transactionPage.dateOfTransaction': 'Datum der Transaktion',
  'transactionPage.regularFlag': 'Regelmäßigkeit',
  'transactionPage.description': 'Beschreibung',
  'transactionPage.ibanOfPartner': 'IBAN des Partners',
  'transactionPage.accountBicOfPartner': 'BIC der Bank des Partners',
  'transactionPage.accountNumberOfThePartner': 'Kontonummer des Partners',
  'transactionPage.cardNumber': 'Kartennummer',
  'transactionPage.originalAmount': 'Ursprünglichen Betrag',
  'transactionPage.originalCurrency': 'Originalwährung',
  'transactionPage.dateOfBooking': 'Datum der Buchung',
  'currency.required': 'Währung ist erforderlich',
  'amount.required': 'Betrag ist erforderlich',
  'amount.min': 'Negative Betrag ist nicht erlaubt',
  'nameOfPartner.required': 'Name des Partners ist erforderlich',
  'dateOfTransaction.required': 'Datum der Transaktion ist erforderlich',
  'transactionPage.generalInformation': 'Allgemeine Information',
  'transactionPage.accountInformation': 'Kontoinformationen',
  'transactionPage.message.createSuccess': 'Die Transaktion wurde erfolgreich erstellt',
  'transactionPage.message.createFail': 'Die Erstellung der Transaktion ist fehlgeschlagen',
  'transactionPage.message.getAccountFail': 'Das Abrufen von Kontoinformationen ist fehlgeschlagen',
  'transactionPage.message.getLabelFail': 'Das Abrufen der Etiketteninformationen ist fehlgeschlagen',
  'transactionPage.message.getTransactionFail': 'Das Abrufen von Transaktionsinformationen ist fehlgeschlagen',
  'transactionPage.message.updateSuccess': 'Die Transaktion wurde erfolgreich aktualisiert',
  'transactionPage.message.updateFail': 'Die Aktualisierung der Transaktion ist fehlgeschlagen',
  'transactionPage.message.getBalanceFail': 'Das Abrufen von Kontostandinformationen ist fehlgeschlagen',
  'transactionPage.message.getDefaultLabelCategory': 'Das Abrufen der Voreinstellung zur Etikettenkategorie ist fehlgeschlagen',
  'transactionPage.message.getListOfTransactionFail': 'Das Abrufen der Liste der Transaktionen ist fehlgeschlagen',
  'transactionPage.regularFlag.NO': 'Nein',
  'transactionPage.regularFlag.WEEKLY': 'Wöchentlich',
  'transactionPage.regularFlag.MONTHLY': 'Monatlich',
  'transactionPage.regularFlag.EVERY_SECOND_MONTH': 'Jeden zweiten Monat',
  'transactionPage.regularFlag.QUARTERLY': 'Vierteljährlich',
  'transactionPage.regularFlag.EVERY_HALF_YEAR': 'Halbjährlich',
  'transactionPage.regularFlag.YEARLY': 'Jährlich',
  'transactionPage.regularFlag.OTHER': 'Andere',
  'transactionPage.addNewTransaction': 'Fügen Sie eine neue Transaktion hinzu',
  'transactionPage.updateTransaction': 'Aktualisieren Sie die Transaktion',
  'transactionPage.removeTransaction.success': 'Die Transaktion wurde erfolgreich gelöscht',
  'transactionPage.removeTransaction.fail': 'Das Löschen der Transaktion ist fehlgeschlagen',
  'transactionPage.removeConfirmation.message': 'Sind Sie sicher, dass Sie diese Transaktion löschen möchten?',

  // UPLOAD-CSV SETTING PAGE
  'CSV-LOADER.FILE': 'Datei',
  'CSV-LOADER.FIRST-STEP.TITLE': 'Definieren Sie eine neue Dateistruktur',
  'CSV-LOADER.FIRST-STEP.SELECT-CSV': 'Wählen Sie eine CSV-Datei aus',
  'CSV-LOADER.FIRST-STEP.PDF': 'Nur PDF-Datei#',
  'CSV-LOADER.FIRST-STEP.PLEASE-SELECT': 'Bitte wählen Sie eine Datei aus',
  'File.required': 'Datei ist erforderlich',
  'CSV-LOADER.SECOND-STEP.TITLE': 'Loader aufstellen',
  'CSV-LOADER.SECOND-STEP.PREVIEW': 'Vorschau',
  'CSV-LOADER.SECOND-STEP.CONFIGURATION': 'Konfiguration',
  'CSV-LOADER.SECOND-STEP.DELIMITER': 'Trennzeichen',
  'CSV-LOADER.SECOND-STEP.QUOTE-CHARACTER': 'Zitat Charakter',
  'CSV-LOADER.SECOND-STEP.CHARACTER-SET': 'Zeichensatz',
  'CSV-LOADER.SECOND-STEP.HEADER-ROW': 'Wählen Sie Anzahl der Kopfzeilen',
  'numberOfHeaderLines.min.0': 'Die Anzahl der Kopfzeilen darf nicht kleiner als 0 sein',
  'CSV-LOADER.SECOND-STEP.TRY-PARSE': 'Versuchen Sie zu analysieren',
  'CSV-LOADER.THIRD-STEP.TITLE': 'Vorschau der analysierten Daten',
  'CSV-LOADER.THIRD-STEP.SELECT_COLUMN_INSTRUCTIONS': 'Bitte wählen Sie mindestens folgende Spalten aus:',
  'dateFormat.required': 'Datumsformat ist erforderlich',
  'numberFormat.required': 'Zahlenformat ist erforderlich',
  'CSV-LOADER.FOURTH-STEP.STAGE-DATA': 'Abschnitt Daten',
  'CSV-LOADER.SECOND-STEP.SELECT-REQUIRED-COLUMNS': 'Bitte wählen Sie die gewünschten Spalten aus:',

  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SEMICOLON': 'Semikolon',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COMMA': 'Komma',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.COLON': 'Doppelpunkt',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.PIPE': 'Leitung',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.HASHMARK': 'Hashmark',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.SPACE': 'Raum',
  'CSV-LOADER.TRY-PARSE.DELIMITER.SELECT.TAB': 'Tabulator',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.NONE': 'Keiner',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.DOUBLE-QUOTE': 'Doppeltes Zitat',
  'CSV-LOADER.TRY-PARSE.QUOTE-CHAR.SELECT.SINGLE-QUOTE': 'Einfaches Zitat',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.BACKSLASH' : 'Backslash',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.DOUBLE-QUOTE' : 'Doppeltes Zitat',
  'CSV-LOADER.TRY-PARSE.ESCAPE-CHAR.SELECT.SINGLE-QUOTE' : 'Einfaches Zitat',
  'FIELDS.TRANSACTION.IGNORE': 'Ignorieren Sie diese Spalte',
  'FIELDS.TRANSACTION.amount': 'Betrag',
  'FIELDS.TRANSACTION.currency': 'Währung',
  'FIELDS.TRANSACTION.dateOfTransaction': 'Datum der Transaktion',
  'FIELDS.TRANSACTION.dateOfBooking': 'Datum der Buchung',
  'FIELDS.TRANSACTION.amountIn': 'Betrag in',
  'FIELDS.TRANSACTION.amountOut': 'Betrag aus',
  'FIELDS.TRANSACTION.directionFlag': 'Richtung',
  'FIELDS.TRANSACTION.namePartner': 'Name des Partners',
  'FIELDS.TRANSACTION.note': 'Notiz',
  'FIELDS.TRANSACTION.ibanPartner': 'IBAN des Partners',
  'FIELDS.TRANSACTION.accountBicPartner': 'BIC des Partners',
  'FIELDS.TRANSACTION.accountNumberPartner': 'Kontonummer des Partners',
  'FIELDS.TRANSACTION.cardNo': 'Kartennummer',
  'FIELDS.TRANSACTION.amountOrig': 'Originalbetrag',
  'FIELDS.TRANSACTION.currencyOrig': 'Originalwährung',
  'FIELDS.TRANSACTION.typeOfTransaction': 'Art der Transaktion',
  'FIELDS.TRANSACTION.description': 'Beschreibung',
  'FIELDS.TRANSACTION.externalId': 'Externe ID',
  'CSV-LOADER.MAP-COLUMNS.INCOME.LABEL' : 'Einkommenszeichen',
  'CSV-LOADER.MAP-COLUMNS.EXPENSE.LABEL' : 'Ausgabenszeichen',
  'CSV-LOADER.MAP-COLUMNS.USE-SIGN.LABEL': 'Verwenden Sie das Vorzeichen des Betrags',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.CUSTOM.LABEL': 'Custom format#',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.LABEL': 'Bitte wählen Sie das Datumsformat',
  'CSV-LOADER.MAP-COLUMNS.NUMBER-FORMAT.LABEL' : 'Bitte wählen Sie das Zahlenformat',
  'CSV-LOADER.MAP-COLUMNS.DATE-FORMAT.SUGGEST-BUTTON': 'Formate vorschlagen',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SEPARATOR.LABEL': 'Dezimaltrennzeichen',
  'CSV-LOADER.MAP-COLUMNS.DECIMAL-SUGGEST-BUTTON': 'Erraten Sie das Dezimaltrennzeichen',
  'CSV-LOADER.MAP-COLUMNS.ERROR.NO-DATE-COLUMN': 'Bitte wählen Sie die Spalte "Datum der Transaktion"',
  'CSV-LOADER.SAVE.TITLE': 'Laden Sie Daten in das Konto hoch',
  'CSV-LOADER.SAVE.RESULT': 'Ergebnis hochladen',
  'CSV-LOADER.SAVE.RESULT.SUCCESS': 'Sie haben Transaktionen erfolgreich hochgeladen.',
  'CSV-LOADER.SAVE.RESULT.FAIL': 'Das Hochladen der Transaktionen ist fehlgeschlagen.',
  'CSV-LOADER.SAVE.INVALID-AMOUNT': 'Ungültiger Betrag',
  'CSV-LOADER.SAVE.SAVE-SETTING-FAIL': 'Das Speichern der Einstellungen ist fehlgeschlagen',
  'CSV-LOADER.SAVE.SAVE-STAGE-DATA-FAIL': 'Das Speichern von bereitgestellten Daten ist fehlgeschlagen',
  'CSV-LOADER.SAVE.CANCEL.SUCCESS': 'Sie haben das Hochladen von Transaktionen abgebrochen',
  'CSV-LOADER.OVERLAPPING.MODAL.TITLE': 'Überlappende Transaktionen',
  'CSV-LOADER.OVERLAPPING.MODAL.SUBTITLE': 'Es gibt eine Überlappung zwischen den Zeitintervallen der zuvor aufgezeichneten Transaktionen und dem aktuellen Satz von Transaktionen.',
  'CSV-LOADER.OVERLAPPING.MODAL.SELECT': 'Bitte wählen Sie Ihre Aktionen:',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Append': 'Behalten Sie die bereits geladenen Transaktionen bei und fügen Sie die Differenz hinzu.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Substitute': 'Ersetzen Sie die zuvor geladenen Transaktionen durch die aktuell geladenen neuen.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Merge': 'Führen Sie die früheren und die aktuellen Uploads zusammen und behalten Sie die Transaktionen beider bei.',
  'CSV-LOADER.OVERLAPPING.MODAL.ACTION.Cancel': 'Brechen Sie der Hochladung ab.',

  // first-time-login
  'firstTimeLoginPage.title' : 'Willkommen zur Continuity',
  'firstTimeLoginPage.subTitle': 'Bitte erstellen Sie Ihr erstes Konto',

  // menu items
  'menu.item.Overview': 'Übersicht',
  'menu.item.AccountList': 'Konten',
  'menu.item.Profile': 'Profil',
  'menu.item.Analytics': 'Analytik',
  'menu.item.signOut': 'Abmelden',
  'menu.item.deleteMyAccount': 'Löschen Sie mein Kundenkonto',
  'menu.item.Cash-flow': 'Cash-flow',
  'menu.item.inviteNewUser': 'Neuen Benutzer einladen',
  'menu.item.help': 'Hilfe',

  // enum icons
  'enum.name.ACCOMODATION' : 'Unterkunft',
  'enum.name.TRANSPORT' : 'Transport',
  'enum.name.MEALS' : 'Essen',
  'enum.name.CLOTHING' : 'Kleidung',
  'enum.name.ALCOHOL_TOBACCO' : 'Alkohol & Tabak',
  'enum.name.BANK' : 'Bank',
  'enum.name.EDUCATION' : 'Bildung',
  'enum.name.ENTERTAINMENT' : 'Unterhaltung',
  'enum.name.GIFT' : 'Geschenk',
  'enum.name.HOBBY' : 'Hobby',
  'enum.name.SPORT' : 'Sport',
  'enum.name.HOLIDAY' : 'Urlaub',
  'enum.name.HYGIENE_HOUSEHOLD' : 'Hygiene & Haushalt',
  'enum.name.TELECOMMUNICATION' : 'Telekommunikation',
  'enum.name.HEALTH_CARE' : 'Gesundheitsvorsorge',
  'enum.name.OTHER' : 'Sonstige',
  'enum.name.SPOUSE' : 'EhepartnerIn',
  'enum.name.MYSELF' : 'Mich selber',
  'enum.name.MOM' : 'Mama',
  'enum.name.DAD' : 'Papa',
  'enum.name.FAMILY' : 'Familie',
  'enum.name.FRIENDS' : 'Freunde',
  'enum.name.IN-LAWS' : 'Große Familie',
  'enum.name.CHILD1' : 'Kind1',
  'enum.name.CHILD2' : 'Kind2',
  'enum.name.CHILD3' : 'Kind3',
  'enum.name.CHILD4' : 'Kind4',
  'enum.name.CHEMICALS' : 'Chemikalien',
  'enum.name.ENERGY' : 'Energie',
  'enum.name.EQUIPMENT' : 'Anlage',
  'enum.name.FIXTURE' : 'Befestigung',
  'enum.name.FOOD' : 'Lebensmittel',
  'enum.name.GADGET' : 'Gerät',
  'enum.name.INSURANCE' : 'Versicherung',
  'enum.name.MAINTENANCE' : 'Instandhaltung',
  'enum.name.PENALTIES' : 'Strafen',
  'enum.name.RENTAL' : 'Mietung',
  'enum.name.TAX' : 'Steuer',
  'enum.name.TICKET_SERVICE' : 'Karte / Bedienung',
  'enum.name.EMPLOYER' : 'Arbeitgeber',
  'enum.name.CLIENT' : 'Kunde',
  'enum.name.EARNING_FROM_INVESTMENT' : 'Mit Investitionen verdienen',
  'enum.name. RENTAL' : 'Vermietung',
  'enum.name.SALE_OF_ASSET' : 'Verkauf von Vermögenswerten',
  'enum.name.PET' : 'Haustier',
  'enum.name.STATE' : 'Staat',
  'enum.name.TRANSFER' : 'Transfer',
  'enum.name.INCOME_LABELED_AS_TRANSFER' : 'Gegenstück fehlt',
  'enum.name.EXPENSE_LABELED_AS_TRANSFER' : 'Gegenstück fehlt',
  'enum.name.SUPPORT' : 'Unterstützung',
  'enum.name.SALARY' : 'Gehalt',
  'enum.category.TRANSFER' : 'Übertragung',
  'enum.category.TITLE': 'Titel',
  'enum.category.PAYING': 'Bezahler',
  'enum.category.PURPOSE': 'Zweck',
  'enum.category.BENEFICIARY': 'Begünstigter',
  'enum.category.TOOL_SERVICE': 'Gerät & Bedienung',
  'enum.category.THIS_MONTH': 'Diesen Monat',
  'enum.category.LAST_MONTH': 'Letzten Monat',
  'enum.category.THIS_YEAR': 'Dieses Jahr',
  'enum.category.LAST_YEAR': 'Vergangenes Jahr',
  'enum.category.THIS_WEEK': 'Diese Woche',
  'enum.category.LAST_WEEK': 'Letzte Woche',
  'enum.category.TODAY': 'Heute',
  'enum.category.CUSTOM': 'Benutzerdefiniert',

  // direction flag
  'direction.flag.INCOME': 'Einkommen',
  'direction.flag.EXPENSE': 'Ausgaben',
  'direction.flag.OUT_GOING_TRANSFER': 'Ausgehende Übertragung',
  'direction.flag.INCOMING_TRANSFER': 'Eingehende Übertragung',

  // analytics page
  'analyticsPage.incomeChart': 'Einkommensdiagramm',
  'analyticsPage.expenseChart': 'Ausgabensdiagramm',
  'analyticsPage.noIncomeData': 'Keine Einkommensdaten verfügbar',
  'analyticsPage.noExpenseData': 'Keine Ausgabensdaten verfügbar',
  'analyticsPage.getLabelFail': 'Liste der Labels nach Kategorie erfolglos abrufen',
  'analyticsPage.getChartDataFail': 'Abrufen von Diagrammdaten erfolglos',
  'analyticsPage.totalAmount': 'Gesamtbetrag',
  'analyticsPage.selectIncomeCategory': 'Wählen Sie Einkommenskategorie',
  'analyticsPage.selectExpenseCategory': 'Wählen Sie Ausgabenkategorie',
  'analyticsPage.selectTimePeriod': 'Wählen Sie Zeitraum',
  'analyticsPage.from': 'Ab',
  'analyticsPage.to': 'Bis',

  // cash-flow page
  'cash-flow.selectYourAccount': 'Wählen Sie Ihr Konto',
  'cash-flow.allAccountOfYourProfile': 'Alle Konten Ihres Profils',
  'cash-flow.chartHeader': 'Cashflow Ihres Kontos',
  'cash-flow.subtextOfAnAccount': 'Konto: ',
  'cash-flow.subtextOfAllAccount': 'Alle Konten Ihres Profils',
  'cash-flow.warningMessage': 'Die Cashflow-Berechnung ist nur ein Jahr im Voraus verfügbar.',

  // invite-users page
  'inviteUserPage.email': 'Email',
  'inviteUserPage.message': 'Nachricht',
  'inviteUserPage.emailRequired': 'E-Mail ist erforderlich',
  'inviteUserPage.emailInvalid': 'Email muss eine gültige Email-Adresse sein',
  'inviteUserPage.messageRequired': 'Nachricht ist erforderlich',
  'inviteUsersPage.message.sendSuccess': 'Erfolgreiche Einladung',
  'inviteUsersPage.message.sendFail': 'Die Einladung ist fehlgeschlagen',
  'inviteUsersPage.message.defaultMessage': 'Hallo, \
\
Ich verwende Continuity, um meine Finanzen zu kontrollieren und meinen Cashflow zu planen. Ich schlage vor, Sie probieren es aus.',
  'inviteUsersPage.email.subject': 'hat Sie eingeladen, Continuity zu verwenden.',
  'inviteUsersPage.email.button': 'Registrieren Sie sich bei Continuity',

  // error message
  'error.INITIAL_BALANCE_IS_LATER' : 'Warnung! Das Datum der Transaktion liegt vor dem Datum des anfänglichen Saldos. Ändern Sie das Datum Ihres ersten Guthabens!',
  'error.FAILED' : 'Die Berechnung der Salden ist fehlgeschlagen',

  // tool tip
  // add account page
  'toolTip.addAccount.name': 'Geben Sie Ihrem Konto einen Namen. Es kann sich von seinem Namen bei Ihrer Bank unterscheiden. Nur ein Name, an den Sie sich erinnern werden.',
  'toolTip.addAccount.nature': 'Die Datenverarbeitung unterscheidet sich je nach Art des Kontos.',
  'toolTip.addAccount.source': 'Sie können Ihre Transaktionen aus einer Datei hochladen, die von Ihrer Netbank heruntergeladen wurde. Es wird empfohlen, die Bargeldtransaktionsdaten so bald wie möglich in der mobilen Version von Continuity einzugeben.',
  'toolTip.addAccount.currency': 'Wenn Sie mehr als eine Währung haben, benötigen Sie für jede ein Konto!',
  'toolTip.addAccount.dateOfInitialBalance': 'Wählen Sie ein Datum aus, dessen Tagesendstand Sie kennen!',
  'toolTip.addAccount.initialBalance': 'Geben Sie den Tagesabschluss ein. Es ist optional, wird aber für die korrekte Bilanzberechnung benötigt.',
  'toolTip.addAccount.iban': 'Die internationale Bankkontonummer. Beginnt mit dem aus zwei Buchstaben bestehenden Ländercode. Optional, wenn Sie es ausfüllen, wird Continuity die Überweisungen zwischen Ihren Konten einfacher identifizieren.',
  'toolTip.addAccount.accountNumber': 'Inländische Bankkontonummer. Optional, wenn Sie es ausfüllen, wird Continuity die Überweisungen zwischen Ihren Konten einfacher identifizieren.',
  'toolTip.addAccount.bic': 'Die internationale Kennung Ihrer Bank. Auch als Swift-Code bekannt. Optional, wenn Sie es ausfüllen, wird Continuity die Überweisungen zwischen Ihren Konten einfacher identifizieren.',
  'toolTip.addAccount.saveBtn': 'Sie können die Kontodaten nach dem Speichern ändern. Wenn das Konto jedoch bereits eine Transaktion hat, können nur der Name und der anfängliche Kontostand geändert werden.',
  'toolTip.addAccount.resetBtn': 'Löschen Sie die zuvor eingegebenen Daten.',

  // edit account page###
  'toolTip.editAccount.name': 'Der Name Ihres Kontos - Sie können ihn jederzeit ändern.',
  'toolTip.editAccount.nature': 'Wenn das Konto eine Transaktion hat, ist die Art des Kontos festgelegt.',
  'toolTip.editAccount.source': 'Wenn das Konto eine Transaktion hat, ist die Quelle des Kontos festgelegt.',
  'toolTip.editAccount.currency': 'Wenn das Konto eine Transaktion hat, ist die Währung des Kontos festgelegt.',
  'toolTip.editAccount.dateOfInitialBalance': 'Wählen Sie ein Datum aus, dessen Tagesendstand Sie kennen!',
  'toolTip.editAccount.initialBalance': 'Geben Sie den Tagesabschluss ein. Es ist optional, wird aber für die korrekte Bilanzberechnung benötigt.',
  'toolTip.editAccount.iban': 'Wenn das Konto eine Transaktion hat, ist die IBAN des Kontos festgelegt.',
  'toolTip.editAccount.accountNumber': 'Wenn das Konto eine Transaktion hat, ist die Kontonummer des Kontos festgelegt.',
  'toolTip.editAccount.bic': 'Wenn das Konto eine Transaktion hat, ist der BIC des Kontos festgelegt.',
  'toolTip.editAccount.saveBtn': 'Aktualisieren Sie das Konto mit den aktuell eingegebenen Daten.',
  'toolTip.editAccount.cancelBtn': 'Stellen Sie die vorherigen Kontodaten wieder her.',
  'toolTip.editAccount.deleteBtn': 'Löschen Sie das Konto mit allen Transaktionen und Einstellungen.',

  // add transaction page
  'toolTip.addTransaction.direction': 'Die Richtung der Transaktion setzt ihr Vorzeichen. Wenn es sich bei der Transaktion um einen Korrektureintrag handelt, können Sie das Vorzeichen später ändern, indem Sie die Transaktion erneut öffnen.',
  'toolTip.addTransaction.dateOfTransaction': 'Vergessen Sie nicht festzulegen, ob die Transaktion in der Vergangenheit liegt!',
  'toolTip.addTransaction.amount': 'Geben Sie den Betrag ohne Vorzeichen ein!',
  'toolTip.addTransaction.namePartner': 'Das Unternehmen oder die Person, die Sie bezahlt haben oder die Sie bezahlt hat.',
  'toolTip.addTransaction.regularFlag': 'Die Continuity berechnet einen einjährigen Cashflow-Plan basierend auf den regulären Transaktionen. Wenn die ähnliche Transaktion bereits früher als regulär markiert wurde, markieren Sie sie nicht erneut, da dies zu doppelten Werten im Plan führen kann.',
  'toolTip.addTransaction.description': 'Informationen über die Transaktion, die für Sie wichtig sind.',
  'toolTip.addTransaction.note': 'Ihre Notiz.',
  'toolTip.addTransaction.saveBtn': 'Nachdem Sie die Transaktion gespeichert haben, können Sie sie im Fehlerfall korrigieren.',
  'toolTip.addTransaction.cancelBtn': 'Löschen Sie die eingegebenen Daten.',

  // the following seven fields do not appear on the data entry form
  'toolTip.addTransaction.ibanPartner': 'Die internationale Bankkontonummer Ihres Partners. Beginnt mit dem aus zwei Buchstaben bestehenden Ländercode.',
  'toolTip.addTransaction.accountBicPartner': 'Die internationale Kennung der Bank Ihres Partners. Auch als Swift-Code bekannt.',
  'toolTip.addTransaction.accountNumberPartner': 'Die inländische Bankkontonummer des Partners.',
  'toolTip.addTransaction.cardNo': 'Die Nummer der für die Transaktion verwendeten Zahlungskarte.',
  'toolTip.addTransaction.amountOrig': 'Der ursprüngliche Betrag, wenn sich die Währung der Transaktion von der Währung des Kontos unterscheidet.',
  'toolTip.addTransaction.currency': 'Die Währung der Transaktion, wenn sie sich von der Währung des Kontos unterscheidet.',
  'toolTip.addTransaction.dateOfBooking': 'Das Datum, an dem Ihre Bank oder Ihr Finanzdienstleister die Transaktion gebucht hat.',

  // edit transaction page
  'toolTip.editTransaction.directionFlag': 'Sie können eine positive Transaktion im Falle einer Korrekturbuchung als Aufwand markieren. Zum Beispiel eine Rückerstattung von einer überbezahlten Rechnung. (Dies senkt Ihre Kosten, anstatt Ihr Einkommen zu erhöhen.)',
  'toolTip.editTransaction.dateOfTransaction': 'Die Zeit, zu der die Zahlung erfolgt ist. Wenn aus einer Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.amount': 'Der Betrag in der Währung des Kontos. Wenn aus einer Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.namePartner': 'Das Unternehmen oder die Person, die Sie bezahlt haben oder die Sie bezahlt hat. Wenn aus einer Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.regularFlag': 'Die Continuity berechnet einen einjährigen Cashflow-Plan basierend auf den regulären Transaktionen. Bitte markieren Sie nur die letzte Instanz der ähnlichen Transaktionen.',
  'toolTip.editTransaction.description': 'Wenn aus einer Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.note': 'Wenn aus einer Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.ibanPartner': 'Aus der Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.accountBicPartner': 'Aus der Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.accountNumberPartner': 'Aus der Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.cardNo': 'Die Nummer der für die Transaktion verwendeten Zahlungskarte. Aus der Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.amountOrig': 'Der ursprüngliche Betrag, wenn sich die Währung der Transaktion von der Währung des Kontos unterscheidet. Aus der Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.currencyOrig': 'Die Währung der Transaktion, wenn sie sich von der Währung des Kontos unterscheidet. Aus der Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.dateOfBooking': 'Das Datum, an dem Ihre Bank oder Ihr Finanzdienstleister die Transaktion gebucht hat. Aus der Datei geladen, können Sie nicht manuell ändern.',
  'toolTip.editTransaction.saveBtn': 'Änderungen speichern.',
  'toolTip.editTransaction.cancelBtn': 'Stellen Sie die vorherigen Daten wieder her.',
  'toolTip.editTransaction.removeBtn': 'Sie können die Transaktion löschen, wenn es sich um eine Duplizierung handelt.',

  // account upload csv page
  'toolTip.accountUpload.fileName': 'Die folgenden Einstellungen müssen für einen Dateityp nur einmal vorgenommen werden. Continuity speichert die Einstellungen beim nächsten Hochladen einer Datei in dieses Konto.',
  'toolTip.accountUpload.delimiter': 'Continuity erkennt die Trennzeichen. Ändern Sie es nur, wenn Sie sicher sind.',
  'toolTip.accountUpload.quote': 'Continuity erkennt die Anführungszeichen um die Daten. Ändern Sie es nur, wenn Sie sicher sind.',
  'toolTip.accountUpload.charset': 'Continuity erkennt den Zeichensatz der Datei. Ändern Sie es nur, wenn Sie sicher sind.',
  'toolTip.accountUpload.numberOfHeaderLines': 'Legen Sie die Anzahl der Zeilen am Anfang der Datei fest, die keine Daten enthalten.',
  'toolTip.accountUpload.selectColumn': 'Hilft bei der automatischen Kategorisierung, wenn Sie nicht nur die Pflichtfelder, sondern auch die Notiz sowie den Namen, die Kontonummer und die IBAN des Partners hochladen.',
  'toolTip.accountUpload.dateFormat': 'Beachten Sie die Reihenfolge und die Schreibweise von Jahr, Monat und Tag und achten Sie auf die Trennzeichen. Wählen Sie das Datumsformat!',
  'toolTip.accountUpload.numberFormat': 'Beachten Sie die Dezimal- und Tausendertrennzeichen im Betragsfeld. Wählen Sie das Zahlenformat!',
  'toolTip.accountUpload.backBtn': 'Zurück, um eine Datei auszuwählen.',
  'toolTip.accountUpload.nextBtn': 'Laden Sie die ausgewählte Datei hoch.',
  'toolTip.accountUpload.correctTheSettingBtn': 'Wenn Sie mit der Vorschau nicht zufrieden sind, können Sie hier einen Schritt zurücktreten.',
  'toolTip.accountUpload.saveAndFinishBtn': 'Wenn Sie mit der Vorschau zufrieden sind, beenden Sie den Upload.',

  // profile page
  'toolTip.profileDetail.name': 'Geben Sie Ihrem Profil einen Namen!',
  'toolTip.profileDetail.currency': 'Wählen Sie die Währung Ihres Profils! Wenn Sie mehr als eine Währung haben, wird der Gesamtsaldo in dieser Währung in der Übersicht angezeigt.',
  'toolTip.profileDetail.attachFile': 'Mit einem Bild können Sie Ihr Profil personalisieren.',
  'toolTip.profileDetail.removeBtn': 'Hier können Sie die Löschung Ihres Profils veranlassen. Sobald Ihr Profil gelöscht ist, können Sie sich nicht mehr mit Ihrer zuvor verwendeten E-Mail-Adresse anmelden.',

  // sent new user page
  'toolTip.inviteUsers.email': 'Die Email-Adresse des Eingeladenen. Sie erhalten Ihre Prämie nur, wenn der Eingeladene mit dieser E-Mail Continuity verwendet.',
  'toolTip.inviteUsers.message': 'Machen Sie die Einladung persönlich.',

  // transaction filter modal page
  'transactionFilterModal.title': 'Listen Sie die Transaktionen des Labels auf: ',
  'transactionFilterModal.accountName': 'Kontoname',
  'transactionFilterModal.dateOfTransaction': 'Datum der Transaktion',
  'transactionFilterModal.amount': 'Betrag',
  'transactionFilterModal.descAndNote': 'Beschreibung / Notiz',
  'transactionFilterModal.partnerName': 'Name des Partners',

  // delete my user account modal
  'deleteUserAccount.confirmMessage1': 'Möchten Sie Ihr Konto wirklich löschen?',
  'deleteUserAccount.confirmMessage2': 'Ihre Daten können nach dem Löschen nicht wiederhergestellt werden und Sie können sich später nicht mit denselben Anmeldeinformationen anmelden.\n\rSind Sie sicher?',
  'deleteUserAccount.deleteSuccessfully': 'Das Löschen des Benutzerkontos war erfolgreich.',
  'deleteUserAccount.deleteUnsuccessfully': 'Das Löschen des Benutzerkontos war nicht erfolgreich. Bitte wenden Sie sich an unseren Kundensupport.',

  // help popup
  'helpPopup.step0.title': 'Übersicht',
  'helpPopup.step0.intro': 'Der \"Gesamtsaldo\" ist die Summe der Salden Ihrer Konten. Es wird in der Währung Ihres Profils angezeigt. Hier sehen Sie, wie viel Geld Sie haben. Die Salden Ihrer Konten in einer anderen Währung als Ihrem Profil werden zum letzten Wechselkurs in die Währung des Profils umgerechnet. Unten sehen Sie den aktuellen Saldo aller Ihrer Konten in der Währung des Kontos. Die aktuellen Salden zeigen nur die Fakten, nicht die Pläne. Auf diese Weise werden nur die Ergebnisse der manuell eingegebenen oder aus einer Datei hochgeladenen Transaktionen in die Salden einbezogen, die geplanten Transaktionen werden nicht berücksichtigt. Übrigens können Sie die Währung Ihres Profils im Menü \"Profil\" ändern.',
  'helpPopup.step1.title': 'Konten',
  'helpPopup.step1.intro': 'Hier können Sie Ihrem Profil neue Konten hinzufügen und Informationen zu Ihren bestehenden Konten hinzufügen oder bearbeiten. \
\
Aber was ist ein Konto? \
\
Dieser Begriff bedeutet alles, wo Sie Geld oder andere Vermögenswerte aufbewahren können. Ein Bausparkonto, ein Anlagekonto und sogar Ihre Geldbörse sind also „Konten“. Und wenn mehr Währungen in deiner Geldbörse sind, dann es wird empfohlen, es als separates Konto hinzuzufügen, da ein Konto nur eine Währung haben kann. Bei den Berechnungen werden die angegebenen Eigenschaften Ihrer Konten berücksichtigt, z.B. der Suche nach dem Gegengeschäft einer Überweisung zwischen Ihren Konten. Daher können die Kontoinformationen nachträglich nicht mehr geändert werden. Ausnahmen sind der Name des Kontos und die Informationen zum Eröffnungssaldo. Wenn Sie Informationen falsch eingegeben haben, sollten Sie dieses Konto löschen und neu erstellen. Hoffentlich bemerken Sie einen solchen Fehler bald und Sie verlieren nicht viele Daten, wenn Sie das Konto löschen und neu erstellen.',
  'helpPopup.step2.title': 'Profil',
  'helpPopup.step2.intro': 'Hier können Sie den Namen, die Währung, die Sprache und den Avatar Ihres Profils eingeben oder ändern. Wenn Sie möchten, können Sie auch Ihr Profil löschen, aber überlegen Sie es sich zweimal! Wir werden Sie vermissen und Sie können sich später nicht mehr mit derselben Email-Adresse anmelden.',
  'helpPopup.step3.title': 'Analytik',
  'helpPopup.step3.intro': 'Es zeigt Ihnen, wie viel Sie für bestimmte Zwecke ausgegeben haben, und zeigt die Quellen Ihrer Einnahmen. Wenn Sie das System schon eine Weile verwenden, sollten Sie diese Diagramme analysieren. Wenn Sie zu viele Einnahmen oder Kosten in der Kategorie \"Sonstige\" haben, sollten Sie sich etwas mehr um die Kategorisierung kümmern.',
  'helpPopup.step3.imageUrl': 'https://live.staticflickr.com/65535/51930070200_95da6a62ff_c.jpg',
  'helpPopup.step4.title': 'Cash-flow',
  'helpPopup.step4.intro': 'Basierend auf den Transaktionen, die Sie als regelmäßig markieren oder vom System als regelmäßig wahrgenommen werden, wird ein Cashflow-Plan für Sie erstellt. Dies sollten Sie sowohl kontobezogen als auch insgesamt geordnet prüfen. Wenn der Plan jemals einen negativen Saldo auf einem Konto oder insgesamt aufweist, können Sie ihn immer noch rechtzeitig korrigieren. Sie können beispielsweise eine Überweisung auf das Konto vornehmen, auf dem Ihr Geld ausgeht, oder Sie können eine Ausgabe verschieben oder einen Vorschuss auf einen Ihrer zukünftigen Einnahmen beantragen.',
  'helpPopup.step4.imageUrl': 'https://live.staticflickr.com/65535/51928480722_346507237f_b.jpg',

  // first time login initial help
  'firstTimeLogin.popup.name': 'Geben Sie den Namen Ihres ersten Kontos ein! Sie können einen benutzerdefinierten Namen wählen. Der einzige Punkt ist, dass Sie das Konto anhand seines Namens erkennen sollten. Dieser Name wird im Folgenden im System verwendet. Es wird empfohlen, zuerst Ihre häufig verwendeten Konten hinzuzufügen.',
  'firstTimeLogin.popup.nature': 'Wählen Sie die Art des Kontos! Grundsätzlich hat jeder mindestens eine Brieftasche für Bargeld und ein Girokonto bei einer Bank. Beginnen Sie mit diesen!',
  'firstTimeLogin.popup.source': 'Wählen Sie die Eingabemethode für das Konto aus. Sie müssen Bargeldtransaktionen manuell eingeben. Sie können Banktransaktionen auch mit einer von Ihrer Netbank heruntergeladenen Datei hochladen.',
  'firstTimeLogin.popup.currency': 'Wählen Sie die Währung des Kontos aus. Die Währung Ihrer Konten kann unterschiedlich sein, aber ein Konto kann nur eine Währung haben, die Sie später nicht mehr ändern können. Sie müssen ein neues Konto für eine neue Währung hinzufügen.',
};
