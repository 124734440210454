import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.css']
})
export class ImageCropComponent implements OnInit {

  @Input() croppedImage: any = '';
  @Output() public getUserImage = new EventEmitter<any>();
  imageChangedEvent: any = '';
  @Input() imageL = false;
  constructor() { }

  ngOnInit() {
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imageL = true;
  }

  openInput() {
    // your can use ElementRef for this later
    document.getElementById('fileInput').click();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.getUserImage.emit(this.croppedImage);
  }

  imageLoaded() {
    // show cropper
    this.imageL = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
