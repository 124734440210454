export class AppSettings {

  public static get API_ENDPOINT(): string { return `cc-api/${this.VERSION}/`; }

  public static get API_KEYCLOAK_URL(): string { return '/cc-keycloak/'; }

  public static KEYCLOAK_REAM = 'cc';
  public static CLIENT_ID = 'cc-api';
  public static VERSION = 'v1';

}
