import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { TranslateService } from '../../translate';
import { ToastServices } from '../../shared/toast.service';
import { AccountService } from '../../service/account.service';
import { TransactionEnumsModel } from './transaction-enums.model';
import { TransactionService } from '../../service/transaction.service';
import { DirectionFlag } from '../enums/direction-flag-enums';
import { BasePage } from '../../shared/BasePage';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.css']
})
export class AddTransactionComponent extends BasePage implements OnInit {

  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private commonService: CommonService,
              private translateService: TranslateService,
              private transactionService: TransactionService,
              private accountService: AccountService,
              private toastService: ToastServices) {
    super();
  }

  monthString = '';
  addTransactionForm: FormGroup;
  account: any;
  accountId = 0;
  transactionEnumList = new TransactionEnumsModel();
  labelCategoryList = [];
  selectedLabels = [];
  defaultLabelCategoryList = [];
  isTransferTransaction = false;
  ngOnInit() {
    this.initFormGroup();
    this.initCommonData();
    this.activatedRoute.paramMap.subscribe(params => {
      this.accountId = +params.get('accountId');
      if (params.get('month')) {
        this.monthString = params.get('month');
      }
      this.getAccountInfo();
    });
    this.getDefaultLabelCategory();
  }

  hasError = (controlName: string, errorName: string) => {
    return this.addTransactionForm.controls[controlName].hasError(errorName);
  }

  onSubmit() {
    if (this.addTransactionForm.invalid) {
      return;
    }
    const formValue = this.addTransactionForm.value;
    const transObj = {
      accountId: this.accountId,
      directionFlag: this.getDirectionFlagValue(formValue.directionFlag),
      selectedLabelsId: this.getSelectedLabelId(),
      namePartner: formValue.namePartner,
      amount: this.getAmountByDirection(formValue.directionFlag, this.convertToNumber(formValue.amount)),
      currency: this.account.currency,
      dateOfTransaction: this.getDateValue(formValue.dateOfTransaction),
      description: formValue.description,
      note: formValue.note,
      regularFlag: formValue.regularFlag ? formValue.regularFlag : null,
      ibanPartner: formValue.ibanPartner,
      accountBicPartner: formValue.accountBicPartner,
      accountNumberPartner: formValue.accountNumberPartner,
      dateOfBooking: formValue.dateOfBooking,
      currencyOrig: formValue.currencyOrig ? formValue.currencyOrig : null,
      amountOrig: formValue.amountOrig,
      cardNo: formValue.cardNo,
    };

    this.transactionService.createFiTransaction(this.accountId, transObj).then((result) => {
      this.updateBalance(this.accountId, this.defaultProfileId, result, this.getDateInFormat(transObj.dateOfTransaction.toDate(), 'YYYY-MM-DD'));
      this.toastService.showSuccess(this.translateService.instant('transactionPage.message.createSuccess'));
      const month = this.dateToYYYYMM(transObj.dateOfTransaction.toDate());
      this.router.navigate([`/accounts/${this.accountId}/transactions/list/${month}`]);
    }).catch((err) => {
      this.toastService.showError(this.translateService.instant('transactionPage.message.createFail'));
    });
  }

  updateBalance(accountId, profileId, transactionId, startingDate) {
    this.accountService.recalculateBalance(accountId, profileId, transactionId, startingDate, 'TRANSACTION').catch((err => {
      if (err.error && err.error.errorMessage === 'INITIAL_BALANCE_IS_LATER') {
        this.toastService.showWarning(this.translateService.instant('error.INITIAL_BALANCE_IS_LATER'));
      } else {
        this.toastService.showError(this.translateService.instant('error.FAILED'));
      }
    }));
  }

  initFormGroup() {
    this.addTransactionForm = this.formBuilder.group({
      accountId: this.accountId,
      directionFlag: ['EXPENSE', Validators.required],
      namePartner: [''],
      amount: ['', [Validators.required, Validators.min(0)]],
      dateOfTransaction: [new Date(), Validators.required],
      description: [''],
      note: [''],
      regularFlag: [''],
      ibanPartner: [''],
      accountBicPartner: [''],
      accountNumberPartner: [''],
      dateOfBooking: [''],
      currencyOrig: [''],
      amountOrig: [''],
      cardNo: [''],
    }, {
    });
  }

  getAccountInfo() {
    this.accountService.getAccount(this.accountId).then(account => {
      this.account = account;
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('transactionPage.message.getAccountFail'));
    });
  }

  initCommonData() {
    this.commonService.getTransactionEnumValues().then(result => {
      this.transactionEnumList = result;
      this.transactionEnumList.listDirectionFlag = DirectionFlag.LIST;
    });
  }

  changeDirection(event) {
    this.selectedLabels = [];
    this.labelCategoryList = [];
    if (event.value === 'OUT_GOING_TRANSFER' || event.value === 'INCOMING_TRANSFER') {
      event.value = 'TRANSFER';
      this.isTransferTransaction = true;
    } else {
      this.isTransferTransaction = false;
    }
    this.getLabelList(event.value);
  }

  initLabelList() {
    const formValue = this.addTransactionForm.value;
    if (formValue.directionFlag) {
      this.getLabelList('EXPENSE');
    }
  }

  getDefaultLabelCategory() {
    this.commonService.getDefaultLabelCategory().then(result => {
      this.defaultLabelCategoryList = result;
      this.initLabelList();
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('transactionPage.message.getDefaultLabelCategory'));
    });
  }

  filterType(type: string, directionFlag) {
    if (directionFlag === DirectionFlag.INCOME.value || directionFlag === DirectionFlag.EXPENSE.value) {
      return type === directionFlag;
    } else {
      return type === DirectionFlag.TRANSFER.value;
    }
  }

  getLabelList(direction: string) {
    const categoryList = this.defaultLabelCategoryList.filter( a => {
      return this.filterType(a.directionFlag, direction);
    });
    this.transactionService.getLabelByDirectionFlag(direction).then(result => {
      for (const category of categoryList) {
        for (const i in result) {
          if (category.enumId === i) {
            this.labelCategoryList.push({
              categoryType: i,
              listLabelsOfCategory: result[i],
            });
          }
        }
      }
    }).catch(() => {
      this.toastService.showError(this.translateService.instant('transactionPage.message.getLabelFail'));
    });
  }

  updateLabelSelected(selectedLabel: any) {
    let existed = false;
    for (let label of this.selectedLabels) {
      if (label.labelCategory.enumId.trim() === selectedLabel.label.labelCategory.enumId.trim()) {
        existed = true;
        // replace label
        label = selectedLabel.label;
      }
    }
    if (!existed) {
      this.selectedLabels.push(selectedLabel.label);
    }
  }

  getSelectedLabelId() {
    const selectedList = [];
    for (const label of this.selectedLabels) {
      selectedList.push(label.id);
    }
    return selectedList;
  }

  backToAccountList() {
    if (this.monthString) {
      this.router.navigate([`/accounts/${this.accountId}/transactions/list/${this.monthString}`]);
    } else {
      this.router.navigate([`/accounts`]);
    }
  }

  getAmountByDirection(directionFlag: string, amount: number) {
    if (directionFlag === DirectionFlag.INCOME.value) {
      return amount;
    } else if (directionFlag === DirectionFlag.EXPENSE.value) {
      return -amount;
    } else if (directionFlag === DirectionFlag.INCOMING_TRANSFER.value) {
      return amount;
    } else if (directionFlag === DirectionFlag.OUT_GOING_TRANSFER.value) {
      return -amount;
    }
    return 0;
  }

  getDirectionFlagValue(directionFlag: string) {
    if (directionFlag === DirectionFlag.OUT_GOING_TRANSFER.value || directionFlag === DirectionFlag.INCOMING_TRANSFER.value) {
      return 'TRANSFER';
    } else {
      return directionFlag;
    }
  }

  public orderListInAlphabetical(listLabelsOfCategory) {
    return this.orderListInAlphabeticalWithSelectedLanguage(this.translateService, listLabelsOfCategory);
  }
}
