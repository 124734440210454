export class OverlappingOptions {
  public static NOT_OVERLAPPING = {
    name : 'Not OverLapping',
    value: 'NOT_OVERLAPPING'
  };
  public static APPEND = {
    name : 'Append',
    value: 'APPEND'
  };
  public static SUBSTITUTE = {
    name : 'Substitute',
    value: 'SUBSTITUTE'
  };
  public static MERGE = {
    name : 'Merge',
    value: 'MERGE'
  };
  public static CANCEL = {
    name : 'Cancel',
    value: 'CANCEL'
  };

  public static get LIST(): Array<any> {
    return [
      this.APPEND, this.SUBSTITUTE, this.MERGE, this.CANCEL
    ];
  }
}
